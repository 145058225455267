import { SchedulePatternContext } from 'contexts/cronEdit/SchedulePatternContext';
import { useContext, useEffect, useState } from 'react';

const rateUnitOptions = ['Minutes', 'Hours', 'Days'];

const timeMapping = [
  { label: 'OFF', value: null },
  { label: '15 Minutes', value: 15 },
  { label: '30 Minutes', value: 30 },
  { label: '45 Minutes', value: 45 },
  { label: '1 Hours', value: 60 },
  { label: '2 Hours', value: 120 },
  { label: '4 Hours', value: 240 }
];

function getValueBetweenParentheses(inputString) {
  const match = inputString.match(/\((.*?)\)/);

  if (match) {
    const valueBetweenParentheses = match[1];
    return valueBetweenParentheses;
  } else {
    return null;
  }
}

export default function RateBasedSchedule() {
  const { maximumWindowInMinutes, scheduleExpression, setFormModel } =
    useContext(SchedulePatternContext);

  const [selectedMaximumWindowInMinutes, setSelectedMaximumWindowInMinutes] = useState('');
  const [rateBasedValue, setRateBasedValue] = useState('');
  const [selectedRateUnitOption, setSelectedRateUnitOption] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [flexibleTimeWindowMode, setFlexibleTimeWindowMode] = useState(
    timeMapping.map((value) => value.label)
  );

  const [isUpdate, setUpdate] = useState(false);

  useEffect(() => {
    setSelectedMaximumWindowInMinutes(
      timeMapping.filter((option) => option.value === maximumWindowInMinutes)[0].label
    );
  }, [maximumWindowInMinutes]);

  useEffect(() => {
    setUpdate(false);

    if (scheduleExpression.startsWith('rate')) {
      setRateBasedValue(getValueBetweenParentheses(scheduleExpression).split(' ')[0]);
      setSelectedRateUnitOption(getValueBetweenParentheses(scheduleExpression).split(' ')[1]);
    }
  }, [scheduleExpression]);

  useEffect(() => {
    if (rateBasedValue !== '' && selectedRateUnitOption !== '' && isUpdate) {
      setFormModel((prevState) => {
        return {
          ...prevState,
          cronTriggerInfo: {
            ...prevState.cronTriggerInfo,
            scheduleExpression: `rate(${rateBasedValue} ${selectedRateUnitOption})`
          }
        };
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateBasedValue, selectedRateUnitOption, isUpdate]);

  return (
    <>
      <div className="mt-2 block">
        <h2 className="font-extrabold">Rate expression</h2>
        <p className="text-gray-500">Enter a value and the unit of time to run the schedule.</p>
      </div>
      <div className="flex items-center gap-4 py-4">
        <h2 className="font-extrabold text-black dark:text-gray-200">Rate</h2>
        <span className="font-extrabold text-black dark:text-gray-200">(</span>
        <div className="w-12">
          <input
            id="rateBasedValue"
            name="rateBasedValue"
            type="text"
            value={rateBasedValue}
            className="input-secondary dark:!border-slate-600"
            onChange={(e) => {
              setRateBasedValue(e.target.value);
              setUpdate(true);
            }}
          />
          <div className="text-left">
            <label className="text-xs text-gray-500">Value</label>
          </div>
        </div>
        <div className="w-16">
          <select
            id="rateUnitOptions"
            name="rateUnitOptions"
            type="text"
            value={selectedRateUnitOption}
            className="input-secondary dark:!border-slate-600"
            onChange={(e) => {
              setSelectedRateUnitOption(e.target.value);
              setUpdate(true);
            }}
          >
            <option value="" disabled>
              Select
            </option>
            {rateUnitOptions.map((units, index) => {
              return (
                <option key={index} defaultValue={units}>
                  {units}
                </option>
              );
            })}
          </select>
          <div className="text-left">
            <label className="text-xs text-gray-500">Unit</label>
          </div>
        </div>
        <span className="font-extrabold text-black dark:text-gray-200">)</span>
      </div>
      <div className="mt-2">
        <h2 className="py-1 font-extrabold">Flexible time window</h2>
        <p className="text-gray-500">
          If you choose a flexbile window, Scheduler invokes your schedule within the time window
          you specify. For example, if you choose 15 minutes, your schedule runs within 15 minutes
          after the schedule start time.
        </p>
        <div className="mt-2">
          <select
            id="maximumWindowInMinutes"
            name="maximumWindowInMinutes"
            className="input-main w-1/2"
            value={selectedMaximumWindowInMinutes}
            onChange={(e) => {
              setFormModel((prevState) => {
                return {
                  ...prevState,
                  cronTriggerInfo: {
                    ...prevState.cronTriggerInfo,
                    flexibleTimeWindow: {
                      ...prevState.cronTriggerInfo.flexibleTimeWindow,
                      maximumWindowInMinutes: timeMapping.filter(
                        (options) => options.label === e.target.value
                      )[0].value
                    }
                  }
                };
              });
              setSelectedMaximumWindowInMinutes(e.target.value);
            }}
          >
            <option value="" disabled>
              Select a flexible time
            </option>
            {flexibleTimeWindowMode.map((twindow, index) => {
              return (
                <option key={index} defaultValue={twindow}>
                  {twindow}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </>
  );
}
