import { Fragment, useState, useEffect } from 'react';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  CalendarDaysIcon,
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
} from '@heroicons/react/20/solid';
import LogFilterSkeleton from 'components/skeleton/eventLogs/LogFilter';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/airbnb.css';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { BiInfoCircle } from 'react-icons/bi';

const filters = [
  {
    id: 'Action Type',
    name: 'Action Type',
    options: [
      { value: 'create', label: 'Create', checked: false },
      { value: 'update', label: 'Update', checked: false },
      { value: 'delete', label: 'Delete', checked: false }
    ]
  },
  {
    id: 'Entity Type',
    name: 'Entity Type',
    options: [
      { value: 'command', label: 'Command', checked: false },
      { value: 'connection', label: 'Connection', checked: false },
      { value: 'secret', label: 'Secret', checked: false },
      { value: 'trigger', label: 'Trigger', checked: false },
      { value: 'project', label: 'Project', checked: false },
      { value: 'workflow', label: 'Workflow', checked: false },
      { value: 'user', label: 'User', checked: false },
      { value: 'execution-policy', label: 'Execution Policy', checked: false },
      { value: 'group', label: 'Group', checked: false },
      { value: 'login', label: 'Login', checked: false },
      { value: 'internal-app', label: 'Internal App', checked: false }
    ]
  }
];

export default function LogFilter({ onSelectChange, loading, handleDateChange }) {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState(filters);
  const [selectedActionType, setSelectedActionType] = useState([]);
  const [selectedEntityType, setSelectedEntityType] = useState([]);
  const [newStartDate, setNewStartDate] = useState(getInitialDate());
  const [newEndDate, setNewEndDate] = useState(getFormattedDate());
  const userSubscriptionPlan = useSelector((state) => state.userState.stripeInfo.subscriptionPlan);
  const [infoModal, setInfoModal] = useState(false);

  const handleCheckboxChange = (sectionId, optionId) => {
    setFilterOptions((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[sectionId] = {
        ...updatedFilters[sectionId],
        options: updatedFilters[sectionId].options.map((option, id) => {
          if (id === optionId) {
            return {
              ...option,
              checked: !option.checked
            };
          }
          return option;
        })
      };

      const selectedActionType = updatedFilters
        .find((filter) => filter.id === 'Action Type')
        .options.filter((option) => option.checked)
        .map((option) => option.value);

      const selectedEntityType = updatedFilters
        .find((filter) => filter.id === 'Entity Type')
        .options.filter((option) => option.checked)
        .map((option) => option.value);

      setSelectedActionType(selectedActionType);
      setSelectedEntityType(selectedEntityType);

      return updatedFilters;
    });
  };

  useEffect(() => {
    onSelectChange({ selectedActionType, selectedEntityType });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionType, selectedEntityType]);

  function getFormattedDate(date = new Date()) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  function getInitialDate(date = new Date()) {
    const oneWeekAgoDate = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
    const year = oneWeekAgoDate.getFullYear();
    const month = oneWeekAgoDate.getMonth() + 1;
    const day = oneWeekAgoDate.getDate();
    const formattedDate =
      year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    return formattedDate;
  }

  useEffect(() => {
    const sDate = format(new Date(newStartDate), 'yyyyMMdd');
    const eDate = format(new Date(newEndDate), 'yyyyMMdd');
    handleDateChange({ selectedStartDate: sDate, selectedEndDate: eDate });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStartDate, newEndDate]);

  return loading ? (
    <LogFilterSkeleton />
  ) : (
    <section aria-labelledby="logs-heading" className="w-full pt-2">
      {/* Mobile filter dialog */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4 border-t border-gray-200">
                  {filters.map((section) => (
                    <Disclosure
                      as="div"
                      key={section.id}
                      className="border-t border-gray-200 px-4 py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between px-2 py-3 text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-gray-900">{section.name}</span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                ) : (
                                  <ChevronDownIcon
                                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-6">
                              {section.options.map((option, optionIdx) => (
                                <div key={option.value} className="flex items-center">
                                  <input
                                    id={`filter-mobile-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type="checkbox"
                                    defaultChecked={option.checked}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                    className="ml-3 min-w-0 flex-1 text-gray-500"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="-mt-2 flex flex-col items-baseline border-b border-gray-200 pb-2">
        <div
          className="w-full"
          onMouseEnter={() => setInfoModal(true)}
          onMouseLeave={() => setInfoModal(false)}
        >
          <Menu as="div">
            <div className="flex items-center gap-2">
              <div className="flex w-full flex-col gap-1">
                <div className="group inline-flex cursor-pointer items-center justify-start gap-1 text-xs font-medium text-gray-400 hover:text-gray-500">
                  <CalendarDaysIcon
                    className="h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Start Date
                </div>
                {userSubscriptionPlan !== 'pro' ? (
                  <Flatpickr
                    data-enable-time
                    value={newStartDate}
                    options={{
                      altFormat: 'Y/m/d',
                      altInput: true,
                      enableTime: false,
                      dateFormat: 'Y/m/d'
                    }}
                    onChange={(e) => {
                      setNewStartDate(e);
                    }}
                    className="w-full cursor-pointer rounded-md border border-gray-300 bg-gray-50 py-2.5 text-center text-xs  shadow-inner"
                  />
                ) : (
                  <input
                    value={newStartDate}
                    disabled
                    className="w-full cursor-pointer rounded-md border border-gray-300 bg-gray-50 py-2.5 text-center text-xs  shadow-inner"
                  />
                )}
              </div>
              <div className="flex w-full flex-col gap-1">
                <div className="group inline-flex cursor-pointer items-center justify-start gap-1 text-xs font-medium text-gray-400 hover:text-gray-500">
                  <CalendarDaysIcon
                    className="h-4 w-4 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  End Date
                </div>
                {userSubscriptionPlan !== 'pro' ? (
                  <Flatpickr
                    data-enable-time
                    value={newEndDate}
                    options={{
                      altFormat: 'Y/m/d',
                      altInput: true,
                      enableTime: false,
                      dateFormat: 'Y/m/d'
                    }}
                    onChange={(e) => {
                      setNewEndDate(e);
                    }}
                    className="w-full cursor-pointer rounded-md border border-gray-300 bg-gray-50 py-2.5 text-center text-xs  shadow-inner"
                  />
                ) : (
                  <input
                    value={newEndDate}
                    disabled
                    className="w-full cursor-pointer rounded-md border border-gray-300 bg-gray-50 py-2.5 text-center text-xs  shadow-inner"
                  />
                )}
              </div>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            ></Transition>
          </Menu>
          {infoModal && (
            <div className="flex w-full items-center gap-2 rounded-md py-2">
              <div>
                <BiInfoCircle className="h-4 w-4 text-secondary" />
              </div>
              <span className="text-xs text-gray-400">
                Date will not be selected from user, it will be greyed out. When user comes upon to
                date, a message emerges: “You should upgrade your plan to determine dates”
              </span>
            </div>
          )}
        </div>
        <div className="flex w-full items-center justify-between gap-2 pt-4 md:justify-start">
          <FunnelIcon className="hidden h-4 w-4 lg:block" aria-hidden="true" />
          <h1 className="text-2xl font-bold tracking-tight">Filters</h1>
          <button
            type="button"
            className=" ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
            onClick={() => setMobileFiltersOpen(true)}
          >
            <span className="sr-only">Filters</span>
            <FunnelIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-10">
        {/* Filters */}
        <form className="hidden lg:block">
          {filterOptions.map((section, sectionIdx) => (
            <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
              {({ open }) => (
                <>
                  <h3 className="-my-3 flow-root">
                    <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                      <span className="font-medium text-gray-900">{section.name}</span>
                      <span className="ml-6 flex items-center">
                        {open ? (
                          <MinusIcon className="h-5 w-5" aria-hidden="true" />
                        ) : (
                          <ChevronDownIcon
                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </Disclosure.Button>
                  </h3>
                  <Disclosure.Panel className="pt-6">
                    <div className="space-y-4">
                      {section.options.map((option, optionIdx) => (
                        <div key={option.value} className="flex items-center">
                          <input
                            id={`filter-${section.id}-${optionIdx}`}
                            name={`${section.id}[]`}
                            defaultValue={option.value}
                            type="checkbox"
                            defaultChecked={option.checked}
                            onChange={() => handleCheckboxChange(sectionIdx, optionIdx)}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor={`filter-${section.id}-${optionIdx}`}
                            className="ml-3 text-sm text-gray-600"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </form>
      </div>
    </section>
  );
}
