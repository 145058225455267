import React, { useState, useEffect } from 'react';
import { editTriggerViewStates } from '../../../../constants';
import { Navigate } from 'react-router-dom';
import { updateWebhook, deleteWebhook, regenerateUrl } from './io';
import Loading from 'components/loading/Loading';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import { BiCopy } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { userRoles } from 'constants/index';
import MultiSelectDataTable from 'components/multiSelectDataTable';
import { generateSelectedEntities } from 'utils/entityUtils';
import { useToast } from 'hooks/use-toast';

const WebhookEdit = ({
  webhook,
  commands: allCommands,
  slackChannels: allSlackChannels,
  connections: allConnections,
  workflows: allWorkflows,
  title
}) => {
  const [viewState, setViewState] = useState(editTriggerViewStates.FETCHING);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [commands, setCommands] = useState([]);
  const [selectedCommands, setSelectedCommands] = useState([]);
  const [connections, setConnections] = useState([]);
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflows, setSelectedWorkflows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState('');
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();

  const webhookId = webhook ? webhook.id : '';
  const webhookName = webhook.name;
  const webhookCommands = webhook.commands;
  const webhookConnections = webhook.connections;
  const webhookWorkflows = webhook.workflows;
  const webhookDescription = webhook.description;
  useEffect(() => {
    if (!webhookId) {
      return;
    }
    setName(webhookName);
    setCommands(
      allCommands.map((command) => {
        return { value: command.name, label: command.name, kind:command.kind, description: command.description};
      }) || []
    );
    if (webhookCommands !== null) {
      setSelectedCommands(generateSelectedEntities(webhook, 'commands', commands));
    }
    setDescription(webhookDescription);
    setConnections(
      allConnections.map((connection) => {
        return { value: connection.name, label: connection.name, kind:connection.kind, description: connection.description};
      }) || []
    );
    if (webhookConnections !== null) {
      setSelectedConnections(generateSelectedEntities(webhook, 'connections', connections));
    }
    setWorkflows(
      allWorkflows.map((workflow) => {
        return { value: workflow.name, label: workflow.name, description:workflow.description };
      }) || []
    );
    if (webhookWorkflows !== null) {
      setSelectedWorkflows(generateSelectedEntities(webhook, 'workflows', workflows));
    }
    setViewState(editTriggerViewStates.IDLE);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    webhookId,
    allCommands,
    allConnections,
    allWorkflows,
    webhookConnections,
    webhookWorkflows,
    webhookName,
    webhookCommands,
    webhookDescription
  ]);
  if (!webhook.id) {
    return (
      <form action="/webhook/edit" onSubmit={() => {}}>
        <Loading />
      </form>
    );
  }
  const disabled = false;
  const btnExtraClassName = '';

  const onNameChange = (evt) => {
    setName(evt.target.value);
  };

  const onDescriptionChange = (evt) => {
    setDescription(evt.target.value);
  };

  const onBackClick = (evt) => {
    evt.preventDefault();
    setViewState(editTriggerViewStates.REDIRECT_TO_PARENT);
  };

  const onFormSubmit = (evt) => {
    evt.preventDefault();

    const payload = {
      kind: 'webHook',
      webHookTriggerInfo: {},
      name,
      description,
      connections: selectedConnections.map((connection) => {
        return connection.value;
      }),
      commands: selectedCommands.map((command) => {
        return command.value;
      }),
      workflows: selectedWorkflows.map((workflow) => {
        return workflow.value;
      })
    };

    updateWebhook(payload).then(
      () => {
        toast({
          title: 'Successfully',
          description:'Updated webhook'
        });
        setViewState(editTriggerViewStates.REDIRECT_TO_PARENT);
      },
      (err) => {
        console.log(err);
        setViewState(editTriggerViewStates.REDIRECT_TO_PARENT);
      }
    );
  };

  const onDeleteClick = (evt) => {
    evt.preventDefault();
    setShowModal(!showModal);
  };

  const confirmDelete = () => {
    setShowModal(!showModal);
    deletionConfirmed();
  };

  const deletionConfirmed = () => {
    deleteWebhook({ name: webhookName }).then(
      () => {
        setViewState(editTriggerViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description:'Deleted webhook'
        });
      },
      () => {
        setViewState(editTriggerViewStates.REDIRECT_TO_PARENT);
      }
    );
  };

  const updateUrl = (evt) => {
    evt.preventDefault();
    regenerateUrl({
      name: webhookName
    }).then(
      (response) => {
        setWebhookUrl(response.url);
      },
      (err) => {
        console.log(err);
        setViewState(editTriggerViewStates.REDIRECT_TO_PARENT);
      }
    );
  };

  //copy url
  const copyToClipboard = async (command) => {
    try {
      await navigator.clipboard.writeText(command);
      toast({
        title: 'Successfully',
        description:'Copied to clipboard'
      });
    } catch (err) {
      console.log('Failed to copy text: ', err);
    }
  };

  if (viewState === editTriggerViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/triggers/webhook" />;
  }

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <form action="/triggers/webhook/edit" onSubmit={onFormSubmit}>
              <div className="flex flex-col gap-6">
                {/* <h2 className="font-bold text-base mt-0 mb-4 text-center"> {title} </h2> */}
                <h2 className="mb-4 mt-0 text-center text-base font-bold">UPDATE WEBHOOK</h2>
                <div>
                  <label htmlFor="name" className="label-main">
                    Webhook Name
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <input
                      id="name"
                      required
                      value={name}
                      maxLength="150"
                      onChange={onNameChange}
                      disabled={disabled}
                      readOnly={!!webhookName}
                      placeholder="webhook name"
                      className={`${btnExtraClassName} bg-gray-100 ${
                        webhookName && 'cursor-default'
                      } ${webhookName ? 'input-main-disabled' : 'input-main'}`}
                    />
                  </div>
                  <span className="label-second">
                    Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                  </span>
                </div>
                <div>
                  <label htmlFor="description" className="label-main">
                    Webhook Description
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <input
                      id="description"
                      value={description}
                      disabled={disabled}
                      onChange={onDescriptionChange}
                      maxLength={125}
                      placeholder="webhook description"
                      className={`${btnExtraClassName} input-main`}
                    />
                  </div>
                </div>
                {/*Commands */}
                <MultiSelectDataTable
                  title={'Commands'}
                  tableData={commands}
                  selectedTableData={selectedCommands}
                  setSelectedTableDataCallback={setSelectedCommands}
                />
                {/*Connections */}
                <MultiSelectDataTable
                  title={'Connections'}
                  tableData={connections}
                  selectedTableData={selectedConnections}
                  setSelectedTableDataCallback={setSelectedConnections}
                />
                {/*Workflows */}
                <MultiSelectDataTable
                  title={'Workflows'}
                  tableData={workflows}
                  selectedTableData={selectedWorkflows}
                  setSelectedTableDataCallback={setSelectedWorkflows}
                />

                {webhookName ? (
                  <>
                    <div className="label-second -mb-8 mt-4">
                      To execute your command, send a HTTP POST request using the provided request
                      body. Click the "Regenerate URL" button to obtain the updated URL for your
                      request.
                    </div>
                    {webhookUrl ? (
                      <div className="-mb-6 mt-6 flex w-full items-center gap-4">
                        <div className="agent-copy" onClick={() => copyToClipboard(webhookUrl)}>
                          <BiCopy className="h-7 w-7 text-secondary" />
                        </div>
                        <div className="truncate font-black text-secondary">{webhookUrl}</div>
                      </div>
                    ) : (
                      <div className="regenerate-agent -mb-6" onClick={updateUrl}>
                        Regenerate Url
                      </div>
                    )}
                    <div className="agent-instruction">
                      <div className="agent-instruction-topbar">
                        <div>TO RUN A COMMAND WITH A CONNECTION</div>
                        <div
                          className="agent-copy"
                          onClick={() =>
                            copyToClipboard(`{
                            "connection":"<connectionName>",
                            "command":"<commandName>",
                            "argument":"arguments"
                        }`)
                          }
                        >
                          <BiCopy className="h-5 w-5 text-white" />
                        </div>
                      </div>
                      <div className="agent-instruction-body">
                        <div className="codeBlock">
                          <div className="codeBlock-scroll">
                            <div class="codeBlock-lines">
                              <div>1</div>
                              <div>2</div>
                              <div>3</div>
                              <div>4</div>
                              <div>5</div>
                            </div>
                            <pre className="codeBlock-pre !min-h-min">
                              <code className="language-bash">
                                <span className="comment-line-heading">{`{`}</span>
                                <span className="comment-line-heading comment-line-margin">
                                  {`"connection":"<connectionName>",`}
                                </span>
                                <span className="comment-line-heading comment-line-margin">
                                  {`"command":"<commandName>",`}
                                </span>
                                <span className="comment-line-heading comment-line-margin">
                                  {`"argument":"<arguments>"`}
                                </span>
                                <span className="comment-line-heading">{`}`}</span>
                              </code>
                            </pre>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="agent-instruction !-mt-6">
                      <div className="agent-instruction-topbar">
                        <div>TO RUN A WORKFLOW</div>
                        <div
                          className="agent-copy"
                          onClick={() =>
                            copyToClipboard(`{
                            "workflow":<workflowName>,
                            "workflowArgument":<arguments>",
                        }`)
                          }
                        >
                          <BiCopy className="h-5 w-5 text-white" />
                        </div>
                      </div>
                      <div className="agent-instruction-body">
                        <div className="codeBlock">
                          <div className="codeBlock-scroll">
                            <div class="codeBlock-lines">
                              <div>1</div>
                              <div>2</div>
                              <div>3</div>
                              <div>4</div>
                            </div>
                            <pre className="codeBlock-pre !min-h-min">
                              <code className="language-bash">
                                <span className="comment-line-heading">{`{`}</span>
                                <span className="comment-line-heading comment-line-margin">
                                  {`"workflow":<workflowName>,`}
                                </span>
                                <span className="comment-line-heading comment-line-margin">
                                  {`"workflowArgument":<arguments>",`}
                                </span>
                                <span className="comment-line-heading">{`}`}</span>
                              </code>
                            </pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {userState.userRole.role !== userRoles.READ_ONLY && (
                  <div>
                    <h2 className="text-xl font-extrabold">Danger Zone</h2>
                    <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                      <p className="label-second">
                        By clicking the button below you delete this webhook. This action is{' '}
                        <strong>irreversible</strong>.
                      </p>
                      <p className="label-second mb-4">
                        You can still re-use this webhook connections and commands in other
                        webhooks.
                      </p>
                      <button
                        disabled={disabled}
                        type="button"
                        onClick={onDeleteClick}
                        className={`${btnExtraClassName} text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                      >
                        I understand the consequences. Delete this webhook
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {userState.userRole.role !== userRoles.READ_ONLY && (
                <div className="flex justify-end">
                  <span className="inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      disabled={disabled}
                      onClick={onBackClick}
                      className={`${btnExtraClassName} cancel-button`}
                    >
                      Cancel
                    </button>
                  </span>
                  <span className="ml-3 inline-flex rounded-md shadow-sm">
                    <button
                      disabled={disabled}
                      type="submit"
                      className={`${btnExtraClassName} save-button`}
                    >
                      Save
                    </button>
                  </span>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this webhook?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default WebhookEdit;
