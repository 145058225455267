import useFetchGroups from 'hooks/useFetchGroups';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

export default function ExecutionPolicyOverride({
  defaultGroup,
  defaultFlags,
  onChangeApproverGroup,
  onChangeFlags
}) {
  const { groups } = useFetchGroups();
  const [flagInputValue, setFlagInputValue] = useState('');

  const [selectedApproverGroup, setSelectedApproverGroup] = useState('');
  const [selectedFlags, setSelectedFlags] = useState([]);

  useEffect(() => {
    setSelectedApproverGroup(defaultGroup);
    if (defaultFlags !== undefined && defaultFlags !== null)
      setSelectedFlags(defaultFlags?.map((flag) => createSelectOption(flag)));
  }, [defaultGroup, defaultFlags]);

  const components = {
    DropdownIndicator: null
  };

  const handleApproverGroupChange = (e) => {
    setSelectedApproverGroup(e.value);
    onChangeApproverGroup(e.value);
  };

  const handleKeyDown = (event) => {
    if (!flagInputValue) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setSelectedFlags([...selectedFlags, createSelectOption(flagInputValue)]);
        setFlagInputValue('');
        event.preventDefault();
        onChangeFlags([...selectedFlags, createSelectOption(flagInputValue)]);
    }
  };

  const createSelectOption = (label) => ({
    label,
    value: label
  });

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="description" className="text-xl font-extrabold">
        Execution Policy Override
      </label>

      <div className="flex flex-col gap-6">
        <div className="flex flex-1 flex-col gap-2">
          <div>Execution Group</div>
          <div className="w-96">
            <Select
              className="flex-1"
              options={groups?.map((group) => createSelectOption(group.name))}
              onChange={handleApproverGroupChange}
              placeholder="Select Approver Group"
              value={{
                value: selectedApproverGroup,
                label: selectedApproverGroup
              }}
              classNames={{
                control: (state) =>
                  state.isFocused ? 'multi-select-input-focus' : 'multi-select-input',
                menuList: () => 'multi-select-dropdown',
                option: () => 'multi-select-option',
                input: () => 'multi-select-input-text'
              }}
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <div>Custom Flags</div>
          <div className="w-96">
            <CreatableSelect
              components={components}
              inputValue={flagInputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={(newValue) => {
                setSelectedFlags(newValue);
                onChangeFlags(newValue);
              }}
              onInputChange={(newValue) => {
                setFlagInputValue(newValue);
              }}
              onKeyDown={handleKeyDown}
              placeholder="Type custom flags and press enter"
              value={selectedFlags}
              classNames={{
                control: (state) => 'custom-select',
                input: (state) => 'custom-select-input'
              }}
            />
          </div>
        </div>
      </div>
      <label className="label-second mt-2 block">
        If these flags are detected in the command text, and the user is a. member of execution
        group, all other execution policy checks will be skipped and user will be allowed to execute
        the command.
      </label>
    </div>
  );
}
