import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CommandLineIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export default function CreatableTypeModal({
  showModal,
  setShowModal,
  creatableTypeOptions = null
}) {
  const [open, setOpen] = useState(showModal);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  useEffect(() => {
    if (!open) {
      setShowModal(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <Dialog.Title className='text-center font-black text-xl'>Create</Dialog.Title>
                <ul className="mt-3 grid grid-cols-1 gap-6 border-gray-200 py-6 sm:grid-cols-2">
                  {creatableTypeOptions !== null &&
                    creatableTypeOptions.map((item, itemIdx) => (
                      <li key={itemIdx} className="flow-root">
                        <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 hover:bg-gray-100">
                          <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-secondary">
                            <CommandLineIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-gray-900">
                              <Link to={item.link} className="focus:outline-none">
                                <span className="absolute inset-0" aria-hidden="true" />
                                <span>{item.name}</span>
                              </Link>
                            </h3>
                          
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
