import { ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/20/solid';
import LogListSkeleton from 'components/skeleton/eventLogs/LogList';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getAuditLogDetails } from './io';
import CodeMirror from '@uiw/react-codemirror';

const statuses = {
  cron: '!text-secondary !bg-secondary/10',
  workflow: '!text-green-400 !bg-green-400/10',
  slack: '!text-rose-400 !bg-rose-400/10',
  app: '!text-amber-400 !bg-amber-400/10',
  webhook: '!text-purple-400 !bg-purple-400/10',
  api: '!text-red-400 !bg-red-400/10',
  agent: '!text-gray-400 !bg-gray-400/10'
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AuditLogsDetail() {
  const [log, setLog] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [agentLogId, setAgentLogId] = useState(null);
  const [logIdDetails, setLogIdDetails] = useState(null);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    setLoading(true);
    try {
      getAuditLogDetails(id).then(({ success, payload }) => {
        if (success) {
          const { log } = payload;
          setLog(log);
        } else {
          console.log('Audit log details could not be loaded.');
        }
        setLoading(false);
      });
    } catch (err) {
      console.log('error', err);
      setLoading(false);
    }
  }, [id]);

  const getAuditLogKindDesc = (log) => {
    return log.logKind === 'workflow'
      ? log.workflowName
      : log.connection + ' ' + log.command + ' ' + log.commandArgs;
  };

  const handleLogIdDetails = (agentLogId) => {
    setAgentLogId(agentLogId);
    try {
      getAuditLogDetails(agentLogId).then(({ success, payload }) => {
        if (success) {
          const { log } = payload;
          setLogIdDetails(log.logResult);
        } else {
          console.log('Audit log id details could not be loaded.');
        }
      });
    } catch (err) {
      console.log('error', err);
    }
  };

  useEffect(() => {
    const maxAttempts = 5;

    if (logIdDetails === '') {
      const intervalId = setInterval(async () => {
        if (attempts < maxAttempts) {
          try {
            const { success, payload } = await getAuditLogDetails(agentLogId); 
            if (success) {
              const { log } = payload;
              setLogIdDetails(log.logResult);
              clearInterval(intervalId);
            } else {
              console.log('Audit log id details could not be loaded.');
            }
          } catch (err) {
            console.log('error', err);
          }
          setAttempts(attempts + 1);
        } else {
          clearInterval(intervalId);
        }
      }, 7000);
      return () => clearInterval(intervalId);
    }
  }, [logIdDetails, agentLogId, attempts]);
console.log(log);
console.log(logIdDetails);
  return (
    <div className="flex w-full flex-col">
      <div className="relative flex items-center justify-center">
        <Link to="/audit-logs" className="absolute left-0 ">
          <div className="flex items-center gap-2 text-xs text-gray-400 transition-all hover:text-gray-900">
            <ChevronLeftIcon className="h-5 w-5" />
            Back to Audit Logs
          </div>
        </Link>
        <h1 className="py-4 text-center text-2xl font-bold tracking-tight">Audit Log Details</h1>
      </div>
      <div className="divide-y rounded-lg border shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] dark:divide-white/10 dark:border-gray-100/20">
        {loading ? (
          <LogListSkeleton />
        ) : log ? (
          <div className="relative block cursor-default items-center space-x-4">
            <div className="min-w-0 flex-auto px-2 py-4">
              <div className="flex items-center gap-x-3">
                <div className={classNames(statuses[log.logKind], 'flex-none rounded-full p-1')}>
                  <div className="h-2 w-2 rounded-full bg-current" />
                </div>
                <h2 className="min-w-0 text-sm font-semibold leading-6">
                  <a href={log.href} className="flex gap-x-2">
                    <span className="truncate text-lg">{log.logKind ? log.logKind : 'Slack'}</span>
                    <span className="absolute inset-0" />
                  </a>
                </h2>
              </div>
              <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
                <div className="block flex-wrap items-center gap-1 lg:flex">
                  <div className="flex items-center gap-1">
                    <p className="eventlog-description"> {log.userEmail}</p>
                    <p className="eventlog-description">triggered</p>
                    <p className="eventlog-description text-[#374151]">
                      {' '}
                      {getAuditLogKindDesc(log).length > 30 ? `${getAuditLogKindDesc(log).slice(0,30)}...` : getAuditLogKindDesc(log) }
                    </p>
                  </div>
                  <div className="flex items-center gap-1">
                    <p className="eventlog-description"> at UTC</p>
                    <p> {log.timestamp.slice(0, 10)}</p>
                    <p>{log.timestamp.slice(11, 19)}</p>
                  </div>
                </div>
              </div>
            </div>{' '}
            <div className="absolute right-4 top-8">
              <div className="flex items-center gap-2">
                <span
                  className={`${
                    log.executionResult
                      ? 'bg-green-50 text-green-700 ring-green-600/40'
                      : 'bg-red-50 text-red-700 ring-red-600/40'
                  } "inline-flex font-medium" ring-inset" flex-shrink-0  items-center rounded-full px-3 py-1.5 text-xs ring-1`}
                >
                  {log.executionResult ? 'Success' : 'Failed'}
                </span>
              </div>
            </div>
            {log.logResult && typeof log.logResult === 'string' && (
              <div className="relative !m-0 bg-gray-200 bg-opacity-70 pt-6">
                <div className="flex items-start gap-8">
                  <div className="flex flex-col gap-2 px-4 py-2">
                    <h1 className="text-2xl font-bold tracking-tight">Output</h1>
                  </div>
                  <div className="flex w-full items-center p-4">
                    <CodeMirror
                      height="40vh"
                      className="w-full cursor-default rounded-md !text-xs"
                      value={
                        typeof log.logResult === 'string' 
                          ? (() => {
                              try {
                                const parsedLog = JSON.parse(log.logResult);
                                return parsedLog.targets && parsedLog.targets[0]?.output 
                                  ? parsedLog.targets[0].output 
                                  : log.logResult; 
                              } catch (e) {
                                return log.logResult;
                              }
                            })()
                          : ''
                      }
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            )}
            {log.agentLogId && (
              <div
                onClick={logIdDetails ? null : () => handleLogIdDetails(log.agentLogId)}
                className="relative !m-0 block cursor-pointer items-center"
              >
                <div className="min-w-0 flex-auto rounded-lg bg-white px-2 py-4">
                  <div className="flex items-center gap-x-3">
                    <h2 className="min-w-0 text-sm font-semibold leading-6">
                      <a href={log.href} className="flex gap-x-2">
                        <span className="truncate text-lg">Agent Logs</span>
                        <span className="absolute inset-0" />
                      </a>
                    </h2>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2.5 pl-1 text-xs leading-5 text-gray-400">
                    <div className="block flex-wrap items-center gap-1 lg:flex">
                      <div className="flex items-center gap-1">
                        <p className="eventlog-description">
                          Click the button to see the details of agent logs
                        </p>
                      </div>
                    </div>
                  </div>
                </div>{' '}
                <div className="absolute right-4 top-8">
                  <div className="flex items-center gap-2">
                    {logIdDetails === '' && (
                      <span className="flex-shrink-0 animate-pulse items-center rounded-full bg-blue-50  px-3 py-1.5 text-xs text-blue-700 ring-1 ring-blue-600/40">
                        In progress
                      </span>
                    )}
                    <ChevronDownIcon
                      className="h-5 w-5 flex-none text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                {logIdDetails && (
                  <div className="relative !m-0 bg-gray-200 bg-opacity-70 pt-6">
                    <div className="flex items-start gap-8">
                      <div className="flex flex-col gap-2 px-4 py-2">
                        <h1 className="text-2xl font-bold tracking-tight">Output</h1>
                      </div>

                      <div className="flex w-full items-center p-4">
                        <CodeMirror
                          height="40vh"
                          className="w-full cursor-default rounded-md !text-xs"
                          value={
                            typeof logIdDetails === 'string' 
                              ? (() => {
                                  try {
                                    const parsedLog = JSON.parse(logIdDetails);
                                    return parsedLog.targets && parsedLog.targets[0]?.output 
                                      ? parsedLog.targets[0].output 
                                      : logIdDetails; 
                                  } catch (e) {
                                    return logIdDetails;
                                  }
                                })()
                              : ''
                          }
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="flex h-56 items-center justify-center text-lg text-gray-400">
            It looks like you don’t have any data.
          </div>
        )}
      </div>
    </div>
  );
}
