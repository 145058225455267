import BotIcon from 'assets/img/ob-bot-icon.webp';
import { EllipsisHorizontalIcon, UsersIcon } from '@heroicons/react/24/outline';
import CopyToClipboard from 'components/copyToClipboard';
import { useEffect, useState } from 'react';
import { ArrowDownCircleIcon } from '@heroicons/react/20/solid';

const Message = (props) => {
  const { message } = props;
  const { role, content: text } = message;
  // eslint-disable-next-line no-unused-vars
  const [displayedText, setDisplayedText] = useState('');
  const [isFullTextVisible, setIsFullTextVisible] = useState(false);

  useEffect(() => {
    if (text) {
      if (!isFullTextVisible) {
        const splitText = text.split('\n');
        const firstTenLines = splitText.slice(0, 20).join('\n');
        setDisplayedText(firstTenLines);
      } else {
        setDisplayedText(text);
      }
    }
  }, [isFullTextVisible, text]);

  const handleShowMore = () => {
    setIsFullTextVisible(true);
    setDisplayedText(text);
  };

  const isUser = role === 'user';

  return (
    <div className="group w-full bg-[#f9fafb] text-gray-900">
      <div className="m-auto flex w-full gap-4 text-base md:max-w-2xl md:gap-6 lg:max-w-xl lg:px-0 xl:max-w-3xl">
        <div className="m-auto flex w-full flex-row gap-4 p-4 md:max-w-2xl md:gap-6 md:py-3 lg:max-w-xl lg:px-0 xl:max-w-3xl">
          <div className="relative flex w-8 flex-col items-center justify-center">
            <div className="rounded-full border bg-gray-100 p-1">
              {isUser ? (
                <UsersIcon className="h-5 w-5 text-black" />
              ) : (
                <img src={BotIcon} className="h-5" alt="ob bot icon" />
              )}
            </div>
          </div>

          <div className="relative flex w-[calc(100%-20px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-50px)]">
            <div className="flex flex-grow flex-col gap-3">
              <div className="min-h-20 flex flex-col items-start gap-4 whitespace-pre-wrap break-words">
                <div className="markdown prose dark:prose-invert w-full break-words whitespace-normal overflow-x-auto">
                  {!isUser && text === null ? (
                    <EllipsisHorizontalIcon className="h-6 w-6 animate-pulse" />
                  ) : !isUser && text !== null ? (
                    <div className="relative">
                      <span className="block py-1 font-mono text-sm font-black text-gray-700">
                        obot
                      </span>
                      <pre className="whitespace-pre-wrap text-xs bg-white p-4 rounded-md border overflow-x-auto leading-relaxed">
                        {text}
                      </pre>

                      {/* Copy to Clipboard button aligned at the bottom left */}
                      <div className="flex justify-start mt-2">
                        <CopyToClipboard
                          copyClass="text-gray-500 hover:text-gray-700"
                          itemTopCopy={text}
                          toastText="Message copied to clipboard"
                        />
                      </div>

                      {text && text.split('\n').length > 10 && !isFullTextVisible && (
                        <div className="flex justify-center">
                          <button
                            className="absolute bottom-2"
                            onClick={handleShowMore}
                          >
                            <ArrowDownCircleIcon className="h-8 w-8 text-gray-900 animate-pulse" />
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                  <p className="text-sm leading-relaxed text-gray-600 text-right">
                    <span className="inline-block bg-blue-100 p-3 rounded-md" style={{ borderRadius: '20px' }}>
                      {text}
                    </span>
                    <div className="flex justify-end mt-2">
                      <CopyToClipboard
                        copyClass="text-gray-500 hover:text-gray-700"
                        itemTopCopy={text.trim()}
                        toastText="Message copied to clipboard"
                      />
                    </div>
                  </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
