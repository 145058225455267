import React, { useEffect } from 'react';
import { useState } from 'react';
import LoginFooter from './LoginFooter';
import LoginHeader from 'features/login/LoginHeader';
import { globalViewStates } from 'constants';
import Logo from 'components/logo/Logo';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RegisterRequest } from './network';
import RequiredField from 'components/toolbox/RequiredField';
import NotConfirmedPassword from 'components/toolbox/NotConfirmedPassword';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';
import { validatePassword, passwordRequirementsMet } from 'utils/passwordUtils';
import { useToast } from 'hooks/use-toast';

const Register = () => {
  // eslint-disable-next-line no-unused-vars
  const [viewState, setViewState] = useState(globalViewStates.DEFAULT);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [lookupKey, setLookupKey] = useState('');
  const [trial, setTrial] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [optIn, setOptIn] = useState(false);
  const [formErrorModel, setFormErrorModel] = useState({
    email: false,
    token: false,
    fullName: false,
    password: false,
    passwordConfirm: false
  });
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const getEmailUrl = decodeURIComponent(params.email);
    const getTokenUrl = decodeURIComponent(params.token);
    const getLookupKey = decodeURIComponent(params.lookupKey);
    const getTrial = decodeURIComponent(params.trial);

    setEmail(getEmailUrl);
    setToken(getTokenUrl);
    setLookupKey(getLookupKey);
    setTrial(getTrial === 'false' ? false : true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = async (evt) => {
    evt.preventDefault();

    const sanitizedEmail = email.trim();
    const sanitizedFullName = fullName.trim();

    const obj = {
      fullName: fullName.trim() === '',
      email: email.trim() === '',
      token: params.token.trim() === '',
      password: password.trim() === '',
      confirmPassword: confirmPassword.trim() === ''
    };

    setFormErrorModel(obj);

    if (!fullName || !email || !token || !password || !confirmPassword) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill all required areas.'
      });
      return;
    }

    if (!sanitizedEmail) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please provide a valid email'
      });
      return;
    }

    if (lookupKey === '' || trial === '') {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please provide plan'
      });
      return;
    }

    if (password !== confirmPassword) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Password did not match! Please try again.'
      });
      return;
    }

    // password validation
    if (!isPasswordValid) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Password must be a minimum of 12 characters and include at least one special character, one number, and one uppercase letter'
      });
      return;
    }

    try {
      const { success } = await RegisterRequest({
        email: sanitizedEmail,
        fullName: sanitizedFullName,
        password,
        optIn,
        token,
        lookupKey: 'opsbeacon-enterprise-free',
        trial: true
      });

      if (!success) {
        setViewState(globalViewStates.ERROR);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There was a problem. Please try again later.'
        });
      } else {
        setTimeout(function () {
          toast({
            title: 'Successfully',
            description: 'You are being redirected to the log in page'
          });
        }, 1000);

        setTimeout(function () {
          navigate('/', { replace: true });
        }, 2000);

        setViewState(globalViewStates.SUCCESS);
      }
    } catch (err) {
      console.log(err);
      setViewState(globalViewStates.ERROR);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description:'There was a problem. Please try again later.'
      });
    }
  };

  const onPasswordChange = (onChange) => {
    const newPassword = onChange.target.value.trim();
    setPassword(newPassword);
    const isValid = validatePassword(newPassword);
    setIsPasswordValid(isValid);
  };

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-100 py-12 pb-48 sm:px-6 lg:px-8">
      <Logo width={'72px'} height={'42px'} textSize={'text-2xl'} />

      <div className="m-5 mt-8 max-w-lg px-4 py-8  sm:mx-auto sm:w-full  sm:px-10">
        <LoginHeader title={'Sign Up'} description={'We will get you in in no-time.'} />

        <form onSubmit={onFormSubmit} className="grid grid-cols-1 gap-y-3">
          <div>
            <label htmlFor="fullName" className="block text-sm font-medium leading-5 text-gray-700">
              Name *
            </label>

            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="fullName"
                type="text"
                placeholder="John Doe"
                value={fullName}
                onChange={(onChange) => setFullName(onChange.target.value)}
                autoFocus
                required
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>
            {formErrorModel.fullName && <RequiredField />}
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
              Email *
            </label>

            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="email"
                type="email"
                placeholder="email address"
                value={email}
                // onChange={(onChange) => setEmail(onChange.target.value.trim())}
                autoFocus
                required
                disabled
                readOnly
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>

            {formErrorModel.email && <RequiredField />}
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
              Password *
            </label>

            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => onPasswordChange(e)}
                autoFocus
                required
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>
            <div className="password-required">
              <div className="password-required-content">
                <CheckBadgeIcon
                  className={`h-6 w-6 ${
                    passwordRequirementsMet(password).length ? 'text-green-500' : ''
                  }`}
                />
                Minimum 12 characters
              </div>
              <div className="password-required-content">
                <CheckBadgeIcon
                  className={`h-6 w-6 ${
                    passwordRequirementsMet(password).specialChar ? 'text-green-500' : ''
                  }`}
                />
                One special character
              </div>
              <div className="password-required-content">
                <CheckBadgeIcon
                  className={`h-6 w-6 ${
                    passwordRequirementsMet(password).uppercase ? 'text-green-500' : ''
                  }`}
                />{' '}
                One uppercase letter
              </div>
              <div className="password-required-content">
                <CheckBadgeIcon
                  className={`h-6 w-6 ${
                    passwordRequirementsMet(password).number ? 'text-green-500' : ''
                  }`}
                />{' '}
                One number
              </div>
            </div>
            {formErrorModel.password && <RequiredField />}
          </div>

          <div>
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Password Confirm *
            </label>

            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="confirmPassword"
                type="password"
                placeholder="Password Confirmation"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoFocus
                required
                className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-4 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              />
            </div>

            {formErrorModel.confirmPassword && <RequiredField />}
            {password !== confirmPassword ? <NotConfirmedPassword /> : ''}
          </div>

          <div className="mb-4">
            <input
              id="opt-in"
              className="w-8"
              type="checkbox"
              checked={optIn}
              onChange={() => setOptIn(!optIn)}
            />{' '}
            <label
              htmlFor="opt-in"
              className="mt-2 cursor-pointer text-center text-sm text-gray-400"
            >
              Register to our newsletter and be the first to know about the cool stuff we create to
              help you <strong>sleep more</strong>.
            </label>
          </div>
          <div className="-mt-3 mb-2">
            <label
              htmlFor="acceptPolicy"
              className="mt-2 cursor-pointer text-center text-sm text-gray-400"
            >
              By continuing, you agree to the{' '}
              <Link
                to="https://www.ob2.ai/terms-of-service"
                target="_blank"
                className="font-black text-[#374151] underline"
              >
                Terms of Service
              </Link>{' '}
              and
              <Link
                to="https://www.ob2.ai/privacy"
                target="_blank"
                className="font-black text-[#374151] underline"
              >
                {' '}
                Privacy Policy
              </Link>{' '}
            </label>
          </div>
          <div>
            <span className="block w-full rounded-md shadow-sm">
              <button
                className="flex w-full justify-center rounded-md border px-4 py-4 text-white"
                style={{ backgroundColor: 'rgb(37, 98, 234' }}
                type="submit"
              >
                Register
              </button>
            </span>
          </div>
        </form>

        <p className="mt-6 block text-slate-400">You will receive a link to signup via email.</p>

        <div className="relative flex items-center py-8">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-4 flex-shrink text-sm text-gray-500">OR</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>

        <div>
          <span className="block w-full rounded-md shadow-sm">
            <Link
              className="flex w-full justify-center rounded-md border bg-white px-4 py-4 text-secondary"
              to="/"
            >
              Sign In
            </Link>
          </span>
        </div>
      </div>

      <LoginFooter />
    </div>
  );
};

export default Register;
