import useFetchGroups from 'hooks/useFetchGroups';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

export default function ApprovalPolicyOverride({
  defaultGroup,
  defaultFlags,
  onChangeApproverGroup,
  onChangeFlags
}) {
  const { groups } = useFetchGroups();
  const [flagInputValue, setFlagInputValue] = useState('');

  const [selectedApproverGroup, setSelectedApproverGroup] = useState('');
  const [selectedFlags, setSelectedFlags] = useState([]);

  useEffect(() => {
    setSelectedApproverGroup(defaultGroup);
    if (defaultFlags !== undefined && defaultFlags !== null) {
      setSelectedFlags(defaultFlags?.map((flag) => createSelectOption(flag)));
    }
  }, [defaultGroup, defaultFlags]);

  const components = {
    DropdownIndicator: null
  };

  const handleApproverGroupChange = (selectedOption) => {
    // check if the Selected Option is empty or not
    const approverGroup = selectedOption ? selectedOption.value : '';
    setSelectedApproverGroup(approverGroup);
    onChangeApproverGroup(approverGroup);
  };
  

  const handleKeyDown = (event) => {
    if (!flagInputValue) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setSelectedFlags([...selectedFlags, createSelectOption(flagInputValue)]);
        setFlagInputValue('');
        event.preventDefault();
        onChangeFlags([...selectedFlags, createSelectOption(flagInputValue)]);
    }
  };

  const createSelectOption = (label) => ({
    label,
    value: label
  });

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="description" className="text-xl font-extrabold">
        Approval Policy Override
      </label>
      <label className="label-second mt-2 block">
        If these flags are detected in the command text, the approval request will be sent to the
        members of the approver group skipping other approval policy checks.
      </label>

      <div className="flex flex-row gap-6">
        <div className="flex flex-1 flex-col gap-2">
          <div>Approver Group</div>
          <div>
            <Select
              className="flex-1"
              options={groups?.map((group) => createSelectOption(group.name))}
              onChange={handleApproverGroupChange}
              placeholder="Select Approver Group"
              value={{
                value: selectedApproverGroup,
                label: selectedApproverGroup
              }}
              isClearable
              classNames={{
                control: (state) =>
                  state.isFocused ? 'multi-select-input-focus' : 'multi-select-input',
                menuList: () => 'multi-select-dropdown',
                option: () => 'multi-select-option',
                input: () => 'multi-select-input-text'
              }}
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <div>Custom Flags</div>
          <div>
            <CreatableSelect
              components={components}
              inputValue={flagInputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={(newValue) => {
                setSelectedFlags(newValue);
                onChangeFlags(newValue);
              }}
              onInputChange={(newValue) => {
                setFlagInputValue(newValue);
              }}
              onKeyDown={handleKeyDown}
              placeholder="Type custom flags and press enter"
              value={selectedFlags}
              classNames={{
                control: (state) => 'custom-select',
                input: (state) => 'custom-select-input'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
