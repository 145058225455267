import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { globalViewStates } from 'constants/index';
import { secretExists, addNewSecret } from './io';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { fetchData } from '../KeyList/io';
import { useToast } from 'hooks/use-toast';

const KeyNewRest = () => {
  const initialFormModel = {
    name: '',
    kind: 'rest',
    description: '',
    restInfo: {
      apiKeyName: '',
      apiKeyValue: ''
    }
  };

  const [viewState, setViewState] = useState(globalViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false,
    key: false,
    value: false
  });
  const params = useParams(); // get param
  const secretName = params.secretName ? params.secretName : null;
  const [secretList, setSecretList] = useState([]);
  const { toast } = useToast();


  const onFormSubmitSecret = (e) => {
    e.preventDefault();

    const obj = {
      name: formModel.name.trim() === '',
      uniqueName: secretList.find(
        (secret) => secret.name.toLowerCase() === formModel.name.toLowerCase()
      )
        ? true
        : false,
      key: formModel.restInfo.apiKeyName.trim() === '',
      value: formModel.restInfo.apiKeyValue.trim() === ''
    };

    setFormErrorModel(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      onSshFormModelSave();
    }
  };

  const onSshFormModelSave = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await secretExists(formModel.name);

      if (!success) {
        toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
        // alert('There was a problem. Try again later.');
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'There’s an existing rest with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addNewSecret(formModel);

      if (!successAdd) {
        toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: 'New secret added.'
        });
      }
      // Check if rest exists by getting rest by name.
      // add rest, go back to listing.
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
    }
  };

  // clear form
  const clearForm = () => {
    setFormModel(initialFormModel);
  };

  //Change Input
  const onChangeInput = (value, field) => {
    const trimmedValue = value.trim();
    if (field === 'name' && (trimmedValue !== '' || value === '')) {
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  };

  const onChangeKeyValue = (value, field) => {
    setFormModel((prevState) => {
      if (field === 'apiKeyName') {
        prevState.restInfo.apiKeyName = value;
      } else if (field === 'apiKeyValue') {
        prevState.restInfo.apiKeyValue = value;
      }

      return {
        ...prevState
      };
    });
  };

  useEffect(() => {
    fetchData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { secrets } = payload;
        setSecretList(secrets);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (!secretName) {
        if (
          secretList.find((secret) => secret.name.toLowerCase() === formModel.name.toLowerCase())
        ) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false
          });
        }
      }
    };
    checkIfNameExists();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModel.name, secretList]);

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/secrets" />;
  }

  return (
    <div className="form-container">
      <div className="hero-section">
        <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
          <h2 className="mb-4 mt-0 text-center text-base font-bold">NEW API KEY</h2>

          <div className="mb-4 rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <BsFillInfoCircleFill className="h-5 w-5 text-blue-500" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm leading-5 text-blue-700">
                  This is the API Key and Value that you want to add to your header or your query
                  parameters. If you select Header as the location, a custom HTTP Header named{' '}
                  <b>"Key: Value"</b> will be added to the list. If you select Query Parameters as
                  the location, then <b>&Key=Value</b> will be added to your query.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <div>
              <label htmlFor="name" className="label-main">
                Name *
              </label>

              <input
                id="name"
                name={formModel.name}
                placeholder=""
                required
                className={`input-main ${
                  formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                }`}
                value={formModel.name}
                onChange={(e) => {
                  onChangeInput(e.target.value, 'name');
                }}
              />

              <span className="label-second">
                Choose a <strong>single word</strong> with <strong>no spaces</strong>.
              </span>

              {formErrorModel.name && (
                <span className="my-1 block text-sm !text-red-500">This area is required!</span>
              )}

              {formErrorModel.uniqueName && (
                <span className="label-second my-1 block !text-red-500">
                  {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                </span>
              )}
            </div>

            <div>
              <label htmlFor="name" className="label-main">
                Key *
              </label>

              <input
                id="apiKeyName"
                name="apiKeyName"
                type="text"
                maxLength="250"
                placeholder=""
                className="input-main"
                value={formModel.restInfo.apiKeyName}
                onChange={(e) => {
                  onChangeKeyValue(e.target.value, 'apiKeyName');
                }}
              />

              {formErrorModel.key && (
                <span className="my-1 block text-sm !text-red-500">This area is required!</span>
              )}
            </div>

            <div>
              <label htmlFor="name" className="label-main">
                Value *
              </label>

              <input
                id="apiKeyValue"
                name="apiKeyValue"
                type="text"
                placeholder=""
                className="input-main"
                value={formModel.restInfo.apiKeyValue}
                onChange={(e) => {
                  onChangeKeyValue(e.target.value, 'apiKeyValue');
                }}
              />

              {formErrorModel.value && (
                <span className="my-1 block text-sm !text-red-500">This area is required!</span>
              )}
            </div>

            <div className="flex justify-center">
              <button type="button" className="btn-danger" onClick={clearForm}>
                Clear
              </button>

              <button type="button" className="btn-success" onClick={onFormSubmitSecret}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyNewRest;
