import { useEffect, useState } from "react";
import { getAppDetail, postCommand } from "../network";
import { useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { Table } from "./Table";
import delve from "dlv";
import AppTabs from "../AppTabs";
import { useToast } from "hooks/use-toast";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";

export const AppDetail = () => {
  const [appDetail, setAppDetail] = useState({});
  const [inputs, setInputs] = useState([]);
  const [submit, setSubmit] = useState({});
  const [table, setTable] = useState({});
  const [inputHeights, setInputHeights] = useState({});
  const [isMaxHeight, setIsMaxHeight] = useState({});

  const params = useParams();

  const [isLoadingDeploy, setLoadingDeploy] = useState(false);
  const [refreshTenant, setRefreshTenant] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [formTitle, setFormTitle] = useState("");
  const [tableTitle, setTableTitle] = useState("Table");

  const { toast } = useToast();

  useEffect(() => {
    getAppDetail({ appId: params.id }).then(({ success, payload }) => {
      if (success) {
        setAppDetail(payload.internalApp);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(appDetail)) {
      const fetchedTitle = delve(appDetail, "features.form.title", "Form");
      setFormTitle(fetchedTitle);

      const fetchedTableTitle = delve(
        appDetail,
        "features.data.title",
        "Table"
      );
      setTableTitle(fetchedTableTitle);

      const formElements = delve(appDetail, "features.form.elements", []);
      const dataElements = delve(appDetail, "features.data.elements", []);
      const submitElement =
        formElements.find((input) => input.type === "submit") || {};

      setInputs(formElements.filter((input) => input.type !== "submit"));
      setSubmit(submitElement);
      setTable(dataElements.length > 0 ? dataElements[0] : {});
    }
  }, [appDetail]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    let userInputValues = {};
    inputs.forEach((input, index) => {
      userInputValues[input.name] = e.target.elements[index].value;
    });

    let finalCommand = submit.action.replace(
      /{{(.*?)}}/g,
      (match, p1) => userInputValues[p1] || ""
    );

    setLoadingDeploy(true);
    setRefreshTenant(false);

    postCommand({
      appId: appDetail.id,
      command: finalCommand,
    }).then(({ success, payload }) => {
      setLoadingDeploy(false);
      if (success) {
        setRefreshTenant(true);
      } else {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: `${payload.response}`,
        });
        setError(true);
      }
    });
  };

  const handleInputChange = (e, inputName) => {
    const scrollHeight = e.target.scrollHeight;
    const maxHeight = 150;
    const minHeight = 40;
    const inputValue = e.target.value;

    if (!inputValue.trim()) {
      setInputHeights(prev => ({
        ...prev,
        [inputName]: minHeight
      }));
      setIsMaxHeight(prev => ({
        ...prev,
        [inputName]: false
      }));
      return;
    }
    
    const newHeight = Math.min(Math.max(scrollHeight, minHeight), maxHeight);
    
    const hasReachedMax = scrollHeight >= maxHeight;
    
    setIsMaxHeight(prev => ({
      ...prev,
      [inputName]: hasReachedMax
    }));
    setInputHeights(prev => ({
      ...prev,
      [inputName]: newHeight
    }));
  };

  return (
    <>
      <AppTabs />
      {!isEmpty(inputs) && !isEmpty(submit) && (
        <Card>
          <CardHeader>
            <CardTitle>{formTitle}</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleFormSubmit}>
              <div className="grid w-full items-center gap-4">
                {inputs.map((inputObject, index) => (
                  <div
                    key={inputObject.id}
                    className="flex flex-col space-y-1.5"
                  >
                    <label>{inputObject.label}</label>
                    <Textarea
                      id={inputObject.name}
                      placeholder={`Enter your ${inputObject.name}`}
                      onChange={(e) => handleInputChange(e, inputObject.name)}
                      style={{
                        height: `${inputHeights[inputObject.name] || 40}px`,
                        transition: 'height 0.2s ease',
                        resize: 'none',
                        overflowY: isMaxHeight[inputObject.name] ? 'auto' : 'hidden',
                        minHeight: '40px',
                        maxHeight: '150px',
                      }}
                      className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                    />
                  </div>
                ))}
              </div>
              <CardFooter className="flex justify-end p-0 pt-6 m-0">
                <Button disabled={isLoadingDeploy} type={submit.type}>
                  {isLoadingDeploy ? "Loading" :submit.label }
                </Button>
              </CardFooter>
            </form>
          </CardContent>
        </Card>
      )}

      {!isEmpty(table) && (
         <Table
         table={table}
         refreshTenant={refreshTenant}
         appId={appDetail.id}
         title={tableTitle}
       />
      )}
    </>
  );
};
