import { Fragment, useEffect, useReducer, useState } from 'react';
import {
  Canvas,
  Edge,
  Add,
  Node,
  Label,
  CanvasPosition,
  upsertNode,
  createEdgeFromNodes,
  addNodeAndEdge,
  removeAndUpsertNodes
} from 'reaflow';

import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/20/solid';
import RunCommand from './workflowSteps/RunCommand';
import RunParser from './workflowSteps/RunParser';
import RunPrint from './workflowSteps/RunPrint';
import SelectedStepType from './workflowSteps/SelectedStepType';
import findIndex from 'lodash/findIndex';
import { RunCondition } from './workflowSteps/RunCondition';
import isString from 'lodash/isString';

import {
  convertNodesToStepObjects,
  createConditionAreaNode,
  createNewButtonNode,
  createNewFirstChildNode,
  createNewNode,
} from './helper';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const addId = (state, stepId) => {
  if (state.find((id) => id === `stepId_${stepId}`) === undefined) return stepId;
  else return addId(state, stepId + 1);
};

const nodeIdArrayReducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return [...state, action.stepId];
    case 'remove':
      return [...state.filter((id) => id !== action.selectedId)];
    case 'replace':
      return [...action.nodeIds];
    default:
      break;
  }
};

const WorkflowFlow = ({
  stepObjects,
  setStepObjects,
  isEdit,
  formNodes,
  formEdges,
  setFormNodes,
  setFormEdges
}) => {
  const [edges, setEdges] = useState([]);
  const [nodes, setNodes] = useState([]);

  const [nodeIds, dispatch] = useReducer(nodeIdArrayReducer, []);

  useEffect(() => {
    if (!isEdit) {
      const id = addId(nodeIds, nodeIds.length + 1);
      dispatch({ type: 'add', stepId: `stepId_${id}` });
      const firstNode = createNewNode(id);
      const buttonNode = createNewButtonNode('button');

      const newEdge = createEdgeFromNodes(firstNode, buttonNode);

      const result = addNodeAndEdge([firstNode], [newEdge], buttonNode);

      setNodes(result.nodes);
      setEdges(result.edges);
    } else {
      setNodes(formNodes);
      setEdges(formEdges);

      dispatch({
        type: 'replace',
        nodeIds: formNodes
          .filter((node) => {
            return (
              !node.id.startsWith('button#') &&
              !node.id.startsWith('conditionButton#') &&
              !node.id.startsWith('condition_')
            );
          })
          .map((node) => node.id)
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  // onChange Step
  const handleChangedStep = (updatedStep, fromComponent) => {
    const updatedStepObjectIndex = nodes.findIndex((step) => step.id === updatedStep.id);

    const copyStepObjects = [...nodes];
    copyStepObjects.splice(updatedStepObjectIndex, 1, updatedStep);

    if (updatedStep.data.workflowStepType === 'run-condition' && fromComponent !== 'RunCondition') {
      initializeBranch(copyStepObjects, updatedStep);
    } else {
      setNodes([...copyStepObjects]);
    }
  };

  function getToNodeIdFromEdge(nodeId, edges) {
    const toNode = edges.find((edge) => {
      return edge.from === nodeId;
    });
    return toNode !== undefined ? toNode.to : null;
  }

  function getNextStepIdForTrueCondition(parentId, nodes) {
    const nodesUnderParent = nodes.filter((node) => {
      return node.parent !== undefined && node.parent === parentId;
    });

    const firstChildNode = nodesUnderParent.find((node) => {
      return isFirstNode(node.id) === true;
    });

    return firstChildNode !== undefined ? firstChildNode.id : null;
  }

  function updateNextSteps(nodes, edges) {
    const copyNodes = [...nodes];
    const copyEdges = [...edges];
    const edgesWithoutButtonEdge = copyEdges.filter((edge) => {
      return edge.id.includes('button#') !== true;
    });
    edgesWithoutButtonEdge.forEach((edge) => {
      const fromNodeIndex = findIndex(copyNodes, (node) => node.id === edge.from);
      const fromNode = copyNodes[fromNodeIndex];

      if (fromNode.data.workflowStepType === 'run-condition') {
        const conditionAreaNodeId = edge.to;
        const conditionAreaToNodeId = getToNodeIdFromEdge(conditionAreaNodeId, copyEdges);
        if (conditionAreaNodeId !== null) {
          fromNode.data.nextStepId = conditionAreaToNodeId.startsWith('button#')
            ? ''
            : conditionAreaToNodeId;

          const nextStepForTrueCondition = getNextStepIdForTrueCondition(
            conditionAreaNodeId,
            copyNodes
          );
          fromNode.data.runConditionStep.conditionComparisonStep[0].nextStepId =
            nextStepForTrueCondition;
        }
      } else if (edge.to.startsWith('conditionButton#')) {
        const conditionAreaLastEdge = edges.find((edge) => {
          return edge.from === fromNode.parent;
        });
        if (
          !conditionAreaLastEdge.to.startsWith('button#') &&
          !conditionAreaLastEdge.to.startsWith('conditionButton#') // for handle nested run-condition lasts node nextSteps
        ) {
          fromNode.data.nextStepId = conditionAreaLastEdge.to;
        }
      } else {
        fromNode.data.nextStepId = edge.to;
      }

      copyNodes.splice(fromNodeIndex, 1, fromNode);
    });

    return { nodes: copyNodes, edges: copyEdges };
  }

  const handleAddEdgeClick = (event, edge) => {
    const id = addId(nodeIds, nodeIds.length + 1);
    dispatch({ type: 'add', stepId: `stepId_${id}` });
    const newNode = createNewNode(id, edge.parent !== undefined && edge.parent);

    const results = upsertNode(nodes, edges, edge, newNode);
    const nodesWithNextSteps = updateNextSteps(results.nodes, results.edges);
    setNodes(nodesWithNextSteps.nodes);
    setEdges(nodesWithNextSteps.edges);
  };

  function initializeBranch(updatedNodes, runConditionNode) {
    let copyNodes = [...updatedNodes];
    let copyEdges = [...edges];

    const edge = copyEdges.find((edge) => {
      return edge.from === runConditionNode.id;
    });

    const newNodeArea = createConditionAreaNode(
      runConditionNode.parent !== null ? runConditionNode.parent : null
    );

    const results = upsertNode(copyNodes, copyEdges, edge, newNodeArea);
    copyNodes = [...results.nodes];
    copyEdges = [...results.edges];

    const id = addId(nodeIds, nodeIds.length + 1);
    dispatch({ type: 'add', stepId: `stepId_${id}` });
    const newNode = createNewFirstChildNode(id, newNodeArea.id);

    copyNodes = [...copyNodes, newNode];

    const conditionButtonNode = createNewButtonNode('conditionButton', newNodeArea.id);
    const newEdge = createEdgeFromNodes(newNode, conditionButtonNode);

    const resultSubNodes = addNodeAndEdge(
      [...copyNodes],
      [...copyEdges, newEdge],
      conditionButtonNode
    );

    copyNodes = [...resultSubNodes.nodes];
    copyEdges = [...resultSubNodes.edges];

    const nodesWithNextSteps = updateNextSteps(copyNodes, copyEdges);

    setNodes(nodesWithNextSteps.nodes);
    setEdges(nodesWithNextSteps.edges);
  }

  const removeChilds = (childNodes) => {
    let result = { nodes: nodes, edges: edges };
    childNodes.forEach((node) => {
      dispatch({ type: 'remove', selectedId: node.id });
      result = removeAndUpsertNodes(result.nodes, result.edges, node);
    });

    return result;
  };

  const removeStep = (node) => {
    if (node.data.workflowStepType === 'run-condition') {
      const childNodes = nodes.filter((node) => {
        return (
          (isString(node.parent) && node.parent.startsWith('condition_')) ||
          node.id.startsWith('condition_')
        );
      });

      let results = removeChilds(childNodes);
      dispatch({ type: 'remove', selectedId: node.id });

      results = removeAndUpsertNodes(results.nodes, results.edges, node);
      const nodesWithNextSteps = updateNextSteps(results.nodes, results.edges);
      setNodes(nodesWithNextSteps.nodes);
      setEdges(nodesWithNextSteps.edges);
    } else {
      dispatch({ type: 'remove', selectedId: node.id });
      const results = removeAndUpsertNodes(nodes, edges, node);
      const nodesWithNextSteps = updateNextSteps(results.nodes, results.edges);
      setNodes(nodesWithNextSteps.nodes);
      setEdges(nodesWithNextSteps.edges);
    }
  };

  useEffect(() => {
    setFormNodes(nodes);
    setStepObjects(convertNodesToStepObjects(nodes));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);

  useEffect(() => {
    setFormEdges(edges);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edges]);

  const handleAddNodeClick = (node) => {
    const id = addId(nodeIds, nodeIds.length + 1);
    dispatch({ type: 'add', stepId: `stepId_${id}` });
    const newNode = createNewNode(id, node.data.type === 'conditionButton' && node.parent);

    const buttonEdge = edges.find((edge) => {
      return edge.id.endsWith(`-${node.id}`) === true;
    });

    const results = upsertNode(nodes, edges, buttonEdge, newNode);
    const nodesWithNextSteps = updateNextSteps(results.nodes, results.edges);
    setNodes(nodesWithNextSteps.nodes);
    setEdges(nodesWithNextSteps.edges);
  };

  const isFirstNode = (nodeId) => {
    const edge = edges.find((edge) => {
      return edge.to === nodeId;
    });

    return edge === undefined ? true : false;
  };

  return (
    <Canvas
      nodes={nodes}
      edges={edges}
      defaultPosition={CanvasPosition.TOP}
      direction="DOWN"
      className="h-screen rounded-lg border "
      onEnter={(event, node) => {
        console.log('Enter Node', event, node);
      }}
      node={(nodeProps) => (
        <Node
          {...nodeProps}
          style={{
            stroke: !nodeProps.id.startsWith('condition_') && '#FFFFFF',
            fill: 'white',
            strokeWidth: 1
          }}
          label={<Label style={{ fill: 'black' }} />}
          linkable={false}
        >
          {(nodeProps) => {
            if (nodeProps.node.data?.type === 'step') {
              return (
                <foreignObject
                  className="rounded-lg border bg-white shadow-[0_10px_20px_rgba(41,_67,_209,_0.36)]"
                  width={nodeProps.width}
                  height={nodeProps.height}
                >
                  {!isFirstNode(nodeProps.node.id) && (
                    <Menu as="div" className="absolute right-2 top-2">
                      <div>
                        <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    removeStep(nodeProps.node);
                                  }}
                                  className={classNames(
                                    active
                                      ? 'cursor-pointer bg-gray-100 text-gray-900'
                                      : 'text-gray-700',
                                    'flex items-center gap-2 px-4 py-2 text-sm'
                                  )}
                                >
                                  <TrashIcon className="h-5 w-5" /> Delete
                                </div>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}

                  {nodeProps.node.data.workflowStepType === null && (
                    <SelectedStepType stepObject={nodeProps.node} onChange={handleChangedStep} />
                  )}
                  {nodeProps.node.data.workflowStepType === 'run-print' && (
                    <RunPrint stepObject={nodeProps.node} onChange={handleChangedStep} />
                  )}
                  {nodeProps.node.data.workflowStepType === 'run-command' && (
                    <RunCommand stepObject={nodeProps.node} onChange={handleChangedStep} />
                  )}
                  {nodeProps.node.data.workflowStepType === 'run-parser' && (
                    <RunParser stepObject={nodeProps.node} onChange={handleChangedStep} />
                  )}
                  {nodeProps.node.data.workflowStepType === 'run-condition' && (
                    <RunCondition stepObject={nodeProps.node} onChange={handleChangedStep} />
                  )}
                </foreignObject>
              );
            } else if (
              nodeProps.node.data?.type === 'button' ||
              nodeProps.node.data?.type === 'conditionButton'
            ) {
              return (
                <foreignObject
                  className="rounded-lg bg-gray-200"
                  width={nodeProps.width}
                  height={nodeProps.height}
                >
                  <div className="flex h-full w-full items-center justify-center rounded-lg ">
                    <button
                      className="w-full"
                      onClick={() => {
                        handleAddNodeClick(nodeProps.node);
                      }}
                    >
                      Add Step
                    </button>
                  </div>
                </foreignObject>
              );
            }
          }}
        </Node>
      )}
      edge={(edgeProps) => {
        return (
          <Edge
            {...edgeProps}
            add={
              <Add
                hidden={
                  edgeProps.id.split('#')[0].endsWith('button') ||
                  edgeProps.id.split('#')[0].endsWith('conditionButton')
                    ? true
                    : false
                }
              />
            }
            onAdd={handleAddEdgeClick}
          />
        );
      }}
    ></Canvas>
  );
};

export default WorkflowFlow;
