import axios from 'axios';
import { urls } from 'constants/index';

const sendAddExecutionCommand = async (formModel) => {
  try {
      const response = await axios.post(urls.URL_WORKSPACE_EXECUTION_COMMAND, formModel, {
          withCredentials: true,
          headers: { 'content-type': 'application/json; charset=utf-8' }
      });
      return { success: true, response: response.data }; 
  } catch (e) {
      console.log(e);
      return e.data;
  }
};

export { sendAddExecutionCommand };
