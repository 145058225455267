import { addNewTriggersViewStates } from 'constants';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { fetchCommands, fetchConnections, fetchWorkflows, saveNewCron } from './io';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from '@heroicons/react/20/solid';
import timezones from './timezones.json';
import cronValidate from 'cron-validate';
import cronstrue from 'cronstrue';
import { fetchData } from '../../TriggerList/CronList/io';
import { useToast} from 'hooks/use-toast'
// Cron-based schedule functions
const indicies = [0, 1, 2, 3, 4, 5];

function splitValue(value) {
  const values = value.split(' ').filter((w) => w);
  if (values.length > 6) return values.slice(0, 6);
  while (values.length < 6) values.push('');
  return values;
}

function simpleError(e) {
  const i = e.indexOf(' (Input cron: ');
  if (i < 0) return e;
  const clipped = e.slice(0, i);
  if (
    clipped ===
    'Cannot specify both daysOfMonth and daysOfWeek field when mustHaveBlankDayField option is enabled.'
  )
    return 'Cannot specify both daysOfMonth and daysOfWeek field.';
  if (clipped.slice(0, 9) === "Element '") return 'Element ' + clipped.slice(9);
  return clipped;
}

const initialFormModel = {
  name: '',
  kind: 'cron',
  description: '',
  connections: '',
  commands: '',
  workflows: '',
  cronTriggerInfo: {
    flexibleTimeWindow: {
      mode: 'OFF', //"OF OR FLEXIBLE"
      maximumWindowInMinutes: null
    },
    argument: '',
    workflowArgument: '',
    scheduleExpression: '',
    scheduleExpressionTimezone: '',
    startDate: null,
    endDate: null,
    state: 'ENABLED',
    kmsKeyArn: ''
  }
};

export default function CronNew() {
  const timeMapping = {
    OFF: null,
    '15 Minutes': 15,
    '30 Minutes': 30,
    '45 Minutes': 45,
    '1 Hours': 60,
    '2 Hours': 120,
    '4 Hours': 240
  };

  const [formModel, setFormModel] = useState(initialFormModel);
  const [commands, setCommands] = useState([]);
  const [connections, setConnections] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [selectedConnectionKind, setSelectedConnectionKind] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [schedules, setSchedules] = useState(['On-time schedule', 'Recurring schedule']);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [flexibleTimeWindowMode, setFlexibleTimeWindowMode] = useState(Object.keys(timeMapping));
  // eslint-disable-next-line no-unused-vars
  const [recurringSchedule, setRecurringSchedule] = useState([
    {
      name: 'Cron-based schedule',
      desc: 'A schedule set using a cron expression that runs at a specific time, such as 8:00 a.m. PST on the first Monday of every month.'
    },
    {
      name: 'Rate-based schedule',
      desc: 'A schedule that runs at a regular rate, such as every 10 minutes.'
    }
  ]);
  const [selectedRecurringSchedule, setSelectedRecurringSchedule] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(startDate);
  const [selectHours, setSelectHours] = useState('');
  const [viewState, setViewState] = useState(
    addNewTriggersViewStates.FETCHING_COMMANDS,
    addNewTriggersViewStates.FETCHING_CONNECTIONS
  );
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false
  });
  const [cronList, setCronList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [permissions, setPermissions] = useState(['Connections & Commands', 'Workflows']);
  const [selectedPermission, setSelectedPermission] = useState(null);

  // Cron-based schedule states and refs
  const [value, setValue] = useState('');
  const [values, setValues] = useState(() => splitValue(value));
  const refs = useRef([]);
  const cronLabels = ['Minutes', 'Hours', 'Day of Month', 'Month', 'Day of Week', 'Year'];

  //rate-based schedule states
  const [rateBasedValue, setRateBasedValue] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [rateUnitOptions, setRateUnitOptions] = useState(['Minutes', 'Hours', 'Days']);
  const [selectedRateUnitOption, setSelectedRateUnitOption] = useState('');
  const { toast } = useToast();

  const cronValidateMessages = useMemo(() => {
    const result = cronValidate(value, {
      preset: 'aws-cloud-watch'
    });
    if (result.isValid()) return;
    return result.getError().map(simpleError);
  }, [value]);

  const cronstrueResult = useMemo(() => {
    try {
      return { isValid: true, msg: cronstrue.toString('0 ' + value) };
    } catch (e) {
      return { isValid: false, msg: `${e}` };
    }
  }, [value]);

  //Change Input
  function onChangeInput(value, field) {
    const trimmedValue = value.trim();
    if (field === 'argument' || field === 'workflowArgument') {
      setFormModel({
        ...formModel,
        cronTriggerInfo: {
          ...formModel.cronTriggerInfo,
          [field]: value
        }
      });
    } else if (field === 'name' && (trimmedValue !== '' || value === '')) {
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  }

  const onChangeFlexibleWindow = (value, field) => {
    setFormModel((prevState) => {
      prevState.cronTriggerInfo.flexibleTimeWindow.maximumWindowInMinutes = value;
      return {
        ...prevState
      };
    });
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const onChangeTimezone = (value, field) => {
    setFormModel((prevState) => {
      prevState.cronTriggerInfo.scheduleExpressionTimezone = value;
      return {
        ...prevState
      };
    });
  };

  useEffect(() => {
    if (selectedDate && selectHours) {
      const year = selectedDate.getFullYear();
      let month = selectedDate.getMonth() + 1;
      let date = selectedDate.getDate();
      // Add '0' prefix if month or date is a single digit
      month = month < 10 ? `0${month}` : month;
      date = date < 10 ? `0${date}` : date;
      const newScheduleExpression = `at(${year}-${month}-${date}T${selectHours})`;

      // update formModel for scheduleExpression
      setFormModel((prevFormModel) => ({
        ...prevFormModel,
        cronTriggerInfo: {
          ...prevFormModel.cronTriggerInfo,
          scheduleExpression: newScheduleExpression
        }
      }));
    }
  }, [selectedDate, selectHours]);

  // add cronbased inside formModel
  useEffect(() => {
    if (selectedRecurringSchedule === 'Cron-based schedule') {
      let newScheduleExpression = `cron(${value})`;
      // update formModel for scheduleExpression
      setFormModel((prevFormModel) => ({
        ...prevFormModel,
        cronTriggerInfo: {
          ...prevFormModel.cronTriggerInfo,
          scheduleExpression: newScheduleExpression
        }
      }));
    } else if (selectedRecurringSchedule === 'Rate-based schedule') {
      let newScheduleExpression = `rate(${rateBasedValue} ${selectedRateUnitOption})`;
      // update formModel for scheduleExpression
      setFormModel((prevFormModel) => ({
        ...prevFormModel,
        cronTriggerInfo: {
          ...prevFormModel.cronTriggerInfo,
          scheduleExpression: newScheduleExpression
        }
      }));
    }
  }, [selectedRecurringSchedule, value, rateBasedValue, selectedRateUnitOption]);

  useEffect(() => {
    const selectedConnection = connections.find(
      (connection) => connection.name === formModel.connections
    );
    setSelectedConnectionKind(selectedConnection ? selectedConnection.kind : null);
  }, [formModel.connections, connections]);

  useEffect(() => {
    setViewState(
      addNewTriggersViewStates.FETCHING_COMMANDS,
      addNewTriggersViewStates.FETCHING_CONNECTIONS,
      addNewTriggersViewStates.FETCHING_WORKFLOWS
    );
    if (selectedConnectionKind) {
      fetchCommands(selectedConnectionKind).then(
        ({ success, payload: { commands } }) => {
          if (!success) {
            alert('There was a problem fetching the command list. Please try again later.');
            setViewState(addNewTriggersViewStates.ERROR);
            return;
          }

          setCommands(commands);
          setViewState(addNewTriggersViewStates.IDLE);
        },
        (err) => {
          console.log(err);
          setViewState(addNewTriggersViewStates.IDLE);
        }
      );
    }
    if (selectedConnectionKind === 'agent') {
      fetchCommands('ssh').then(
        ({ success, payload: { commands } }) => {
          if (!success) {
            alert('There was a problem fetching the command list. Please try again later.');
            return;
          }
          setCommands(commands);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      setCommands([]);
    }

    fetchConnections().then(
      ({ success, payload: { connections } }) => {
        if (!success) {
          alert('There was a problem fetching the command list. Please try again later.');
          setViewState(addNewTriggersViewStates.ERROR);
          return;
        }

        setConnections(connections);
        setViewState(addNewTriggersViewStates.IDLE);
      },
      (err) => {
        console.log(err);
        setViewState(addNewTriggersViewStates.IDLE);
      }
    );
    fetchWorkflows().then(
      ({ success, payload: { workflows } }) => {
        if (!success) {
          alert('There was a problem fetching the workflow list. Please try again later.');
          setViewState(addNewTriggersViewStates.ERROR);
          return;
        }
        setWorkflows(workflows);
        setViewState(addNewTriggersViewStates.IDLE);
      },
      (err) => {
        console.log(err);
        setViewState(addNewTriggersViewStates.IDLE);
      }
    );
  }, [selectedConnectionKind]);

  useEffect(() => {
    fetchData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const filteredCrons = payload.triggers.filter((type) => type.kind === 'cron');
        setCronList(filteredCrons);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (cronList.find((cron) => cron.name.toLowerCase() === formModel.name.toLowerCase())) {
        setFormErrorModel({
          ...formErrorModel,
          uniqueName: true
        });
      } else {
        setFormErrorModel({
          ...formErrorModel,
          uniqueName: false
        });
      }
    };
    checkIfNameExists();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModel.name, cronList]);

  const onBackClick = (evt) => {
    evt.preventDefault();
    setViewState(addNewTriggersViewStates.REDIRECT_TO_PARENT);
  };

  const onFormSubmit = (evt) => {
    evt.preventDefault();

    const obj = {
      name: formModel.name.trim() === '',
      uniqueName: cronList.find((cron) => cron.name.toLowerCase() === formModel.name.toLowerCase())
        ? true
        : false
    };
    setFormErrorModel(obj);
    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    }

    if (formModel.cronTriggerInfo.flexibleTimeWindow.maximumWindowInMinutes !== null) {
      formModel.cronTriggerInfo.flexibleTimeWindow.mode = 'FLEXIBLE';
    } else {
      formModel.cronTriggerInfo.flexibleTimeWindow.mode = 'OFF';
    }
    saveNewCron(formModel)
      .then(() => {
        setTimeout(() => {
          setViewState(addNewTriggersViewStates.REDIRECT_TO_PARENT);
          toast({
            title: 'Successfully',
            description: 'New cron added'
          });
        }, 300);
      })
      .catch((err) => {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem saving your cron. Please try again later.'
        });
      });
  };

  useEffect(()=> {
    if(selectedPermission === 'Workflows'){
      setFormModel((prevFormModel) => ({
        ...prevFormModel,
        connections:'',
        commands:'',
        cronTriggerInfo: {
          ...prevFormModel.cronTriggerInfo,
          argument:'',
        }
      }));
    }else{
      setFormModel((prevFormModel) => ({
        ...prevFormModel,
        workflows:'',
        cronTriggerInfo: {
          ...prevFormModel.cronTriggerInfo,
          workflowArgument:'',
        }
      }));
    }
  },[selectedPermission]);

  if (viewState === addNewTriggersViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/triggers/cron" />;
  }

  const disabled = viewState !== addNewTriggersViewStates.IDLE;
  const btnExtraClassName = disabled ? 'opacity-50 cursor-not-allowed' : '';

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <form action="/triggers/cron" onSubmit={onFormSubmit}>
              <h2 className="mb-4 mt-0 text-center text-base font-bold">NEW CRON</h2>
              <div className="flex flex-col gap-6">
                <div>
                  <label htmlFor="name" className="label-main">
                    Name
                  </label>
                  <input
                    id="name"
                    required
                    value={formModel.name}
                    maxLength="150"
                    onChange={(e) => {
                      onChangeInput(e.target.value, 'name');
                    }}
                    disabled={disabled}
                    placeholder="Cron Name"
                    className={`${btnExtraClassName} input-main ${
                      formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                    }`}
                  />
                  <span className="label-second">
                    This is a <strong>unique name</strong> to describe what your Ops team is working
                    on.
                  </span>

                  {formErrorModel.name && (
                    <span className="label-second my-1 block !text-red-500">
                      This area is required!
                    </span>
                  )}

                  {formErrorModel.uniqueName && (
                    <span className="label-second my-1 block !text-red-500">
                      {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                    </span>
                  )}
                </div>

                <div>
                  <label htmlFor="description" className="label-main">
                    Description
                  </label>
                  <input
                    id="description"
                    value={formModel.description}
                    disabled={disabled}
                    onChange={(e) => {
                      onChangeInput(e.target.value, 'description');
                    }}
                    maxLength={125}
                    placeholder="Cron description"
                    className={`${btnExtraClassName} input-main`}
                  />
                </div>
              </div>
              <div className="my-4 rounded-md border dark:border-slate-700">
                <div className="bg-gray-100 px-4 py-2 dark:bg-slate-700">
                  <h2 className="text-lg font-extrabold">Permission</h2>
                </div>
                <div className="flex items-center gap-2 px-4 py-2">
                  <div className="flex w-full items-center gap-2">
                    {permissions.map((permission, index) => {
                      const isSelected = selectedPermission === permission;
                      return (
                        <label
                          className={`flex w-full items-center gap-2 rounded-md border px-4 py-2 dark:border-slate-700 ${
                            isSelected
                              ? 'border-blue-600 bg-blue-300 dark:border-slate-700 dark:bg-slate-600'
                              : ''
                          }`}
                          key={index}
                        >
                          <input
                            type="radio"
                            value={permission}
                            checked={isSelected}
                            onChange={(e) => setSelectedPermission(e.target.value)}
                          />
                          {permission}
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="px-4 py-2">
                  {selectedPermission === 'Connections & Commands' && (
                    <>
                      <div className="pt-3">
                        <label htmlFor="connections" className="label-main mb-2">
                          Connections
                        </label>
                        <select
                          id="connections"
                          className={`input-main`}
                          value={formModel.connections}
                          required
                          onChange={(e) => {
                            onChangeInput(e.target.value, 'connections');
                          }}
                        >
                          {connections.length === 0 ? (
                            <option>There is no connections.</option>
                          ) : (
                            <option value="">select a connection</option>
                          )}

                          {connections &&
                            connections.map((connection, index) => {
                              return (
                                <option value={connection.name} key={index}>
                                  {connection.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div>
                        <label htmlFor="commands" className="label-main mb-2">
                          Commands
                        </label>
                        <select
                          id="commands"
                          className={`input-main`}
                          value={formModel.commands}
                          required
                          onChange={(e) => {
                            onChangeInput(e.target.value, 'commands');
                          }}
                        >
                          {!formModel.connections ? (
                            <option value="">select a connection for commands</option>
                          ) : (
                            <option value="">select a command</option>
                          )}

                          {formModel.connections &&
                            commands.map((command, index) => {
                              return (
                                <option value={command.name} key={index}>
                                  {command.name}
                                </option>
                              );
                            })}
                        </select>

                        <p className="mt-2 block w-full p-2 sm:w-2/5">
                          <a
                            href="/connections/new"
                            className="hidden rounded-sm p-2 pl-0 underline duration-150 ease-out hover:text-secondary"
                          >
                            create a new connection
                          </a>
                        </p>
                      </div>
                      <div className="-mt-3">
                        <label htmlFor="argument" className="label-main">
                          Argument
                        </label>
                        <input
                          id="argument"
                          value={formModel.argument}
                          disabled={disabled}
                          onChange={(e) => {
                            onChangeInput(e.target.value, 'argument');
                          }}
                          maxLength="500"
                          placeholder="argument"
                          className={`${btnExtraClassName} input-main`}
                        />
                      </div>
                    </>
                  )}
                  {selectedPermission === 'Workflows' && (
                    <>
                      <div className="pt-3">
                        <label htmlFor="workflows" className="label-main mb-2">
                          Workflows
                        </label>
                        <select
                          id="workflows"
                          className={`input-main`}
                          value={formModel.workflows}
                          required
                          onChange={(e) => {
                            onChangeInput(e.target.value, 'workflows');
                          }}
                        >
                          {workflows.length === 0 ? (
                            <option>There is no workflows.</option>
                          ) : (
                            <option value="">select a workflow</option>
                          )}

                          {workflows &&
                            workflows.map((workflow, index) => {
                              return (
                                <option value={workflow.name} key={index}>
                                  {workflow.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="mt-3">
                        <label htmlFor="workflowArgument" className="label-main">
                          Argument
                        </label>
                        <input
                          id="workflowArgument"
                          value={formModel.workflowArgument}
                          disabled={disabled}
                          onChange={(e) => {
                            onChangeInput(e.target.value, 'workflowArgument');
                          }}
                          maxLength="500"
                          placeholder="Workflow argument"
                          className={`${btnExtraClassName} input-main`}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="rounded-md border dark:border-slate-600">
                <div className="bg-gray-100 px-4 py-2 dark:bg-slate-700">
                  <h2 className="text-lg font-extrabold">Schelude pattern</h2>
                </div>
                <div className="px-4 py-2">
                  <div>
                    <h2 className="py-1 font-extrabold">Ocurrance</h2>
                    <p className="text-gray-500">
                      You can define an one-time or recurent schedule.
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2 px-4 py-2">
                  <div className="flex w-full items-center gap-2">
                    {schedules.map((schedule, index) => {
                      const isSelected = selectedSchedule === schedule;
                      return (
                        <label
                          className={`flex w-full items-center gap-2 rounded-md border px-4 py-2 dark:border-slate-700 ${
                            isSelected
                              ? 'border-blue-600 bg-blue-300 dark:border-slate-700 dark:bg-slate-600'
                              : ''
                          }`}
                          key={index}
                        >
                          <input
                            type="radio"
                            value={schedule}
                            checked={isSelected}
                            onChange={(e) => setSelectedSchedule(e.target.value)}
                          />
                          {schedule}
                        </label>
                      );
                    })}
                  </div>
                </div>
                {selectedSchedule === 'On-time schedule' && (
                  <div className="px-4 py-2">
                    <div>
                      <h2 className="py-1 font-extrabold">Date and time</h2>
                      <p className="text-gray-500">The date and time to invoke the target.</p>
                    </div>
                    <div className="flex w-full items-center gap-4 py-4">
                      <div className="block">
                        <div className="flex items-center gap-1">
                          <DatePicker
                            selected={selectedDate ? selectedDate : startDate}
                            onChange={(date) => setSelectedDate(date)}
                            dateFormat="yyyy/MM/dd"
                            className={`${btnExtraClassName} input-main`}
                          />
                          <CalendarIcon className="h-6 w-6" />
                        </div>
                        <div>
                          <span className="text-xs text-gray-500">YYYY/MM/DD</span>
                        </div>
                      </div>
                      <div>
                        <input
                          name="hours"
                          id="hours"
                          type="text"
                          placeholder="hh:mm"
                          defaultValue={selectHours}
                          onChange={(e) => {
                            setSelectHours(e.target.value);
                          }}
                          className={`${btnExtraClassName} input-main`}
                        />
                        <span className="text-xs text-gray-500">Use 24-hour format timestampt</span>
                      </div>
                      <div>
                        <select
                          id="scheduleExpressionTimezone"
                          name="scheduleExpressionTimezone"
                          className="input-main "
                          value={formModel.cronTriggerInfo.scheduleExpressionTimezone}
                          onChange={(e) => {
                            onChangeTimezone(e.target.value, 'scheduleExpressionTimezone');
                          }}
                        >
                          <option value="" disabled>
                            Select a timezone
                          </option>
                          {timezones.map((timezone, index) => {
                            return (
                              <option key={index} value={timezone}>
                                {timezone}
                              </option>
                            );
                          })}
                        </select>

                        <span className="text-xs text-gray-500">Timezone</span>
                      </div>
                    </div>
                    <div className="mt-2">
                      <h2 className="py-1 font-extrabold">Flexible time window</h2>
                      <p className="text-gray-500">
                        If you choose a flexbile window, Scheduler invokes your schedule within the
                        time window you specify. For example, if you choose 15 minutes, your
                        schedule runs within 15 minutes after the schedule start time.
                      </p>
                      <div className="mt-2">
                        <select
                          id="maximumWindowInMinutes"
                          name="maximumWindowInMinutes"
                          className="input-main w-1/2"
                          value={getKeyByValue(
                            timeMapping,
                            formModel.cronTriggerInfo.flexibleTimeWindow.maximumWindowInMinutes
                          )}
                          onChange={(e) => {
                            onChangeFlexibleWindow(
                              timeMapping[e.target.value],
                              'maximumWindoxwInMinutes'
                            );
                          }}
                        >
                          <option value="" disabled>
                            Select a flexible time
                          </option>
                          {flexibleTimeWindowMode.map((twindow, index) => {
                            return (
                              <option key={index} value={twindow}>
                                {twindow}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                {selectedSchedule === 'Recurring schedule' && (
                  <div className="px-4 py-2">
                    <div className="mt-2">
                      <h2 className="py-1 font-extrabold">Schedule Type</h2>
                      <p className="text-gray-500">
                        Choose the schedule type that best meets your needs.
                      </p>
                    </div>
                    <div className="flex gap-2 py-4">
                      <div className="flex w-full  items-stretch gap-2">
                        {recurringSchedule.map((schedule, index) => {
                          const isSelected = selectedRecurringSchedule === schedule.name;
                          return (
                            <>
                              <div
                                className={`block w-full rounded-md border px-4 py-2 dark:border-slate-700 ${
                                  isSelected
                                    ? 'border-blue-600 bg-blue-300 dark:border-slate-700 dark:bg-slate-600'
                                    : ''
                                }`}
                                key={index}
                              >
                                <label className="mt-2 block w-full items-center">
                                  <input
                                    type="radio"
                                    value={schedule.name}
                                    checked={isSelected}
                                    onChange={(e) => setSelectedRecurringSchedule(e.target.value)}
                                  />
                                  <span className="pl-2">{schedule.name}</span>
                                  <div className="mt-2 text-gray-500 dark:text-gray-200">
                                    {schedule.desc}
                                  </div>
                                </label>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    {selectedRecurringSchedule === 'Cron-based schedule' && (
                      <>
                        <div className="mt-2 block">
                          <h2 className="font-extrabold">Cron expression</h2>
                          <p className="text-gray-500">
                            Define the cron expression for the schedule.
                          </p>
                        </div>
                        <div className="flex items-center gap-4 py-4">
                          <h2 className="font-extrabold text-black dark:text-gray-200">Cron</h2>
                          <span className="font-extrabold text-black dark:text-gray-200">(</span>
                          <div className="flex items-center gap-2">
                            {indicies.map((i) => (
                              <div className="block">
                                <input
                                  key={i}
                                  ref={(el) => {
                                    refs.current[i] = el;
                                  }}
                                  value={values[i]}
                                  onChange={(e) => {
                                    setValues((prev) => {
                                      const values = [...prev];
                                      values[i] = e.target.value;
                                      setValue(values.join(' '));
                                      return values;
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === ' ') {
                                      e.preventDefault();
                                      refs.current[e.shiftKey ? i - 1 : i + 1]?.select();
                                    }
                                  }}
                                  className="input-secondary dark:!border-slate-600"
                                />
                                <div className="flex items-center justify-center">
                                  <label className="text-xs text-gray-500">{cronLabels[i]}</label>
                                </div>
                              </div>
                            ))}
                          </div>
                          <span className="font-extrabold text-black dark:text-gray-200">)</span>
                        </div>
                        <div>
                          <h2>
                            <code className="text-xs text-secondary">Validate</code>
                          </h2>
                          {!cronValidateMessages && (
                            <div className="my-2">
                              <code className="rounded-md bg-green-100 px-2 py-0.5 text-xs text-green-700">
                                valid
                              </code>
                            </div>
                          )}
                          {cronValidateMessages?.map((e, i) => (
                            <div key={i} className="my-2">
                              <code className="rounded-md bg-red-100 px-2 py-0.5 text-xs text-red-500">
                                {e}
                              </code>
                            </div>
                          ))}
                        </div>
                        <div className="my-5">
                          <div>
                            {cronstrueResult.isValid && (
                              <>
                                <h2>
                                  <code className="text-xs text-secondary">Cron detail</code>
                                </h2>
                                <code className="text-xs text-gray-500">{cronstrueResult.msg}</code>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="mt-2">
                          <h2 className="py-1 font-extrabold">Flexible time window</h2>
                          <p className="text-gray-500">
                            If you choose a flexbile window, Scheduler invokes your schedule within
                            the time window you specify. For example, if you choose 15 minutes, your
                            schedule runs within 15 minutes after the schedule start time.
                          </p>
                          <div className="mt-2">
                            <select
                              id="maximumWindowInMinutes"
                              name="maximumWindowInMinutes"
                              className="input-main w-1/2"
                              value={getKeyByValue(
                                timeMapping,
                                formModel.cronTriggerInfo.flexibleTimeWindow.maximumWindowInMinutes
                              )}
                              onChange={(e) => {
                                onChangeFlexibleWindow(
                                  timeMapping[e.target.value],
                                  'maximumWindoxwInMinutes'
                                );
                              }}
                            >
                              <option value="" disabled>
                                Select a flexible time
                              </option>
                              {flexibleTimeWindowMode.map((twindow, index) => {
                                return (
                                  <option key={index} value={twindow}>
                                    {twindow}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                    {selectedRecurringSchedule === 'Rate-based schedule' && (
                      <>
                        <div className="mt-2 block">
                          <h2 className="font-extrabold">Rate expression</h2>
                          <p className="text-gray-500">
                            Enter a value and the unit of time to run the schedule.
                          </p>
                        </div>
                        <div className="flex items-center gap-4 py-4">
                          <h2 className="font-extrabold text-black ">Rate</h2>
                          <span className="font-extrabold text-black ">(</span>
                          <div className="w-12">
                            <input
                              id="rateBasedValue"
                              name="rateBasedValue"
                              type="text"
                              value={rateBasedValue}
                              className="input-secondary"
                              onChange={(e) => setRateBasedValue(e.target.value)}
                            />
                            <div className="text-left">
                              <label className="text-xs text-gray-500">Value</label>
                            </div>
                          </div>
                          <div className="w-16">
                            <select
                              id="rateUnitOptions"
                              name="rateUnitOptions"
                              type="text"
                              defaultValue={selectedRateUnitOption}
                              className="input-secondary"
                              onChange={(e) => setSelectedRateUnitOption(e.target.value)}
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              {rateUnitOptions.map((units, index) => {
                                return (
                                  <option key={index} value={units}>
                                    {units}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="text-left">
                              <label className="text-xs text-gray-500">Unit</label>
                            </div>
                          </div>
                          <span className="font-extrabold text-black ">)</span>
                        </div>
                        <div className="mt-2">
                          <h2 className="py-1 font-extrabold">Flexible time window</h2>
                          <p className="text-gray-500">
                            If you choose a flexbile window, Scheduler invokes your schedule within
                            the time window you specify. For example, if you choose 15 minutes, your
                            schedule runs within 15 minutes after the schedule start time.
                          </p>
                          <div className="mt-2">
                            <select
                              id="maximumWindowInMinutes"
                              name="maximumWindowInMinutes"
                              className="input-main w-1/2"
                              value={getKeyByValue(
                                timeMapping,
                                formModel.cronTriggerInfo.flexibleTimeWindow.maximumWindowInMinutes
                              )}
                              onChange={(e) => {
                                onChangeFlexibleWindow(
                                  timeMapping[e.target.value],
                                  'maximumWindoxwInMinutes'
                                );
                              }}
                            >
                              <option value="" disabled>
                                Select a flexible time
                              </option>
                              {flexibleTimeWindowMode.map((twindow, index) => {
                                return (
                                  <option key={index} value={twindow}>
                                    {twindow}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                    {selectedRecurringSchedule === 'Cron-based schedule' ||
                    selectedRecurringSchedule === 'Rate-based schedule' ? (
                      <div className="my-4 rounded-md border dark:border-slate-600">
                        <div className="bg-gray-100 px-4 py-2 dark:bg-slate-700">
                          <h2 className="text-lg font-extrabold">Timeframe</h2>
                        </div>
                        <div className="px-4 py-2">
                          <div>
                            <h2 className="py-1 font-extrabold">Daylight saving time</h2>
                            <p className="text-gray-500">
                              EventBridge Scheduler automatically adjusts your schedule for daylight
                              saving time. When time shifts forward in the Spring, if a cron
                              expression falls on a non-existent date and time, your schedule
                              invocation is skipped. When time shifts backwards in the Fall, your
                              schedule runs only once and does not repeat its invocation. The
                              following invocations occur normally at the specified date and time.
                            </p>
                          </div>
                          <div className="my-4">
                            <span className="text-md font-extrabold">Timezone</span>
                            <p className="text-xs text-gray-500">The timezone for the schedule.</p>
                            <select
                              id="scheduleExpressionTimezone"
                              name="scheduleExpressionTimezone"
                              className="input-main "
                              value={formModel.cronTriggerInfo.scheduleExpressionTimezone}
                              onChange={(e) => {
                                onChangeTimezone(e.target.value, 'scheduleExpressionTimezone');
                              }}
                            >
                              <option value="" disabled>
                                Select a timezone
                              </option>
                              {timezones.map((timezone, index) => {
                                return (
                                  <option key={index} value={timezone}>
                                    {timezone}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>

              <div className="mt-4 flex justify-end">
                <span className="inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    disabled={disabled}
                    onClick={onBackClick}
                    className={`${btnExtraClassName} cancel-button`}
                  >
                    Cancel
                  </button>
                </span>
                <span className="ml-3 inline-flex rounded-md shadow-sm">
                  <button
                    disabled={disabled}
                    type="submit"
                    className={`${btnExtraClassName} save-button`}
                  >
                    Save
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
