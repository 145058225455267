import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { postApiKey } from 'features/account/network';
import { useToast} from 'hooks/use-toast'
import { Spinner } from 'components/Spinner';
import { useDispatch } from 'react-redux';
import { setApiKey } from 'redux/slices/accountSlice';
import { useSelector } from 'react-redux';

export const CreateApiKeyModal = ({
  showApiKeyModal,
  setShowApiKeyModal,
  setSuccessApiKeyCreation
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      description: ''
    }
  });
  const dispatch = useDispatch();
  const accountState = useSelector((state) => state.accountState);
  const [open, setOpen] = useState(showApiKeyModal);
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setOpen(showApiKeyModal);
  }, [showApiKeyModal]);

  useEffect(() => {
    if (!open) {
      setShowApiKeyModal(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (accountState.apiKey !== '') {
      setSuccessApiKeyCreation(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountState.apiKey]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="mx-auto flex w-full max-w-md flex-col space-y-2">
                  <div className="flex flex-col items-center justify-center space-y-2 text-center">
                    <div className="text-xl font-semibold">
                      <p>Create a API Key</p>
                    </div>
                  </div>

                  <div>
                    <form
                      action=""
                      method="post"
                      onSubmit={handleSubmit(async (data) => {
                        setIsLoading(true);
                        const { success, apiKeyInfo } = await postApiKey({
                          name: data.name,
                          description: data.description
                        });
                        if (!success) {
                          toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Try again later.'
      });
                          return;
                        } else {
                          toast({
                            title: 'Successfully',
                            description: 'API Key Created.'
                          });
                          
                          // window.location.reload();
                          reset();
                          setIsLoading(false);
                          setShowApiKeyModal(false);
                          dispatch(setApiKey(apiKeyInfo.apiKey));
                        }
                      })}
                    >
                      <div className=" flex flex-col gap-2">
                        <div className="mx-auto flex w-full max-w-xs flex-row items-center justify-between gap-2"></div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Name*
                          </label>
                          <div className="mt-2">
                            <input
                              {...register('name', {
                                required: 'This area is required!'
                              })}
                              className="block w-full rounded-md border-0 p-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors.name !== undefined && (
                              <span className="label-second my-1 block !text-red-500">
                                {errors.name.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Description
                          </label>
                          <div className="mt-2">
                            <input
                              {...register('description', {
                                required: false
                              })}
                              className="block w-full rounded-md border-0 p-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="mt-4 flex w-full flex-col">
                          {isLoading ? (
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                            >
                              <Spinner />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                            >
                              Create a API Key
                            </button>
                          )}
                        </div>
                        <div></div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
