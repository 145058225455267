import React, { useEffect, useLayoutEffect, useState } from 'react';
import { features, globalViewStates, postMethods } from '../../../constants';
import { Navigate, useParams } from 'react-router-dom';

import { commandExists, addOrUpdateCommand } from './io';
import { BiPlus } from 'react-icons/bi';
import { fetchCommandDataDetail, deleteCommand } from '../commandList/io';
import { fetchData } from 'features/project/ProjectList/io';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import ApprovalPolicyOverride from 'components/ApprovalPolicyOverride';
import ExecutionPolicyOverride from 'components/ExecutionPolicyOverride';
import ApprovalWorkflow from 'components/ApprovalWorkflow';
import CommandArgument from 'components/CommandArgument';
import { nanoid } from 'nanoid';
import {
  createCommandArgumentObject,
  isInvalidArguments,
  validateArguments
} from './helpers/argumentHelper';
import { fetchCommandsData } from './io';
import { Flags } from 'contexts/FlagProvider';
import { fetchPolicies } from 'features/user/io';
import sendFetchTriggersRequest from 'network/sendFetchTriggersRequest';
import { useSelector } from 'react-redux';
import { userRoles } from 'constants/index';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, MinusIcon } from '@heroicons/react/20/solid';
import { useToast } from 'hooks/use-toast';
import { githubDark } from '@uiw/codemirror-theme-github';
import ReactCodeMirror from '@uiw/react-codemirror';
import { python } from "@codemirror/lang-python";

const initialFormModel = {
  name: '',
  private: false,
  kind: 'ssh',
  description: '',
  // connection: '',
  sshCommandInfo: {
    arguments: [
      // {
      //   name: '',
      //   required: true
      // }
    ],
    command: ''
  },
  projectNames: [],
  approvalGroup: '',
  unleashCommand: {},
  leashCommand: {}
};

const CommandNew = () => {
  const [viewState, setViewState] = useState(globalViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [showModal, setShowModal] = useState(false);
  const [submit, setIsSubmit] = useState(false);
  const [postMethodType, setPostMethodType] = useState();
  const [command, setCommand] = useState('');

  const [argumentObjects, setArgumentObjects] = useState([]);
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false,
    arguments: false,
    command: false
  });
  // eslint-disable-next-line no-unused-vars
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const params = useParams(); // get param
  const commandName = params.commandName ? params.commandName : null;
  const [commandList, setCommandList] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [selectedTriggers, setSelectedTriggers] = useState([]);
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();

  // Get Projects and policies, triggers
  useEffect(() => {
    fetchData().then(({ success, payload }) => {
      void success;

      const { projects } = payload;
      setProjects(projects);
    });
    fetchPolicies().then(({ success, payload }) => {
      void success;

      const { policies } = payload;

      setPolicies(policies);
    });
    sendFetchTriggersRequest().then(({ success, payload }) => {
      void success;

      const { triggers } = payload;

      setTriggers(triggers);
    });
  }, []);

  useEffect(() => {
    if (policies) {
      const selectedPolicy = policies.filter(
        (policy) => policy.commands && policy.commands.includes(commandName)
      );
      setSelectedPolicies(selectedPolicy);
    }
  }, [policies, commandName]);

  useEffect(() => {
    if (triggers) {
      const selectedTrigger = triggers.filter(
        (trigger) => trigger.commands && trigger.commands.includes(commandName)
      );
      setSelectedTriggers(selectedTrigger);
    }
  }, [triggers, commandName]);

  useLayoutEffect(() => {
    if (commandName) {
      fetchCommandDataDetail(commandName).then(
        ({ success, payload }) => {
          void success;

          const { command } = payload;
          const temporaryForm = {
            // name: command.name,
            kind: 'ssh',
            description: command.description,
            // connection: command.connection,
            private: command.private,
            sshCommandInfo: {
              arguments: createCommandArgumentObject(command.sshCommandInfo.arguments),
              command: command.sshCommandInfo.command
            },
            projectNames: command.projectNames,
            approvalGroup: command.approvalGroup,
            unleashCommand: command.unleashCommand,
            leashCommand: command.leashCommand
          };
          setSelectedProjects(
            command.projectNames
              ? command.projectNames.map((project) => {
                  return { value: project, label: project };
                })
              : []
          );
          setCommand(command.sshCommandInfo.command);
          setArgumentObjects(temporaryForm.sshCommandInfo.arguments);
          setFormModel(temporaryForm);
          setViewState(globalViewStates.DONE);
        },
        (err) => {
          console.log('error', err);
        }
      );
    }
  }, [commandName]);

  useEffect(() => {
    if (submit) {
      const obj = {
        name: postMethodType === postMethods.UPDATE ? false : formModel.name.trim() === '',
        // arguments: argControl.includes(true),
        command: formModel.sshCommandInfo.command.trim() === '',
        uniqueName:
          postMethodType === postMethods.UPDATE
            ? false
            : commandList.find(
                (command) => command.name.toLowerCase() === formModel.name.toLowerCase()
              )
            ? true
            : false
      };
      setFormErrorModel(obj);

      let showErrorToast = Object.values(obj).includes(true);

      if (argumentObjects !== undefined) {
        const validatedArgumentObjects = validateArguments(argumentObjects);
        setArgumentObjects([...validatedArgumentObjects]);
        showErrorToast = showErrorToast || isInvalidArguments(validatedArgumentObjects);
      }

      if (showErrorToast) {
        setIsSubmit(false);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'Please fill the required areas!'
        });
        return;
      } else {
        if (postMethodType === postMethods.SAVE) {
          onFormSave();
        } else if (postMethodType === postMethods.UPDATE) {
          onFormUpdate();
        }
        setIsSubmit(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModel]);

  useEffect(() => {
    fetchCommandsData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { commands } = payload;
        setCommandList(commands);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (!commandName) {
        if (
          commandList.find((command) => command.name.toLowerCase() === formModel.name.toLowerCase())
        ) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false
          });
        }
      }
    };
    checkIfNameExists();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModel.name, commandList]);

  const onFormSubmit = (type, e) => {
    e.preventDefault();
    setPostMethodType(type);
    setIsSubmit(true);

    const sshArguments =
      argumentObjects !== undefined
        ? argumentObjects.map((argument) => ({
            name: argument.name,
            default:
              typeof argument.default == 'boolean' ? argument.default.toString() : argument.default,
            shortName: argument.shortName,
            type: argument.type,
            required: argument.required
          }))
        : [];

    setFormModel({
      ...formModel,
      sshCommandInfo: {
        ...formModel.sshCommandInfo,
        arguments: [...sshArguments],
        command: command
      }
    });
  };

  //Change Input
  const onChangeInput = (value, field, e) => {
    if (field === 'name' || value === '') {
      const trimmedValue = value.trim();
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else if (field === 'private') {
      const { checked } = e.target;
      setFormModel({
        ...formModel,
        private: checked
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  };

  const onFormSave = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success, payload } = await commandExists(formModel.name);

      if (!success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There’s an existing secret with that name. Please choose a different name.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
      const { success: successAdd } = await addOrUpdateCommand({
        ...formModel
      });

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem saving your command. Please try again later.'
        });
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: 'Command successfully created'
        });
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem saving your command. Please try again later.'
      });
    }
  };

  const onFormUpdate = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateCommand(
        {
          ...formModel
        },
        commandName
      );

      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem updating your command. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: 'Command successfully updated'
        });
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem updating your command. Please try again later.'
      });
    }
  };

  // Add Argument
  const addArgument = (e) => {
    e.preventDefault();
    setArgumentObjects([
      ...argumentObjects,
      {
        id: nanoid(),
        name: '',
        shortName: '',
        required: false,
        type: '',
        default: '',
        invalidName: false,
        invalidDefault: false,
        invalidType: false,
        invalidMessage: '',
        duplicatedName: true
      }
    ]);
  };

  // Delete Argument
  const removeArgument = (removedArgumentId) => {
    const filteredArguments = argumentObjects.filter(
      (argument) => argument.id !== removedArgumentId
    );
    setArgumentObjects(filteredArguments);
  };

  const handleChangedArgument = (updatedArgument) => {
    const updatedArgumentObjectIndex = argumentObjects.findIndex(
      (argument) => argument.id === updatedArgument.id
    );

    const copyArgumentObjects = argumentObjects;
    copyArgumentObjects.splice(updatedArgumentObjectIndex, 1, updatedArgument);
    setArgumentObjects([...copyArgumentObjects]);
  };

  // clear form
  const clearForm = () => {
    setFormModel(initialFormModel);
  };

  const onProjeUpdate = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateCommand(formModel, commandName);

      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem updating your command. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem updating your command. Please try again later.'
      });
    }
  };

  //delete command
  const onDeleteClick = async (evt) => {
    evt.preventDefault();
    if (
      selectedTriggers.length !== 0 ||
      selectedPolicies.length !== 0 ||
      selectedProjects.length !== 0
    ) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: `This command is being used in ${
          selectedTriggers.length !== 0 || selectedProjects.length !== 0
            ? `${selectedTriggers.map((trigger) => `'${trigger.name}'`).join(', ')} ${
                selectedProjects &&
                selectedProjects.map((project) => `'${project.value}'`).join(', ')
              } triggers`
            : ''
        }
        ${
          selectedPolicies.length !== 0
            ? `${selectedPolicies.map((policy) => `'${policy.name}'`).join(', ')} policies`
            : ''
        } 
        `
      });
    } else {
      setShowModal(!showModal);
      formModel.projectNames = [];
    }
  };

  const confirmDelete = async () => {
    setShowModal(!showModal);
    await onProjeUpdate();
    deletionConfirmed();
  };

  const deletionConfirmed = (evt) => {
    if (!commandName) {
      return;
    }
    setViewState(globalViewStates.EXECUTING);
    deleteCommand(commandName).then(
      () => {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: `${commandName} was deleted`
        });
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.DONE);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
      }
    );
  };

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/commands" />;
  }

  const disabled = viewState === globalViewStates.POSTING;
  const btnExtraClassName = disabled ? 'opacity-50 cursor-not-allowed' : '';

  const handleChangeApprovalPolicyGroup = (selectedGroup) => {
    setFormModel({
      ...formModel,
      unleashCommand: {
        ...formModel.unleashCommand,
        group: selectedGroup
      }
    });
  };
  const handleChangeApprovalPolicyFlags = (selectedFlags) => {
    setFormModel({
      ...formModel,
      unleashCommand: {
        ...formModel.unleashCommand,
        list: [...selectedFlags.map((command) => command.value)]
      }
    });
  };
  const handleChangeExecutionPolicyGroup = (selectedGroup) => {
    setFormModel({
      ...formModel,
      leashCommand: {
        ...formModel.leashCommand,
        group: selectedGroup
      }
    });
  };
  const handleChangeExecutionPolicyFlags = (selectedFlags) => {
    setFormModel({
      ...formModel,
      leashCommand: {
        ...formModel.leashCommand,
        list: [...selectedFlags.map((command) => command.value)]
      }
    });
  };

  const handleCommandChange = (value) => {
    setCommand(value);
  };

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">
              {commandName ? 'UPDATE BASH COMMAND' : 'NEW BASH COMMAND'}
            </h2>
            <div className="mb-4 rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <BsFillInfoCircleFill className="h-5 w-5 text-blue-500" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm leading-5 text-blue-700">
                    For more information please checkout the
                    <a href=" https://docs.ob2.ai/opsbeacon/args-in-cmd-workflow" target="_blank">
                      <u> How to use arguments in commands and workflows</u>
                    </a>{' '}
                    page.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6">
              <div>
                <label htmlFor="name" className="label-main">
                  Name *
                </label>

                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  disabled={disabled || commandName}
                  readOnly={commandName}
                  maxLength="250"
                  placeholder="e.g., restart-cluster"
                  className={`${btnExtraClassName} ${
                    commandName ? 'input-main-disabled' : 'input-main'
                  } ${formErrorModel.uniqueName ? 'border-2 border-red-500 bg-red-500' : ''}`}
                  value={commandName ? commandName : formModel.name}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'name');
                  }}
                />

                <span className="label-second">
                  Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                </span>

                {formErrorModel.name && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}

                {formErrorModel.uniqueName && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="description" className="label-main">
                  Description
                </label>

                <textarea
                  rows={3}
                  name="description"
                  id="description"
                  placeholder="e.g., restarts the production cluster"
                  maxLength={125}
                  value={formModel.description}
                  disabled={disabled}
                  className={`${btnExtraClassName} input-main`}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'description');
                  }}
                />
              </div>
              <div className="w-full">
                <label htmlFor="command" className="label-main">
                  Command
                </label>
                <div className="flex">
                  <div className="relative w-full py-2.5">
                    <ReactCodeMirror
                      value={command}
                      extensions={[python()]}
                      onChange={handleCommandChange}
                      theme={githubDark}
                      options={{
                        lineNumbers: true,
                        mode: 'python'
                      }}
                      placeholder="Command line can be modified as needed. So, you can use either hardcoded or dynamic parameters."
                    />
                  </div>
                </div>
                {formErrorModel.command && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}
              </div>

              {/* Arguments */}
              <div className="sm:col-span-6">
                <label
                  htmlFor="command"
                  className="label-main mb-3 block border-b pb-1 dark:border-gray-700"
                >
                  Arguments
                </label>

                {argumentObjects?.length > 0 && (
                  <>
                    <div className="command-arguments !pb-0">
                      <div className="w-full">
                        <label className="label-main">Name:</label>
                      </div>
                      <div className="w-full">
                        <label className="label-main">Type:</label>
                      </div>
                      <div className="w-full">
                        <label className="label-main">Default Value:</label>
                      </div>
                      <div className="w-full">
                        <label className="label-main">Required:</label>
                      </div>
                    </div>
                    {argumentObjects?.map((argument) => (
                      <CommandArgument
                        key={argument.id}
                        argumentObject={argument}
                        onRemove={removeArgument}
                        onChange={handleChangedArgument}
                      />
                    ))}
                  </>
                )}
                <span className="label-second">
                  {`You can determine the dynamic parameters in this section. You must specify this
                  argument parameter as {{ argName }} in command line if you want to use. The value
                  will be replace with the user input via triggers. For example, in Slack trigger:`}
                  <br></br>
                  {`@obot connection command --myArgument 1234 the value of {{ myArgument }} in
                  command text will be set to 1234 at run time`}
                </span>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mt-1 flex items-center rounded-md border p-1.5 px-2 text-blue-500 hover:bg-blue-300 hover:text-blue-900 dark:border-gray-700"
                  onClick={addArgument}
                >
                  <BiPlus className="mr-1 !text-base" /> Add New
                </button>
              </div>

              <Disclosure as="div" className="border-b border-gray-200 py-6">
                {({ open }) => (
                  <>
                    <h3 className="-my-3 flow-root">
                      <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm">
                        <h2 className="text-xl font-extrabold">Advanced Settings</h2>
                        <span className="ml-6 flex items-center">
                          {open ? (
                            <MinusIcon className="h-5 w-5" aria-hidden="true" />
                          ) : (
                            <ChevronDownIcon
                              className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel className="pt-6">
                      <div className="flex flex-col gap-y-4 py-4">
                        <div className="rounded-md border p-4 dark:border-gray-700">
                          <div className="flex items-center">
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="private"
                                name="private"
                                checked={formModel.private}
                                onChange={(e) => {
                                  onChangeInput(!formModel.private, 'private');
                                }}
                              />
                              <span className="slider round"></span>
                            </label>

                            <label
                              htmlFor="private"
                              className="label-main ml-2 mt-1 cursor-pointer "
                            >
                              Enable Direct Message Output
                            </label>
                          </div>

                          <label className="label-second mt-2 block">
                            Enable this setting to send the output of this command directly to the
                            person who triggered it.
                          </label>
                        </div>

                        {/* Approval Workflow */}
                        <Flags authorizedFlags={[features.APPROVAL_POLICY]}>
                          <ApprovalWorkflow
                            defaultGroup={{
                              value: formModel.approvalGroup,
                              label: formModel.approvalGroup
                            }}
                            onChange={(approvalGroup) => {
                              setFormModel({
                                ...formModel,
                                approvalGroup: approvalGroup
                              });
                            }}
                          />
                        </Flags>
                      </div>
                      <div className="space-y-4">
                        {/* Approval Policy Override */}
                        <ApprovalPolicyOverride
                          defaultGroup={
                            formModel.unleashCommand ? formModel.unleashCommand.group : ''
                          } // for difference between old command object type
                          defaultFlags={formModel.unleashCommand.list} // for difference between old command object type
                          onChangeApproverGroup={handleChangeApprovalPolicyGroup}
                          onChangeFlags={handleChangeApprovalPolicyFlags}
                        />

                        {/* Execution Policy Override */}
                        <ExecutionPolicyOverride
                          defaultGroup={formModel.leashCommand ? formModel.leashCommand.group : ''} // for difference between old command object type
                          defaultFlags={formModel.leashCommand.list} // for difference between 'old command object' type
                          onChangeApproverGroup={handleChangeExecutionPolicyGroup}
                          onChangeFlags={handleChangeExecutionPolicyFlags}
                        />
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              {userState.userRole.role !== userRoles.READ_ONLY && commandName && (
                <>
                  <h2 className="text-xl font-extrabold">Danger Zone</h2>

                  <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                    <p className="label-second">
                      By clicking the button below you delete this command. This action is{' '}
                      <strong>irreversible</strong>.
                    </p>
                    <p className="label-second mb-4">
                      You can still re-use this command's features in other commands.
                    </p>
                    <button
                      disabled={disabled}
                      type="button"
                      onClick={onDeleteClick}
                      className={`${btnExtraClassName} text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                    >
                      I understand the consequences. Delete this command
                    </button>
                  </div>
                </>
              )}

              {userState.userRole.role !== userRoles.READ_ONLY && (
                <div className="mt-8 flex justify-center">
                  <button type="button" className="btn-danger" onClick={clearForm}>
                    Clear
                  </button>

                  {commandName ? (
                    <button
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit('UPDATE', e)}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit('SAVE', e)}
                    >
                      Save
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this command?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CommandNew;
