import { environmentVarTabOptions } from 'assets/allData';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEnvironments } from './network';
import ListTabs from 'components/listTabs/ListTabs';
import ListView from './ListView';

export const EnvironmentVariables = () => {
  const navigate = useNavigate();
  const [environmentList, setEnvironmentList] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [selectedKinds, setSelectedKinds] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  const handleSelectedList = (selectedFilters) => {
    setSelectedKinds(selectedFilters);
  };

  useEffect(() => {
    getEnvironments().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { environments } = payload;
        setEnvironmentList(environments);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);
  return (
    <>
      <ListTabs
        filtersKind={environmentVarTabOptions}
        onSelectedFilterKind={handleSelectedList}
        handleSearch={handleSearch}
        handleAddClick={() => {
          navigate('../settings/new/environmentVariable');
        }}
      />
      <ListView list={environmentList} rowClick={() => {}} />
    </>
  );
};
