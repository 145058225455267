import { fetchConnectionData } from 'features/connection/connectionList/io';
import { fetchCommands } from 'features/project/ProjectNew/io';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export default function RunCommand({ stepObject, onChange }) {
  const createSelectOption = (value, label = value) => ({ value: value, label: label });
  const [connections, setConnections] = useState([]);
  const [selectedConnections, setSelectedConnections] = useState(createSelectOption(''));
  const [selectedConnectionKind, setSelectedConnectionKind] = useState(null);
  const [commands, setCommands] = useState([]);
  const [selectedCommands, setSelectedCommands] = useState(createSelectOption(''));
  const [argument, setArgument] = useState('');

  const [updatedConnection, setUpdatedConnection] = useState(false);

  useEffect(() => {
    const initialSelectedConnection = createSelectOption(stepObject.data.runCommandStep.connection);
    setSelectedConnections(initialSelectedConnection);

    const initialSelectedCommand = createSelectOption(stepObject.data.runCommandStep.command);
    setSelectedCommands(initialSelectedCommand);

    setArgument(stepObject.data.runCommandStep.argument);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (connections.length !== 0) {
      const selectedConnection = connections.find(
        (connection) => connection.name === selectedConnections.value
      );
      setSelectedConnectionKind(selectedConnection ? selectedConnection.kind : null);
    }
  }, [selectedConnections, connections]);

  useEffect(() => {
    fetchConnectionData().then(
      ({ success, payload }) => {
        void success;

        const { connections } = payload;
        setConnections(connections);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  const connectionOptions = connections.map((connection) => ({
    label: connection.name,
    value: connection.name
  }));

  const commandOptions = commands.map((command) => ({
    label: command.name,
    value: command.name
  }));

  const handleChangeConnection = (e) => {
    setUpdatedConnection(true);
    setSelectedConnections(e);
    onChange({
      ...stepObject,
      data: {
        ...stepObject.data,
        runCommandStep: { ...stepObject.data.runCommandStep, connection: e.value }
      }
    });
  };

  const handleChangeCommand = (e) => {
    setSelectedCommands(e);
    onChange({
      ...stepObject,
      data: {
        ...stepObject.data,
        runCommandStep: { ...stepObject.data.runCommandStep, command: e.value }
      }
    });
  };

  const handleChangeArgument = (e) => {
    setArgument(e.target.value);
    onChange({
      ...stepObject,
      data: {
        ...stepObject.data,
        runCommandStep: { ...stepObject.data.runCommandStep, argument: e.target.value }
      }
    });
  };

  useEffect(() => {
    if (!selectedConnectionKind) {
      return;
    }
    if (updatedConnection) {
      setSelectedCommands(createSelectOption(''));
      setUpdatedConnection(false);
    }

    fetchCommands().then(
      ({ success, payload: { commands } }) => {
        if (!success) {
          alert('There was a problem fetching the command list. Please try again later.');
          return;
        }
        const filteredCommands = commands.filter(
          (command) =>
            command.kind === (selectedConnectionKind === 'agent' ? 'ssh' : selectedConnectionKind)
        );
        setCommands(filteredCommands);
      },
      (err) => {
        console.log(err);
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnectionKind]);

  return (
    <div className="flex flex-col items-center justify-center px-4">
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-1 items-center">{`id: ${stepObject.id}`}</div>
        <div className="my-4 flex shrink-0 rounded-lg border border-blue-600 p-2 text-xs text-secondary">
          {stepObject.data.workflowStepType}
        </div>
        <div className="flex-1"> </div>
      </div>
      <div className="flex w-full items-center gap-4 px-4">
        <div className="relative w-full">
          <label className="label-main absolute -top-4 !text-[12px]">Connection</label>
          <Select
            name="connections"
            className="my-2 w-full"
            placeholder="connections"
            options={connectionOptions}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            classNames={{
              control: (state) =>
                state.isFocused ? 'multi-select-input-focus' : 'multi-select-input',
              menuList: () => 'multi-select-dropdown',
              option: () => 'multi-select-option',
              input: () => 'multi-select-input-text',
              singleValue: () => 'dark:!text-gray-200'
            }}
            value={selectedConnections.value !== '' && selectedConnections}
            onChange={handleChangeConnection}
          />
        </div>
        <div className="relative w-full">
          <label className="label-main absolute -top-4 !text-[12px]">Command</label>
          <Select
            name="commands"
            className="my-2 w-full"
            placeholder="commands"
            options={commandOptions}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            classNames={{
              control: (state) =>
                state.isFocused ? 'multi-select-input-focus' : 'multi-select-input',
              menuList: () => 'multi-select-dropdown',
              option: () => 'multi-select-option overflow-hidden',
              input: () => 'multi-select-input-text',
              singleValue: () => 'dark:!text-gray-200'
            }}
            value={selectedCommands.value !== '' && selectedCommands}
            onChange={handleChangeCommand}
          />
        </div>
        <div className="relative w-full">
          <label className="label-main absolute -top-4 !text-[12px]">Argument</label>
          <input
            name="argument"
            type="text"
            className="input-main"
            placeholder="argument"
            value={argument}
            onChange={handleChangeArgument}
          />
        </div>
      </div>
    </div>
  );
}
