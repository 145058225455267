import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useToast } from "hooks/use-toast";
import { getApps, postApps } from "../network";
import { useNavigate } from "react-router-dom";
import ReactCodeMirror from "@uiw/react-codemirror";
import { githubDark } from "@uiw/codemirror-theme-github";
import { json } from '@codemirror/lang-json';

export const AppNew = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, defaultValues },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      features: {},
    },
  });

  const [uniqueNameError, setUniqueNameError] = useState(false);
  const [appList, setAppList] = useState([]);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    getApps().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { internalApps } = payload;
        setAppList(internalApps);
      },
      (err) => {
        console.log("error", err);
      }
    );
  }, []);

  const checkIfNameExists = (name) => {
    if (name !== "") {
      if (
        appList.find((app) => app.name.toLowerCase() === name.toLowerCase())
      ) {
        setUniqueNameError(true);
      } else {
        setUniqueNameError(false);
      }
    }
  };

  return (
    <div className="form-container">
      <div className="hero-section">
        <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
          <h2 className="mb-4 mt-0 text-center text-base font-bold">NEW APP</h2>

          <div className="flex flex-col gap-6 ">
            <form
              onSubmit={handleSubmit(
                async (data) => {
                  if (!uniqueNameError) {
                    const { success } = await postApps({ formModel: data });
                    if (!success) {
                      toast({
                        variant: "destructive",
                        title: "Uh oh! Something went wrong.",
                        description: "There was a problem. Try again later.",
                      });
                      return;
                    } else {
                      toast({
                        title: "Successfully",
                        description: "New app added",
                      });
                      navigate("/apps");
                    }
                  }
                },
                (error) => {
                  console.log(error);
                }
              )}
            >
              <div>
                <label htmlFor="name" className="label-main">
                  Name
                </label>

                <input
                  {...register("name", {
                    required: "This area is required!",
                    onChange: (e) => {
                      checkIfNameExists(e.target.value);
                    },
                  })}
                  className={`input-main`}
                />

                {errors.name !== undefined &&
                  errors.name.type === "required" && (
                    <span className="label-second my-1 block !text-red-500">
                      {errors.name.message}
                    </span>
                  )}

                {uniqueNameError && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${defaultValues.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="description" className="label-main">
                  Description
                </label>
                <textarea
                  rows="3"
                  className="input-main"
                  {...register("description")}
                />
              </div>
              <div>
                <ReactCodeMirror
                  height="50vh"
                  value={(JSON.stringify(defaultValues.features))}
                  extensions={[json()]}
                  onChange={(value, e) => {
                    setValue('features', JSON.parse(value));
                  }}
                  theme={githubDark}
                  options={{
                    lineNumbers: true,
                    mode: "json",
                  }}
                  placeholder="Command line can be modified as needed. So, you can use either hardcoded or dynamic parameters."
                />
              </div>

              <div className="flex justify-center">
                <button
                  type="button"
                  className="btn-danger"
                  onClick={() => {
                    reset();
                  }}
                >
                  Clear
                </button>

                <button type="submit" className="btn-success">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
