import { useEffect, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { postCommand } from "../network";
import { useToast } from "hooks/use-toast";
import Loading from "components/loading/Loading";
import { DataTable } from "components/data-table";
import { TableRow } from "./TableRow";

export const Table = ({ table, refreshTenant, appId, title }) => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const toast = useToast();

  const rowsPerPage = 25;
  const dataSource = get(table, "dataSource", "");

  const fetchData = () => {
    setLoading(true);
    if (!isEmpty(dataSource)) {
      postCommand({
        appId,
        command: dataSource,
        page: currentPage,
        limit: rowsPerPage,
      })
        .then(({ success, payload }) => {
          setLoading(false);
          if (success) {
            setData(payload.response || []);
            setTotalPages(Math.ceil(payload.response.length / rowsPerPage));
          } else {
            setError("Failed to load tenants. Please try again.");
            toast({
              variant: "destructive",
              title: "Uh oh! Something went wrong.",
              description: `${payload.response}`,
            });
          }
        })
        .catch(() => {
          setLoading(false);
          setError("An error occurred while fetching data.");
        });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, refreshTenant]);

  const columns = get(table, "headers", []).map((header) => ({
    header: header,
    accessorKey: header,
  }));

  const rowActions = get(table, "rowActions", []);
  if (rowActions.length > 0) {
    columns.push({
      header: "Actions",
      accessorKey: "actions",
      cell: (info) => (
        <TableRow
          rowData={info.row.original}
          appId={appId}
          tableRowActions={rowActions}
        />
      ),
    });
  }
  return (
    <div className="w-full">
      {loading ? (
        <Loading />
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          <DataTable columns={columns} data={data} />
          <div className="mt-3 flex items-end justify-between">
            <div className="flex-1 text-sm text-[#71717a]">
              Total {data.length} item
            </div>
            <nav>
              <ul className="flex h-8 items-center -space-x-px text-sm">
                <li>
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className="inline-flex items-center justify-center gap-2 whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs"
                  >
                    <span className="sr-only">Previous</span>
                    <svg
                      className="h-2 w-2 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 1 1 5l4 4"
                      />
                    </svg>
                  </button>
                </li>
                {Array.from(
                  { length: totalPages },
                  (_, index) => index + 1
                ).map((page) => (
                  <li key={page}>
                    <button
                      onClick={() => setCurrentPage(page)}
                      className={`inline-flex items-center justify-center gap-2 whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs ${
                        page === currentPage
                          ? "bg-secondaries_blue_600/100 text-gray-300"
                          : "bg-white text-gray-500"
                      }`}
                    >
                      {page}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                    className="inline-flex items-center justify-center gap-2 whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs"
                  >
                    <span className="sr-only">Next</span>
                    <svg
                      className="h-2 w-2 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </>
      )}
    </div>
  );
};
