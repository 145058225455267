import { postMethods } from 'constants';
import { globalViewStates } from 'constants';
import { addNewPolicyViewStates } from 'constants';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { addOrUpdatePolicy, fetchConnections, fetchCommands } from './io';
import { deletePolicy, fetchPolicyDataDetail } from '../PolicyList/io';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import { fetchPolicyData } from '../PolicyList/io';
import { fetchWorkflowsData } from 'features/workflow/WorkflowNew/io';
import MultiSelectDataTable from 'components/multiSelectDataTable';
import { generateSelectedEntities } from 'utils/entityUtils';
import { useToast } from 'hooks/use-toast';

const initialFormModel = {
  name: '',
  description: '',
  commands: [],
  connections: [],
  workflows: []
};

const PoliciesNew = () => {
  const [viewState, setViewState] = useState(addNewPolicyViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [commands, setCommands] = useState([]);
  const [selectedCommands, setSelectedCommands] = useState([]);
  const [connections, setConnections] = useState([]);
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflows, setSelectedWorkflows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false
  });
  const [policyList, setPolicyList] = useState([]);
  const { toast } = useToast();

  // Get connections, commands, workflows
  useEffect(() => {
    fetchConnections().then(({ success, payload }) => {
      void success;
      const { connections } = payload;
      setConnections(
        connections.map((connection) => ({
          value: connection.name,
          label: connection.name,
          kind: connection.kind,
          description: connection.description
        }))
      );
    });
  }, []);

  useEffect(() => {
    fetchCommands().then(({ success, payload }) => {
      void success;
      const { commands } = payload;
      setCommands(
        commands.map((command) => ({
          value: command.name,
          label: command.name,
          kind: command.kind,
          description: command.description
        }))
      );
    });
  }, []);

  useEffect(() => {
    fetchWorkflowsData().then(({ success, payload }) => {
      void success;
      const { workflows } = payload;
      setWorkflows(
        workflows.map((workflow) => ({
          value: workflow.name,
          label: workflow.name,
          description: workflow.description
        }))
      );
    });
  }, []);

  const onFormSubmit = (type, e) => {
    e.preventDefault();

    const obj = {
      name: type === postMethods.UPDATE ? false : formModel.name.trim() === '',
      uniqueName:
        type === postMethods.UPDATE
          ? false
          : policyList.find((policy) => policy.name.toLowerCase() === formModel.name.toLowerCase())
          ? true
          : false
    };

    setFormErrorModel(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    }
    setFormModel({
      ...formModel,
      connections: selectedConnections.map((connection) => {
        return connection.value;
      }),
      commands: selectedCommands.map((command) => {
        return command.value;
      }),
      workflows: selectedWorkflows.map((workflow) => {
        return workflow.value;
      })
    });

    if (type === postMethods.SAVE) {
      onFormSave();
    } else if (type === postMethods.UPDATE) {
      onFormUpdate();
    }
  };

  //Change Input
  const onChangeInput = (value, field) => {
    const trimmedValue = value.trim();
    if (field === 'name' && (trimmedValue !== '' || value === '')) {
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  };

  const params = useParams(); // get param
  const policyName = params.policyName ? params.policyName : null;

  useEffect(() => {
    if (policyName) {
      fetchPolicyDataDetail(policyName).then(
        ({ success, payload }) => {
          void success;

          const { policies } = payload;

          const temporaryForm = {
            name: policies.name,
            description: policies.description,
            commands: policies.commands,
            connections: policies.connections,
            workflows: policies.workflows
          };
          setFormModel(temporaryForm);
          setSelectedCommands(generateSelectedEntities(policies, 'commands', commands));
          setSelectedConnections(generateSelectedEntities(policies, 'connections', connections));
          setSelectedWorkflows(generateSelectedEntities(policies, 'workflows', workflows));
          setViewState(addNewPolicyViewStates.DONE);
        },
        (err) => {
          console.log('error', err);
        }
      );
    }
  }, [policyName, connections, commands, workflows]);

  useEffect(() => {
    fetchPolicyData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { policies } = payload;
        setPolicyList(policies);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    if (!policyName) {
      const checkIfNameExists = () => {
        if (
          policyList.find((policy) => policy.name.toLowerCase() === formModel.name.toLowerCase())
        ) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false
          });
        }
      };
      checkIfNameExists();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModel.name, policyList]);

  const onFormSave = async (evt) => {
    
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successAdd } = await addOrUpdatePolicy({
        ...formModel,
        connections: selectedConnections.map((connection) => {
          return connection.value;
        }),
        commands: selectedCommands.map((command) => {
          return command.value;
        }),
        workflows: selectedWorkflows.map((workflow) => {
          return workflow.value;
        })
      });

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem saving your policy. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
      }

      toast({
        title: 'Successfully',
        description: 'Policy saved.'
      });
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem saving your policy. Please try again later.'
      });
    }
  };

  const onFormUpdate = async (isDelete = false) => {
    

    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdatePolicy(
        {
          ...formModel,
          connections: selectedConnections.map((connection) => {
            return connection.value;
          }),
          commands: selectedCommands.map((command) => {
            return command.value;
          }),
          workflows: selectedWorkflows.map((workflow) => {
            return workflow.value;
          })
        },
        policyName
      );
      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem updating your policy. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        if (!isDelete) {
          toast({
            title: 'Successfully',
            description: 'Policy updated.'
          });
        }
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem updating your policy. Please try again later.'
      });
    }
  };

  const onBackClick = (evt) => {
    evt.preventDefault();
    setViewState(globalViewStates.REDIRECT_TO_PARENT);
  };

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/settings/execution-policies" />;
  }

  const disabled = viewState === globalViewStates.POSTING;

  //delete policy
  const onDeleteClick = async (evt) => {
    evt.preventDefault();
    setShowModal(!showModal);
  };

  const confirmDelete = async () => {
    setShowModal(!showModal);
    await onFormUpdate(true); // delete process
    deletionConfirmed();
  };

  const deletionConfirmed = (evt) => {
    if (!policyName) {
      return;
    }
    setViewState(globalViewStates.EXECUTING);
    deletePolicy(policyName).then(
      () => {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);

        toast({
          title: 'Successfully',
          description:`${policyName} was deleted.`
        });
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.DONE);
        toast({
          title: 'Successfully',
          description:'There was a problem. Please try again!'
        });
      }
    );
  };

  const btnExtraClassName = disabled ? 'opacity-50 cursor-not-allowed' : '';

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="overflow-hidden bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">
              {policyName ? 'UPDATE POLICY' : 'NEW POLICY'}
            </h2>

            <div className="flex flex-col gap-6"></div>
            <div>
              <label htmlFor="name" className="label-main">
                Policy Name *
              </label>

              <input
                name="name"
                id="name"
                disabled={disabled || policyName}
                readOnly={policyName}
                placeholder=""
                maxLength="250"
                required
                className={`${policyName ? 'input-main-disabled' : 'input-main'} ${
                  formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                }`}
                value={policyName ? policyName : formModel.name}
                onChange={(e) => {
                  onChangeInput(e.target.value, 'name');
                }}
              />

              <span className="label-main">
                Choose a <strong>single word</strong> with <strong>no spaces</strong>.
              </span>

              {formErrorModel.name && (
                <span className="label-second my-1 block !text-red-500">
                  This area is required!
                </span>
              )}

              {formErrorModel.uniqueName && (
                <span className="label-second my-1 block !text-red-500">
                  {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                </span>
              )}
            </div>
            <div className="mt-8">
              <label htmlFor="description" className="label-main">
                Description
              </label>

              <input
                id="description"
                name="description"
                disabled={disabled}
                required
                placeholder=""
                maxLength={125}
                className={`input-main`}
                value={formModel.description}
                onChange={(e) => {
                  onChangeInput(e.target.value, 'description');
                }}
              />
            </div>
            {/* Commands */}
            <MultiSelectDataTable
              title={'Commands'}
              tableData={commands}
              selectedTableData={selectedCommands}
              setSelectedTableDataCallback={setSelectedCommands}
            />
            {/* Connections */}
            <MultiSelectDataTable
              title={'Connections'}
              tableData={connections}
              selectedTableData={selectedConnections}
              setSelectedTableDataCallback={setSelectedConnections}
            />
            {/* Workflows */}
            <MultiSelectDataTable
              title={'Workflows'}
              tableData={workflows}
              selectedTableData={selectedWorkflows}
              setSelectedTableDataCallback={setSelectedWorkflows}
            />

            {policyName && (
              <>
                <h2 className="py-6 text-xl font-extrabold">Danger Zone</h2>

                <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                  <p className="label-second mb-4">
                    By clicking the button below you delete this policy. This action is{' '}
                    <strong>irreversible</strong>.
                  </p>
                  <button
                    disabled={disabled}
                    type="button"
                    onClick={onDeleteClick}
                    className={`${btnExtraClassName} text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                  >
                    I understand the consequences. Delete this policy
                  </button>
                </div>
              </>
            )}

            <div className="mt-5 flex justify-center">
              <button type="button" className="btn-danger" onClick={onBackClick}>
                Cancel
              </button>
              {policyName ? (
                <button
                  type="button"
                  className="btn-success"
                  onClick={(e) => onFormSubmit('UPDATE', e)}
                >
                  Update
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-success"
                  onClick={(e) => onFormSubmit('SAVE', e)}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this policy?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PoliciesNew;
