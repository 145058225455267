import { useEffect, useState } from 'react';
import LogFilter from './LogFilter';
import { useAuditLogListFetch } from 'hooks/useAuditLogListFetch';
import LogList from './LogList';
import LogSearch from './LogSearch';

export default function Index() {
  // eslint-disable-next-line no-unused-vars
  const [startDate, setStartDate] = useState(getInitialDate());
  // eslint-disable-next-line no-unused-vars
  const [endDate, setEndDate] = useState(getFormattedDate());
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [dataCount, setDataCount] = useState();
  const [selectedLogKind, setSelectedLogKind] = useState([]);
  const [searchedLogs, setSearchedLogs] = useState('');
  const [selectedDates, setSelectedDates] = useState('');

  const handleFilterChange = ({ selectedLogKind }) => {
    setSelectedLogKind(selectedLogKind);
  };

  const handleSearch = (searchedLogs) => {
    setSearchedLogs(searchedLogs);
  };

  const handleDateChange = (newDates) => {
    setSelectedDates(newDates);
  };

  function getFormattedDate(date = new Date()) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}${month}${day}`;
  }

  function getInitialDate(date = new Date()) {
    const oneWeekAgoDate = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
    const year = oneWeekAgoDate.getFullYear();
    const month = oneWeekAgoDate.getMonth() + 1;
    const day = oneWeekAgoDate.getDate();
    const formattedDate = year + (month < 10 ? '0' : '') + month + (day < 10 ? '0' : '') + day;
    return formattedDate;
  }

  const { data, loading } = useAuditLogListFetch({
    initialStartDate: startDate,
    initialEndDate: endDate,
    initialPage: 1,
    initialLimit: 10,
    initialOrderBy: 'timestamp',
    initialDirection: 'desc'
  });

  useEffect(() => {
    if (data !== null) {
      setTableData(data.logs);
      setDataCount(data.dataCount);
      setTotalPages(Math.ceil(data.dataCount / 10));
    }
  }, [data]);
  
  return (
    <section className="block items-start gap-6 md:flex">
      <div className="w-full pt-2 md:w-1/4">
        <LogSearch onSearchChange={handleSearch} loading={loading} />
        <LogFilter
          onSelectChange={handleFilterChange}
          loading={loading}
          handleDateChange={handleDateChange}
        />
      </div>
      <LogList
        tableData={tableData}
        searchedLogs={searchedLogs}
        startDate={selectedDates ? selectedDates.selectedStartDate : startDate}
        endDate={selectedDates ? selectedDates.selectedEndDate : endDate}
        totalPages={totalPages}
        dataCount={dataCount}
        selectedLogKind={selectedLogKind}
        loading={loading}
      />
    </section>
  );
}
