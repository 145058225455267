import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { addNewProjectViewStates } from 'constants/index';
import { fetchCommands, saveNewProject } from './io';
import { fetchData } from 'features/project/ProjectList/io';
import { useToast } from 'hooks/use-toast';

const ProjectNew = () => {
  const [viewState, setViewState] = useState(addNewProjectViewStates.FETCHING_COMMANDS);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const [gptEnable, setGptEnable] = useState(false);
  const [gptPrompt, setGptPrompt] = useState('');
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false
  });
  const [projectList, setProjectList] = useState([]);
  const { toast } = useToast();

  useEffect(() => {
    setViewState(addNewProjectViewStates.FETCHING_COMMANDS);

    fetchCommands().then(
      ({ success }) => {
        if (!success) {
          alert('There was a problem fetching the command list. Please try again later.');
          setViewState(addNewProjectViewStates.ERROR);
          return;
        }

        setViewState(addNewProjectViewStates.IDLE);
      },
      (err) => {
        console.log(err);
        setViewState(addNewProjectViewStates.IDLE);
      }
    );
  }, []);

  useEffect(() => {
    fetchData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { projects } = payload;
        setProjectList(projects);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (projectList.find((project) => project.name.toLowerCase() === name.toLowerCase())) {
        setFormErrorModel({
          ...formErrorModel,
          uniqueName: true
        });
      } else {
        setFormErrorModel({
          ...formErrorModel,
          uniqueName: false
        });
      }
    };
    checkIfNameExists();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, projectList]);

  const onNameChange = (evt) => {
    const trimmedValue = evt.target.value.trim();
    setName(trimmedValue);
  };

  const onDescriptionChange = (evt) => {
    setDescription(evt.target.value);
  };

  const onPromptChange = (evt) => {
    setGptPrompt(evt.target.value);
  };

  const handleGptChange = (event) => {
    const { checked } = event.target;
    setGptEnable(checked);
  };

  const onBackClick = (evt) => {
    evt.preventDefault();
    setViewState(addNewProjectViewStates.REDIRECT_TO_PARENT);
  };

  const onFormSubmit = (evt) => {
    evt.preventDefault();

    const obj = {
      name: name.trim() === '',
      uniqueName: projectList.find((project) => project.name.toLowerCase() === name.toLowerCase())
        ? true
        : false
    };
    setFormErrorModel(obj);
    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    }

    saveNewProject({
      name,
      description,
      gptEnable,
      gptPrompt
    }).then(
      () => {
        setTimeout(() => {
          toast({
            title: 'Successfully',
            description: 'Added new project'
          });
          setViewState(addNewProjectViewStates.REDIRECT_TO_PARENT);
        }, 300);
      },
      (err) => {
        console.log(err);
        setViewState(addNewProjectViewStates.REDIRECT_TO_PARENT);
      }
    );
  };

  if (viewState === addNewProjectViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/projects" />;
  }

  const disabled = viewState !== addNewProjectViewStates.IDLE;
  const btnExtraClassName = disabled ? 'opacity-50 cursor-not-allowed' : '';

  return (
    <div className="form-container">
      <div className="hero-section">
        <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
          <form action="/projects/new" onSubmit={onFormSubmit}>
            <h2 className="mb-4 mt-0 text-center text-base font-bold">NEW PROJECT</h2>

            <div className="flex flex-col gap-6">
              <div>
                <label htmlFor="name" className="label-main">
                  Project Name
                </label>
                <input
                  id="name"
                  value={name}
                  maxLength="150"
                  onChange={onNameChange}
                  disabled={disabled}
                  placeholder="e.g., Hogwarts Staging Operations"
                  className={`${btnExtraClassName} input-main ${
                    formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                  }`}
                />
                <span className="label-second">
                  This is a <strong>unique name</strong> to describe what your Ops team is working
                  on.
                </span>

                {formErrorModel.name && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}

                {formErrorModel.uniqueName && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="description" className="label-main">
                  Project Description
                </label>
                <input
                  id="description"
                  value={description}
                  disabled={disabled}
                  onChange={onDescriptionChange}
                  maxLength={125}
                  placeholder="e.g., Staging operations for the Hogwarts School of Witchcraft and Wizardry CRM portal."
                  className={`${btnExtraClassName} input-main`}
                />
              </div>
            </div>
            <div className="mt-4 block">
              <div className="mb-4 flex items-center gap-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    id="enableGpt"
                    name="enableGpt"
                    checked={gptEnable}
                    onChange={handleGptChange}
                  />
                  <span className="slider round"></span>
                </label>
                <label htmlFor="enableGpt" className="label-main">
                  Enable GPT
                </label>
              </div>
              <div className={gptEnable ? 'block' : 'hidden'}>
                <textarea
                  id="gptPrompt"
                  name="gptPrompt"
                  value={gptPrompt}
                  onChange={onPromptChange}
                  className="prompt"
                  rows="8"
                  cols="100%"
                  placeholder="Can you list my ec2 instances?"
                ></textarea>
              </div>
            </div>

            <div className="flex justify-end">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  disabled={disabled}
                  onClick={onBackClick}
                  className={`${btnExtraClassName} cancel-button`}
                >
                  Cancel
                </button>
              </span>
              <span className="ml-3 inline-flex rounded-md shadow-sm">
                <button
                  disabled={disabled}
                  type="submit"
                  className={`${btnExtraClassName} save-button`}
                >
                  Save
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProjectNew;
