import React, { useState, useEffect } from 'react';
import { editProjectViewStates } from '../../../constants';
import { Navigate } from 'react-router-dom';
import { updateProject, deleteProject } from './io';
import Loading from 'components/loading/Loading';
import Select from 'react-select';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import ApprovalPolicyOverride from 'components/ApprovalPolicyOverride';
import ExecutionPolicyOverride from 'components/ExecutionPolicyOverride';
import { fetchData } from 'features/project/ProjectList/io';
import { useSelector } from 'react-redux';
import { userRoles } from 'constants/index';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, MinusIcon } from '@heroicons/react/20/solid';
import MultiSelectDataTable from 'components/multiSelectDataTable';
import { generateSelectedEntities } from 'utils/entityUtils';
import { useToast } from 'hooks/use-toast';

const ProjectEdit = ({ payload }) => {
  const [viewState, setViewState] = useState(editProjectViewStates.FETCHING);
  const [name, setName] = useState('');
  const [commands, setCommands] = useState([]);
  const [selectedCommands, setSelectedCommands] = useState([]);
  const [connections, setConnections] = useState([]);
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflows, setSelectedWorkflows] = useState([]);
  const [slackTeam, setSlackTeam] = useState('');
  const [slackChannels, setSlackChannels] = useState([]);
  const [selectedSlackChannelId, setSelectedSlackChannelId] = useState('');
  const [selectedSlackChannelName, setSelectedSlackChannelName] = useState('');
  const [description, setDescription] = useState('');
  const [slackChannelOptions, setSlackChannelOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [gptEnable, setGptEnable] = useState(false);
  const [gptPrompt, setGptPrompt] = useState('');
  const [unleashCommand, setUnleashCommand] = useState({ list: [], group: '' });
  const [leashCommand, setLeashCommand] = useState({ list: [], group: '' });
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false
  });
  const [projectList, setProjectList] = useState([]);

  const [payloadObject, setPayloadObject] = useState({});

  const [filteredProjectList, setFilteredProjectList] = useState([]);
  const [project, setProject] = useState({});
  const [projectSlackChannel, setProjectSlackChannel] = useState({});
  const [projectId, setProjectId] = useState('');
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();

  useEffect(() => {
    setPayloadObject(payload);
  }, [payload]);

  useEffect(() => {
    const workspaceCommands = payloadObject.commands;
    const workspaceConnections = payloadObject.connections;
    const workspaceWorkflows = payloadObject.workflows;
    const workspaceSlackChannels = payloadObject.slackChannels;

    const projectId = payloadObject.project ? payloadObject.project.id : '';
    const projectName = payloadObject.project?.name;
    const projectDescription = payloadObject.project?.description;
    const projectEnableGpt = payloadObject.project?.gptEnable;
    const projectPrompt = payloadObject.project?.gptPrompt;
    const projectSlackIntegration = payloadObject.project?.slackIntegration;
    const projectSlackChannel = payloadObject.project?.slackChannel;
    const projectConnections = payloadObject.project?.connections;
    const projectUnLeashCommand = payloadObject.project?.unleashCommand;
    const projectLeashCommand = payloadObject.project?.leashCommand;
    const projectWorkflows = payloadObject.project?.workflows;

    if (!projectId) {
      return;
    }

    setFilteredProjectList(projectList.filter((project) => project.name !== projectName));
    setProject(payloadObject.project);
    setProjectSlackChannel(projectSlackChannel);
    setProjectId(projectId);

    setName(projectName);
    setCommands(
      workspaceCommands.map((command) => {
        return {
          value: command.name,
          label: command.name,
          kind: command.kind,
          description: command.description
        };
      }) || []
    );
    setSelectedCommands(generateSelectedEntities(payloadObject.project, 'commands', commands));
    setSelectedSlackChannelId(projectSlackChannel ? projectSlackChannel.id : '');
    setSelectedSlackChannelName(projectSlackChannel ? projectSlackChannel.name : '');
    setDescription(projectDescription);
    setGptPrompt(projectPrompt);
    setGptEnable(projectEnableGpt);
    setSlackTeam(projectSlackIntegration ? projectSlackIntegration.teamName : '');
    setSlackChannels(workspaceSlackChannels);
    setConnections(
      workspaceConnections.map((connection) => {
        return {
          value: connection.name,
          label: connection.name,
          kind: connection.kind,
          description: connection.description
        };
      }) || []
    );
    setSelectedConnections(
      projectConnections && connections
        ? projectConnections.map((projectConnection) => {
            const matchedConnection = connections.find(
              (connection) => connection.value === projectConnection.name
            );
            return matchedConnection
              ? {
                  value: matchedConnection.value,
                  label: matchedConnection.label,
                  kind: matchedConnection.kind,
                  description: matchedConnection.description
                }
              : { value: projectConnection.name, label: projectConnection.name };
          })
        : []
    );
    setUnleashCommand(projectUnLeashCommand);
    setLeashCommand(projectLeashCommand);
    setViewState(editProjectViewStates.IDLE);
    setWorkflows(
      workspaceWorkflows.map((workflow) => {
        return { value: workflow.name, label: workflow.name, description: workflow.description };
      }) || []
    );
    setSelectedWorkflows(
      projectWorkflows && workflows
        ? projectWorkflows.map((projectWorkflow) => {
            const matchedWorkflow = workflows.find(
              (workflow) => workflow.value === projectWorkflow
            );
            return matchedWorkflow
              ? {
                  value: matchedWorkflow.value,
                  label: matchedWorkflow.label,
                  description: matchedWorkflow.description
                }
              : { value: projectWorkflow, label: projectWorkflow };
          })
        : []
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payloadObject]);

  useEffect(() => {
    if (selectedSlackChannelId === '') {
      selectSlackChannelOptions();
    }
    // eslint-disable-next-line
  }, [slackChannels]);

  useEffect(() => {
    fetchData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { projects } = payload;
        setProjectList(projects);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (
        filteredProjectList.find((project) => project.name.toLowerCase() === name.toLowerCase())
      ) {
        setFormErrorModel({
          ...formErrorModel,
          uniqueName: true
        });
      } else {
        setFormErrorModel({
          ...formErrorModel,
          uniqueName: false
        });
      }
    };

    if (projectList.length !== 0) checkIfNameExists();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, projectList]);

  const selectSlackChannelOptions = () => {
    if (slackChannels && slackChannels.length > 0) {
      const optionsArr = slackChannels.map((channel, i) => {
        return {
          label: channel?.name,
          value: channel?.id
          //isActive: item.active
        };
      });

      setSlackChannelOptions(optionsArr);
    }
  };

  if (!project.id) {
    return (
      <form action="/projects/edit" onSubmit={() => {}}>
        <Loading />
      </form>
    );
  }
  const disabled = false;
  const btnExtraClassName = '';

  const onNameChange = (evt) => {
    const trimmedValue = evt.target.value.trim();
    if (trimmedValue !== '') {
      setName(trimmedValue);
    } else {
      setName(evt.target.value);
    }
  };
  const onDescriptionChange = (evt) => {
    setDescription(evt.target.value);
  };

  const onPromptChange = (evt) => {
    setGptPrompt(evt.target.value);
  };

  const handleGptChange = (event) => {
    const { checked } = event.target;
    setGptEnable(checked);
  };

  const onBackClick = (evt) => {
    evt.preventDefault();
    setViewState(editProjectViewStates.REDIRECT_TO_PARENT);
  };

  const onSelectSlackChannelChange = (evt) => {
    setSelectedSlackChannelId(evt.value);
    const ch = slackChannels.find((c) => c.id === evt.value);
    const name = ch ? ch.name : '';
    setSelectedSlackChannelName(name);
  };

  const onFormSubmit = (evt) => {
    evt.preventDefault();
    const obj = {
      name: name.trim() === '',
      uniqueName: filteredProjectList.find(
        (project) => project.name.toLowerCase() === name.toLowerCase()
      )
        ? true
        : false
    };
    setFormErrorModel(obj);
    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    }

    const payload = {
      name,
      id: project.id,
      description,
      connections: selectedConnections.map((connection) => {
        return connection.value;
      }),
      commands: selectedCommands.map((command) => {
        return command.value;
      }),
      workflows: selectedWorkflows.map((workflow) => {
        return workflow.value;
      }),
      slackChannelId: selectedSlackChannelId,
      slackChannelName: selectedSlackChannelName,
      gptPrompt,
      gptEnable,
      unleashCommand: unleashCommand,
      leashCommand: leashCommand
    };
    if (!payload.slackChannelId) {
      alert('Please select a slack channel first.');
      return;
    }

    updateProject(payload).then(
      ({success,err}) => {
        if(success){
          toast({
            title: 'Successfully',
            description: 'Project successfully updated'
          });
          setViewState(editProjectViewStates.REDIRECT_TO_PARENT);
        }else{
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description:`Project not updated Error: ${err}`
          });
        }
      },
      (err) => {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:`Project not updated Error: ${err}`
        });
      }
    );
  };

  const onChannelChangeClick = (evt) => {
    evt.preventDefault();
    alert('Not implemented');
  };

  const onDeleteClick = (evt) => {
    evt.preventDefault();
    setShowModal(!showModal);
  };

  const confirmDelete = () => {
    setShowModal(!showModal);
    deletionConfirmed();
  };

  const deletionConfirmed = () => {
    deleteProject({ id: projectId }).then(
      () => {
        toast({
          title: 'Successfully',
          description:'Deleted project'
        });
        setViewState(editProjectViewStates.REDIRECT_TO_PARENT);
      },
      () => {
        setViewState(editProjectViewStates.REDIRECT_TO_PARENT);
      }
    );
  };

  const handleChangeApprovalPolicyGroup = (selectedGroup) => {
    setUnleashCommand({ ...unleashCommand, group: selectedGroup });
  };
  const handleChangeApprovalPolicyFlags = (selectedFlags) => {
    setUnleashCommand({
      ...unleashCommand,
      list: [...selectedFlags.map((command) => command.value)]
    });
  };
  const handleChangeExecutionPolicyGroup = (selectedGroup) => {
    setLeashCommand({ ...unleashCommand, group: selectedGroup });
  };
  const handleChangeExecutionPolicyFlags = (selectedFlags) => {
    setLeashCommand({
      ...unleashCommand,
      list: [...selectedFlags.map((command) => command.value)]
    });
  };

  if (viewState === editProjectViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/projects" />;
  }

  if (!slackChannels) {
    return (
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="flex max-w-3xl flex-col">
          <div className="my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="inline-block min-w-full overflow-hidden border-b border-gray-200 bg-white p-8 align-middle shadow sm:rounded-lg">
              <p>Link this project to a slack channel first.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <form action="/projects/edit" onSubmit={onFormSubmit}>
              <div className="flex flex-col gap-6">
                <h2 className="mb-4 mt-0 text-center text-base font-bold">UPDATE PROJECT</h2>

                <div>
                  <label htmlFor="name" className="label-main">
                    Project Name
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <input
                      id="name"
                      required
                      value={name}
                      maxLength="150"
                      onChange={onNameChange}
                      disabled={disabled}
                      placeholder="e.g., Hogwarts Staging Operations"
                      className={`${btnExtraClassName} input-main ${
                        formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                      }`}
                    />
                  </div>
                  <span className="label-second">
                    Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                  </span>

                  {formErrorModel.name && (
                    <span className="label-second my-1 block !text-red-500">
                      This area is required!
                    </span>
                  )}

                  {formErrorModel.uniqueName && (
                    <span className="label-second my-1 block !text-red-500">
                      {`${name.toLocaleLowerCase()} is  already taken`}
                    </span>
                  )}
                </div>

                <div>
                  <label htmlFor="description" className="label-main">
                    Project Description
                  </label>
                  <div className="mt-1 flex rounded-md">
                    <input
                      id="description"
                      value={description}
                      onChange={onDescriptionChange}
                      maxLength={125}
                      placeholder="e.g., Staging operations for the Hogwarts School of Witchcraft and Wizardry CRM portal."
                      className={`${btnExtraClassName} input-main`}
                    />
                  </div>
                </div>

                {projectSlackChannel && projectSlackChannel.name ? null : (
                  <div>
                    <h2 className="text-xl font-extrabold"> Slack Integration </h2>
                    <div className="my-2 rounded-md bg-blue-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-5 w-5 text-blue-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                          <p className="text-sm leading-5 text-blue-700">
                            Each project can <em>only</em> be linked to a <strong>unique</strong>{' '}
                            Slack channel.
                            <br />
                            Each Slack channel can <em>only</em> be associated with a{' '}
                            <strong>single</strong> project.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {projectSlackChannel ? (
                  <div>
                    <label htmlFor="slack-channel" className="label-main">
                      Slack Channel
                    </label>
                    <div className="flex rounded-md">
                      <div className="label-second">
                        <strong className="font-extrabold">#{projectSlackChannel.name}</strong>{' '}
                        <a
                          href="/change"
                          onClick={onChannelChangeClick}
                          className="hidden rounded-sm p-2 pt-0 underline duration-150 ease-out hover:text-secondary"
                        >
                          change
                        </a>
                      </div>
                    </div>
                    <div className="flex rounded-md" />
                  </div>
                ) : !slackChannels ? null : (
                  <div className="col-span-3 rounded border-4 border-yellow-300 bg-yellow-100 p-2 shadow-lg">
                    <label
                      htmlFor="slack-channel"
                      className="label-main !text-black dark:!text-black"
                    >
                      Slack Channel
                    </label>
                    <Select
                      id="slack-channel"
                      required
                      className="input-main !h-auto dark:!border-gray-300 dark:!text-gray-700"
                      placeholder="Select a Channel"
                      defaultValue=""
                      onChange={onSelectSlackChannelChange}
                      options={slackChannelOptions}
                    />
                  </div>
                )}

                <div>
                  <label className="label-main">Slack Team</label>
                  <div className="flex rounded-md">
                    <div className="flex w-full rounded-md text-gray-500">
                      <strong>{slackTeam}</strong>
                    </div>
                  </div>
                </div>
                <div className="block">
                  <div className="mb-4 flex items-center gap-2">
                    <label className="switch">
                      <input
                        type="checkbox"
                        id="gptEnable"
                        name="gptEnable"
                        checked={gptEnable}
                        onChange={handleGptChange}
                      />
                      <span className="slider round"></span>
                    </label>
                    <label htmlFor="gptEnable" className="label-main">
                      Enable GPT
                    </label>
                  </div>
                  <div className={gptEnable ? 'block' : 'hidden'}>
                    <h1 className="mb-4 text-lg font-extrabold">Training Data</h1>
                    <textarea
                      id="gptPrompt"
                      name="gptPrompt"
                      value={gptPrompt}
                      onChange={onPromptChange}
                      className="prompt"
                      rows="8"
                      cols="100%"
                      placeholder="Example: get me the list of ec2 instances from region eu-central-1                                                         
                    Output: aws ec2-describe-instances --region eu-central-1                                                                                                                                                                                                                                                                                                         "
                    ></textarea>
                  </div>
                </div>
                {/*Commands */}
                <MultiSelectDataTable
                  title={'Commands'}
                  tableData={commands}
                  selectedTableData={selectedCommands}
                  setSelectedTableDataCallback={setSelectedCommands}
                />
                {/*Connections */}
                <MultiSelectDataTable
                  title={'Connections'}
                  tableData={connections}
                  selectedTableData={selectedConnections}
                  setSelectedTableDataCallback={setSelectedConnections}
                />
                {/*Workflows */}
                <MultiSelectDataTable
                  title={'Workflows'}
                  tableData={workflows}
                  selectedTableData={selectedWorkflows}
                  setSelectedTableDataCallback={setSelectedWorkflows}
                />
                <Disclosure as="div" className="border-b border-gray-200 py-6">
                  {({ open }) => (
                    <>
                      <h3 className="-my-3 flow-root">
                        <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm">
                          <h2 className="text-xl font-extrabold">Advanced Settings</h2>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusIcon className="h-5 w-5" aria-hidden="true" />
                            ) : (
                              <ChevronDownIcon
                                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className="pt-6">
                        <div className="space-y-4">
                          {/* Approval Policy Override */}
                          <ApprovalPolicyOverride
                            defaultGroup={
                              project.unleashCommand ? project.unleashCommand.group : ''
                            } // for difference between old command object type
                            defaultFlags={project.unleashCommand ? project.unleashCommand.list : []} // for difference between old command object type
                            onChangeApproverGroup={handleChangeApprovalPolicyGroup}
                            onChangeFlags={handleChangeApprovalPolicyFlags}
                          />

                          {/* Execution Policy Override */}
                          <ExecutionPolicyOverride
                            defaultGroup={project.leashCommand ? project.leashCommand.group : ''} // for difference between old command object type
                            defaultFlags={project.leashCommand ? project.leashCommand.list : []} // for difference between old command object type
                            onChangeApproverGroup={handleChangeExecutionPolicyGroup}
                            onChangeFlags={handleChangeExecutionPolicyFlags}
                          />
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>

                {userState.userRole.role !== userRoles.READ_ONLY && (
                  <div>
                    <h2 className="text-xl font-extrabold">Danger Zone</h2>

                    <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                      <p className="label-second">
                        By clicking the button below you delete this project. This action is{' '}
                        <strong>irreversible</strong>.
                      </p>
                      <p className="label-second mb-4">
                        You can still re-use this project’s connections and commands in other
                        projects.
                      </p>
                      <button
                        disabled={disabled}
                        type="button"
                        onClick={onDeleteClick}
                        className={`${btnExtraClassName} text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                      >
                        I understand the consequences. Delete this project
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {userState.userRole.role !== userRoles.READ_ONLY && (
                <div className="flex justify-end">
                  <span className="inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      disabled={disabled}
                      onClick={onBackClick}
                      className={`${btnExtraClassName} cancel-button`}
                    >
                      Cancel
                    </button>
                  </span>
                  <span className="ml-3 inline-flex rounded-md shadow-sm">
                    <button
                      disabled={disabled}
                      type="submit"
                      className={`${btnExtraClassName} save-button`}
                    >
                      Save
                    </button>
                  </span>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this project?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ProjectEdit;
