import React, { useState, useEffect } from 'react';
import { addNewConnectionViewStates, postMethods } from 'constants/index';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { addOrUpdateAgentConnection } from './io';
import { globalViewStates } from 'constants/index';
import { deleteConnection, fetchConnectionDataDetail } from '../connectionList/io';
import { fetchData } from 'features/project/ProjectList/io';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import { BiCopy } from 'react-icons/bi';
import { fetchConnectionData } from '../connectionList/io';
import sendFetchTriggersRequest from 'network/sendFetchTriggersRequest';
import { fetchPolicies } from 'features/user/io';
import { useSelector } from 'react-redux';
import { userRoles } from 'constants/index';
import { ConnectionEnvironment } from '../connectionEnvironment';
import { useToast } from 'hooks/use-toast';

const API_ROOT = window.ob.config.apiRoot;
const trimmedAPI_ROOT = API_ROOT.substring(8);

const initialFormModel = {
  name: '',
  kind: 'agent',
  description: '',
  agentConnectionInfo: {
    status: 'pending'
  },
  projectNames: []
};

const ConnectionNewAgent = () => {
  const [viewState, setViewState] = useState(addNewConnectionViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [showModal, setShowModal] = useState(false);
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false
  });
  // eslint-disable-next-line no-unused-vars
  const [urlModal, setUrlModal] = useState(false);
  const [agentUrl, setAgentUrl] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [connectionList, setConnectionList] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [selectedTriggers, setSelectedTriggers] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const params = useParams(); // get param
  const connectionName = params.connectionName ? params.connectionName : null;
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();

  // Get Projects
  useEffect(() => {
    fetchData().then(({ success, payload }) => {
      void success;
      const { projects } = payload;

      const allProjectsOption = projects.length && { value: 'selectAll', label: 'All' };
      setProjects(
        allProjectsOption
          ? [
              allProjectsOption,
              ...projects.map((connection) => ({
                value: connection.name,
                label: connection.name
              }))
            ]
          : projects.map((connection) => ({ value: connection.name, label: connection.name }))
      );
    });
    sendFetchTriggersRequest().then(({ success, payload }) => {
      void success;

      const { triggers } = payload;

      setTriggers(triggers);
    });
    fetchPolicies().then(({ success, payload }) => {
      void success;

      const { policies } = payload;

      setPolicies(policies);
    });
  }, []);

  useEffect(() => {
    if (policies) {
      const selectedPolicy = policies.filter(
        (policy) => policy.connections && policy.connections.includes(connectionName)
      );
      setSelectedPolicies(selectedPolicy);
    }
  }, [policies, connectionName]);

  useEffect(() => {
    if (triggers) {
      const selectedTrigger = triggers.filter(
        (trigger) => trigger.connections && trigger.connections.includes(connectionName)
      );
      setSelectedTriggers(selectedTrigger);
    }
  }, [triggers, connectionName]);

  const onFormSubmit = (type, e) => {
    e.preventDefault();

    const obj = {
      name: type === postMethods.UPDATE ? false : formModel.name.trim() === '',
      uniqueName:
        type === postMethods.UPDATE
          ? false
          : connectionList.find(
              (connection) => connection.name.toLowerCase() === formModel.name.toLowerCase()
            )
          ? true
          : false
    };

    setFormErrorModel(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    } else {
      const selectedProjectNames = selectedProjects.map((project, index) => {
        return project.label;
      });

      setFormModel({
        ...formModel,
        projectNames: selectedProjectNames
      });

      if (type === postMethods.SAVE) {
        onFormSave();
      } else if (type === postMethods.UPDATE) {
        onFormUpdate();
      }
    }
  };

  useEffect(() => {
    if (connectionName) {
      fetchConnectionDataDetail(connectionName).then(
        ({ success, payload }) => {
          void success;

          const { connection } = payload;

          const temporaryForm = {
            name: connection.name,
            kind: 'agent',
            description: connection.description,
            agentConnectionInfo: {
              status: connection.agentConnectionInfo.status
            },
            projectNames: connection.projectNames
          };

          const currentProjects = connection.projectNames.map((project, i) => {
            return {
              label: project,
              value: project
              // disabled: true
            };
          });

          setSelectedProjects(currentProjects);

          setFormModel(temporaryForm);

          setViewState(addNewConnectionViewStates.DONE);
        },
        (err) => {
          console.log('error', err);
        }
      );
    }
  }, [connectionName]);

  useEffect(() => {
    fetchConnectionData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { connections } = payload;
        setConnectionList(connections);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (!connectionName) {
        if (
          connectionList.find(
            (connection) => connection.name.toLowerCase() === formModel.name.toLowerCase()
          )
        ) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false
          });
        }
      }
    };
    checkIfNameExists();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModel.name, connectionList]);

  //Change Input
  const onChangeInput = (value, field) => {
    const trimmedValue = value.trim();
    if (field === 'name' && (trimmedValue !== '' || value === '')) {
      setFormModel({
        ...formModel,
        [field]: trimmedValue
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value
      });
    }
  };

  const onFormSave = async (evt) => {
    
    addOrUpdateAgentConnection({
      ...formModel,
      projectNames: selectedProjects.map((project) => {
        return project.value;
      })
    }).then(
      (response) => {
        setUrlModal(true);
        setAgentUrl(response.configurationToken);
        toast({
          title: 'Successfully',
          description: 'Added new agent connection.'
        });
        navigate(`/connections/new/agent/${formModel.name}`);
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
      }
    );
  };

  const onFormUpdate = async (isDelete = false) => {
    try {
      setViewState(globalViewStates.POSTING);
      const { result: successUpdate } = await addOrUpdateAgentConnection(
        {
          ...formModel,
          projectNames: selectedProjects.map((project) => {
            return project.value;
          })
        },
        connectionName
      );

      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        if (!isDelete) {
          toast({
            title: 'Successfully',
            description: 'Connection successfully updated'
          });
        }
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Please try again later.'
      });
    }
  };

  const onFormConfigurate = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);
      const { result: successUpdate, configurationToken } = await addOrUpdateAgentConnection(
        formModel,
        connectionName
      );
      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        if (configurationToken) {
          setAgentUrl(configurationToken);
          toast({
            title: 'Successfully',
            description: 'Created regenerate token'
          });
        }
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem. Please try again later.'
      });
    }
  };

  const onBackClick = (evt) => {
    evt.preventDefault();
    setViewState(globalViewStates.REDIRECT_TO_PARENT);
  };

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/connections" />;
  }

  const disabled = viewState === globalViewStates.POSTING;
  //delete connection
  const onDeleteClick = async (evt) => {
    evt.preventDefault();
    if (
      selectedTriggers.length !== 0 ||
      selectedPolicies.length !== 0 ||
      selectedProjects.length !== 0
    ) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: `This command is being used in ${
          selectedTriggers.length !== 0
            ? `${selectedTriggers.map((trigger) => `'${trigger.name}'`).join(', ')} ${
                selectedProjects &&
                selectedProjects.map((project) => `'${project.value}'`).join(', ')
              } triggers`
            : ''
        }
        ${
          selectedPolicies.length !== 0
            ? `${selectedPolicies.map((policy) => `'${policy.name}'`).join(', ')} policies`
            : ''
        } 
        `
      });
    } else {
      setShowModal(!showModal);
      formModel.projectNames = [];
    }
  };

  const confirmDelete = async () => {
    setShowModal(!showModal);
    await onFormUpdate(true); // delete process
    deletionConfirmed();
  };

  const deletionConfirmed = (evt) => {
    if (!connectionName) {
      return;
    }
    setViewState(globalViewStates.EXECUTING);
    deleteConnection(connectionName).then(
      () => {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: `${connectionName} was deleted.`
        });
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.DONE);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again later.'
        });
      }
    );
  };

  const copyToClipboard = async (command) => {
    try {
      await navigator.clipboard.writeText(command);
      toast({
        title: 'Successfully',
        description: 'Copied to clipboard'
      });
    } catch (err) {
      console.log('Failed to copy text: ', err);
    }
  };

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">
              {connectionName ? 'UPDATE AGENT CONNECTION' : 'NEW AGENT CONNECTION'}
            </h2>

            <div className="flex flex-col gap-6">
              <div>
                <div className="flex justify-between gap-4">
                  <label htmlFor="name" className="label-main">
                    Connection Name *
                  </label>
                  {connectionName && (
                    <div className="agent-status flex">
                      Status :{' '}
                      <div className="agent-instruction-status">
                        {formModel.agentConnectionInfo.status === 'undownload'
                          ? 'pending'
                          : formModel.agentConnectionInfo.status}
                      </div>
                    </div>
                  )}
                </div>

                <input
                  name="name"
                  id="name"
                  disabled={disabled || connectionName}
                  readOnly={connectionName}
                  placeholder=""
                  maxLength="250"
                  required
                  className={`${connectionName ? 'input-main-disabled' : 'input-main'} ${
                    formErrorModel.uniqueName ? 'form-error border-2 border-red-500' : ''
                  }`}
                  value={connectionName ? connectionName : formModel.name}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'name');
                  }}
                />

                <span className="label-main">
                  Choose a <strong>single word</strong> with <strong>no spaces</strong>.
                </span>

                {formErrorModel.name && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}

                {formErrorModel.uniqueName && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="description" className="label-main">
                  Description
                </label>

                <input
                  id="description"
                  name="description"
                  disabled={disabled}
                  required
                  maxLength={125}
                  placeholder=""
                  className={`input-main`}
                  value={formModel.description}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'description');
                  }}
                />
              </div>
            </div>

            {formModel.agentConnectionInfo.status === 'expired' ||
            (formModel.agentConnectionInfo.status === 'undownload' && connectionName) ||
            agentUrl ? (
              <div className="agent-instruction">
                <div className="agent-instruction-topbar">
                  <div>#Download the latest agent package</div>
                  <div className="flex gap-2">
                    <div>Status:</div>{' '}
                    <div className="agent-instruction-status">
                      {formModel.agentConnectionInfo.status === 'undownload'
                        ? 'pending'
                        : formModel.agentConnectionInfo.status}
                    </div>
                  </div>
                </div>
                <div className="agent-instruction-body">
                  <div className="codeBlock">
                    <div className="codeBlock-scroll">
                      <div class="codeBlock-lines">
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                        <div>4</div>
                        <div>5</div>
                        <div>6</div>
                        <div>7</div>
                        <div>8</div>
                        <div>9</div>
                      </div>
                      <pre className="codeBlock-pre">
                        <code className="language-bash">
                          <span className="comment-line">#For Linux</span>
                          <div className="comment-line-heading">
                            <div className="flex">
                              curl{' '}
                              <div className="font-semibold text-blue-400">
                                https://ob2-public.s3.us-east-1.amazonaws.com/ob-agent/linux/ob-agent
                                -o ob-agent
                              </div>
                            </div>
                            <div
                              className="agent-copy"
                              onClick={() =>
                                copyToClipboard(
                                  'curl https://ob2-public.s3.us-east-1.amazonaws.com/ob-agent/linux/ob-agent -o ob-agent'
                                )
                              }
                            >
                              <BiCopy className="h-5 w-5" />
                            </div>
                          </div>
                          <span className="comment-line">#For MacOS</span>
                          <div className="comment-line-heading">
                            <div className="flex">
                              curl{' '}
                              <div className="font-semibold text-blue-400">
                                https://ob2-public.s3.us-east-1.amazonaws.com/ob-agent/macos/ob-agent
                                -o ob-agent
                              </div>
                            </div>
                            <div
                              className="agent-copy"
                              onClick={() =>
                                copyToClipboard(
                                  'curl https://ob2-public.s3.us-east-1.amazonaws.com/ob-agent/macos/ob-agent -o ob-agent'
                                )
                              }
                            >
                              <BiCopy className="h-5 w-5" />
                            </div>
                          </div>
                          <span className="comment-line">
                            #Change the permissions of ob-agent to execute
                          </span>
                          <div className="comment-line-heading">
                            <div>chmod +x ob-agent</div>
                            <div
                              className="agent-copy"
                              onClick={() => copyToClipboard('chmod +x ob-agent')}
                            >
                              <BiCopy className="h-5 w-5" />
                            </div>
                          </div>
                          <span className="comment-line">
                            #Run the agent binary by entering the following command, replacing{' '}
                            <server /> with the server address and <token /> with your token:
                          </span>
                          <div className="comment-line-heading">
                            <div className="flex">
                              ./ob-agent --server wss://{trimmedAPI_ROOT}/ws --token{' '}
                              {agentUrl ? (
                                agentUrl
                              ) : (
                                <div
                                  className="regenerate-agent"
                                  onClick={(e) => onFormConfigurate()}
                                >
                                  Regenerate Token
                                </div>
                              )}
                            </div>
                            {agentUrl ? (
                              <div
                                className="agent-copy"
                                onClick={() =>
                                  copyToClipboard(
                                    `./ob-agent --server wss://${trimmedAPI_ROOT}/ws --token ${agentUrl}`
                                  )
                                }
                              >
                                <BiCopy className="h-5 w-5" />
                              </div>
                            ) : null}
                          </div>
                          <span className="comment-line">
                            #After executing these steps, the OpsBeacon agent should start and
                            connect to the specified server using the provided token.
                          </span>
                        </code>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {connectionName && (
              <div>
                <h2 className="mb-2 text-xl font-extrabold">Environment Variables</h2>
                <ConnectionEnvironment connectionName={connectionName} />
              </div>
            )}
            {userState.userRole.role !== userRoles.READ_ONLY && connectionName && (
              <>
                <h2 className="py-6 text-xl font-extrabold">Danger Zone</h2>

                <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                  <p className="label-second">
                    By clicking the button below you delete this connection. This action is{' '}
                    <strong>irreversible</strong>.
                  </p>
                  <p className="label-second mb-4">
                    You can still re-use this connection's secrets and commands in other
                    connections.
                  </p>
                  <button
                    type="button"
                    onClick={onDeleteClick}
                    className="text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700"
                  >
                    I understand the consequences. Delete this connection
                  </button>
                </div>
              </>
            )}

            {userState.userRole.role !== userRoles.READ_ONLY && (
              <div className="mt-5 flex justify-center">
                <button type="button" className="btn-danger" onClick={onBackClick}>
                  Cancel
                </button>
                {connectionName ? (
                  <button
                    type="button"
                    className="btn-success"
                    onClick={(e) => onFormSubmit('UPDATE', e)}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-success"
                    onClick={(e) => onFormSubmit('SAVE', e)}
                  >
                    Save
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this connection?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConnectionNewAgent;
