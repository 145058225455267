import { sendFetchCommandsRequest } from 'network/sendFetchCommandsRequest';
import { sendFetchConnectionsRequest } from 'network/sendFetchConnectionsRequestForTriggers';
import { sendFetchWorkflowsRequest } from 'network/sendFetchWorkflowsRequest';
import { sendFetchLinkRequest } from './network';

const fetchData = async ({ linkName }) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const { payload } = await sendFetchLinkRequest(linkName);
    const {
      payload: { info }
    } = await sendFetchLinkRequest(linkName);
    const {
      payload: { commands }
    } = await sendFetchCommandsRequest();
    const {
      payload: { connections }
    } = await sendFetchConnectionsRequest();
    const {
      payload: { workflows }
    } = await sendFetchWorkflowsRequest();
    return {
      success: true,
      payload: {
        trigger: info.trigger,
        connections,
        commands,
        workflows
      }
    };
  } catch (ex) {
    return {
      success: false,
      payload: {
        trigger: {},
        commands: "",
        connections: "",
        workflows: "",
      }
    };
  }
};

export { fetchData };
