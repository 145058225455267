import Select from "react-select";
import { useState } from "react";
import map from "lodash/map";
import { postCommand } from "../network";
import { CirclePlayIcon } from "lucide-react";

export const TableRow = ({
  rowData,
  tableRowActions,
  appId,
  setRefreshTable,
}) => {
  const [selectedRowAction, setSelectedRowAction] = useState({});
  const transformedArray = map(tableRowActions, (action) => {
    return {
      label: action.name,
      value: action.name,
      action: action.action,
    };
  });

  const handleChangeRowAction = (e) => {
    setSelectedRowAction(e);
  };

  const handleRowActionRun = (e) => {
    const command = selectedRowAction.action.replace(
      /{{(.*?)}}/g,
      (match, p1) => rowData[p1] || ""
    );

    postCommand({ appId: appId, command: command }).then(
      ({ success, payload }) => {
        if (success) {
          setRefreshTable(true);
        } else {
          setRefreshTable(false);
        }
      }
    );
  };
  return (
    <div className={`${ transformedArray.length > 0 ? 'flex' : 'hidden'} flex-row`}>
      <Select
      className="w-32"
        options={transformedArray}
        onChange={handleChangeRowAction}
        value={selectedRowAction}
      />
      <button className="ml-5" onClick={handleRowActionRun}>
      <CirclePlayIcon className="text-[#3D506F]"/>
      </button>
    </div>
  );
};
