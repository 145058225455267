import { useEffect, useState } from 'react';
import { BiX } from 'react-icons/bi';
import Select from 'react-select';

const typeOptions = [
  { label: 'integer', value: 'integer' },
  { label: 'string', value: 'string' },
  { label: 'email', value: 'email' },
  { label: 'bool', value: 'bool' }
];

const requiredOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' }
];

const boolOptions = [
  { label: 'True', value: true },
  { label: 'False', value: false }
];

export default function CommandArgument({ argumentObject, onRemove, onChange }) {
  const createSelectOption = (value, label = value) => ({ value: value, label: label });

  const [name, setName] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [selectedTypeOption, setSelectedTypeOption] = useState(createSelectOption(''));
  const [selectedBoolOption, setSelectedBoolOption] = useState(boolOptions[0]);
  const [selectedRequireOption, setSelectedRequireOption] = useState(createSelectOption(''));

  useEffect(() => {
    setName(argumentObject.name);
    setDefaultValue(argumentObject.default);
    if (argumentObject.type === 'bool') {
      setSelectedBoolOption(
        argumentObject.default !== ''
          ? boolOptions.filter(
              (option) =>
                option.label.toLowerCase() === argumentObject.default.toString().toLowerCase()
            )[0]
          : boolOptions[0]
      );
    }
    setSelectedTypeOption(createSelectOption(argumentObject.type));
    setSelectedRequireOption(
      createSelectOption(
        argumentObject.required ? 'true' : 'false',
        argumentObject.required ? 'Yes' : 'No'
      )
    );
  }, [argumentObject]);

  const handleChangeArgumentName = (e) => {
    setName(e.target.value);
    onChange({ ...argumentObject, name: e.target.value.trim() });
  };

  const handleChangeDefaultValue = (e) => {
    setDefaultValue(e.target.value);
    onChange({ ...argumentObject, default: e.target.value.trim() });
  };

  const handleChangeTypeOption = (e) => {
    setSelectedTypeOption(e);
    onChange({ ...argumentObject, type: e.value, default: '' });
  };

  const handleChangeBoolOption = (e) => {
    setSelectedBoolOption(e);
    onChange({ ...argumentObject, default: e.value });
  };

  const handleChangeRequireOption = (e) => {
    setSelectedRequireOption(e);
    onChange({ ...argumentObject, required: e.value === 'true' ? true : false });
  };

  return (
    <div className="command-arguments">
      <div className="relative w-full">
        <input
          name="argumentName"
          type="text"
          className={`${argumentObject.invalidName ? 'input-main border-red-500' : 'input-main'}`}
          placeholder="name"
          value={name}
          onChange={handleChangeArgumentName}
        />
      </div>
      <div className="relative w-full">
        <Select
          name="type"
          className="my-2 w-full"
          placeholder="type"
          options={typeOptions}
          classNames={{
            control: (state) =>
              state.isFocused ? 'multi-select-input-focus' : 'multi-select-input',
            menuList: () => 'multi-select-dropdown',
            option: () => 'multi-select-option',
            input: () => 'multi-select-input-text',
            singleValue: () => 'dark:!text-gray-200'
          }}
          value={selectedTypeOption.value !== '' && selectedTypeOption}
          onChange={handleChangeTypeOption}
        />
      </div>

      <div className="relative w-full">
        {selectedTypeOption.value === 'bool' ? (
          <Select
            name="requried"
            className="my-2 w-full"
            // placeholder="required"
            defaultValue={selectedBoolOption}
            options={boolOptions}
            value={selectedBoolOption.value !== '' && selectedBoolOption}
            onChange={handleChangeBoolOption}
          />
        ) : (
          <input
            name="defaultArgumentValue"
            type="text"
            className={`${
              argumentObject.invalidDefault ? 'input-main border-red-500' : 'input-main'
            }`}
            placeholder="default value"
            value={defaultValue}
            onChange={handleChangeDefaultValue}
          />
        )}
      </div>
      <div className="relative w-full">
        <Select
          name="requried"
          className="my-2 w-full"
          placeholder="required"
          options={requiredOptions}
          classNames={{
            control: (state) =>
              state.isFocused ? 'multi-select-input-focus' : 'multi-select-input',
            menuList: () => 'multi-select-dropdown',
            option: () => 'multi-select-option',
            input: () => 'multi-select-input-text',
            singleValue: () => 'dark:!text-gray-200'
          }}
          value={selectedRequireOption.value !== '' && selectedRequireOption}
          onChange={handleChangeRequireOption}
        />
      </div>
      <div className="absolute right-0">
        <button
          className="btn-delete-second !m-0 self-center"
          onClick={() => onRemove(argumentObject.id)}
        >
          <BiX />
        </button>
      </div>
      <div className="absolute bottom-0">
          <span
            className={`label-second block !text-red-500 ${
              argumentObject.invalidMessage === '' ? 'hidden' : 'block'
            }`}
          >{`*${argumentObject.invalidMessage}`}</span>
        </div>
    </div>
  );
}
