import IconGoogle from 'components/icons/iconGoogle';
import IconMicrosoft from 'components/icons/iconMicrosoft';
import IconSlack from 'components/icons/IconSlack';
import { useEffect, useState } from 'react';
import { authWithMicrosoft, authWithPassword, authWithGoogle, authWithSlack } from './network';
import { useDispatch } from 'react-redux';
import { setLoginState } from 'redux/slices/userSlice';
import { loginViewStates } from 'constants/index';
import { Navigate, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useToast } from 'hooks/use-toast';

const LoginWorkspaceSettings = ({ workspaceOptions, email, slackCode }) => {
  const [workspaceSettings, setWorkspaceSettings] = useState([
    {
      id: 'enableGoogleLogin',
      icon: <IconGoogle />,
      label: 'Google',
      active: false
    },
    {
      id: 'enableSlackLogin',
      icon: <IconSlack />,
      label: 'Slack',
      active: false
    },
    {
      id: 'enableMicrosoftLogin',
      icon: <IconMicrosoft />,
      label: 'Microsoft',
      active: false
    },
    {
      id: 'enablePasswordLogin',
      icon: null,
      label: 'SSO',
      active: false
    }
  ]);
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const [viewState, setViewState] = useState(loginViewStates.DEFAULT);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (workspaceOptions) {
      setWorkspaceSettings((prevState) =>
        prevState.map((setting) => ({
          ...setting,
          active: workspaceOptions[setting.id] || false
        }))
      );
    }
  }, [workspaceOptions]);

  const handleAction = (settingId) => {
    if (settingId === 'enableMicrosoftLogin') {
      loginWithMicrosoft();
    } else if (settingId === 'enableGoogleLogin') {
      loginWithGoogle();
    } else if (settingId === 'enableSlackLogin') {
      loginWithSlack();
    }
  };

  const loginWithPassword = async () => {
    setViewState(loginViewStates.LOADING);
    try {
      const { success } = await authWithPassword(email, password);

      if (success) {
        dispatch(setLoginState(true));
        setViewState(loginViewStates.REDIRECT_TO_DASHBOARD);
        toast({
          title: 'Successfully',
          description: 'Successfully logged in, redirect to dashboard'
        });
        return;
      } else {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'Please check your password.'
        });
      
        dispatch(setLoginState(false));
        setViewState(loginViewStates.DEFAULT);
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please check your password.'
      });
      dispatch(setLoginState(false));
      setViewState(loginViewStates.DEFAULT);
    }
  };

  const loginWithMicrosoft = async () => {
    try {
      const { success, location } = await authWithMicrosoft({
        trial: true,
        optIn: false,
        lookupKey: 'opsbeacon-starter'
      });
      if (success && location) {
        window.location.href = location;
      } else if (success) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'Redirect failed'
        });
      } else {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
        });
        dispatch(setLoginState(false));
        setViewState(loginViewStates.DEFAULT);
      }
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
      });
      dispatch(setLoginState(false));
      setViewState(loginViewStates.DEFAULT);
    }
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      let accessToken = tokenResponse.access_token;
      authWithGoogle({
        accessToken,
        lookupKey: 'opsbeacon-starter',
        trial: true,
        optIn: false
      })
        .then(({ success }) => {
          if (success) {
            dispatch(setLoginState(true));
            setViewState(loginViewStates.REDIRECT_TO_DASHBOARD);
            toast({
              title: 'Successfully',
              description: 'Successfully logged in, redirect to dashboard'
            });
          } else {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
            });
            dispatch(setLoginState(false));
            setViewState(loginViewStates.DEFAULT);
          }
        })
        .catch(() => {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
          });
          dispatch(setLoginState(false));
          setViewState(loginViewStates.DEFAULT);
        });
    }
  });

  const slackRedirectUri = `${window.location.origin}/slack-auth`;
  const loginWithSlack = () => {
    const authorizeUrl = `https://slack.com/openid/connect/authorize?response_type=code&scope=openid%20profile%20email&client_id=${workspaceOptions.slackClientId}&redirect_uri=${slackRedirectUri}`;
    window.location.href = authorizeUrl;
  };

  useEffect(() => {
    if (slackCode) {
      let accessToken = slackCode;
      authWithSlack({
        accessToken,
        lookupKey: 'opsbeacon-starter',
        trial: true,
        optIn: false
      })
        .then(({ success }) => {
          if (success) {
            dispatch(setLoginState(true));
            navigate('/dashboard');
            toast({
              title: 'Successfully',
              description: 'Successfully logged in, redirect to dashboard'
            });
          }
        })
        .catch(() => {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
          });
          navigate('/');
          dispatch(setLoginState(false));
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, slackCode]);

  if (viewState === loginViewStates.REDIRECT_TO_DASHBOARD) {
    return <Navigate to="/dashboard" />;
  }


  return (
    <div className="relative flex grow flex-col items-center justify-between bg-gray-300 px-5 py-8 text-black dark:bg-black dark:text-white sm:rounded-t-[30px] md:rounded-none md:px-6">
      <div className="relative flex w-full grow flex-col items-center justify-center">
        <div className="w-full max-w-[440px]">
          <div className="flex flex-1 flex-col justify-center lg:flex-none">
            <div className="py-8">
              {workspaceOptions ? (
                <>
                  {/* Enable Password Login Section */}
                  {workspaceSettings.some(
                    (setting) => setting.id === 'enablePasswordLogin' && setting.active
                  ) && (
                    <>
                      <div>
                        <h2 className="text-2xl font-black leading-9 tracking-tight text-gray-900">
                          Sign in to your account
                        </h2>
                      </div>
                      <div>
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Password
                        </label>
                        <div className="mt-1 rounded-md shadow-sm">
                          <input
                            id="password"
                            type="password"
                            placeholder="*********"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoFocus
                            required
                            className="block w-full rounded-md border-0 px-4 py-2.5 shadow-inner outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                          />
                        </div>
                        <div className="mt-6">
                          <span className="block w-full rounded-md shadow-sm">
                            <button
                              onClick={loginWithPassword}
                              className="flex w-full justify-center rounded-md bg-secondary px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Sign In
                            </button>
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Authentication Method Options */}
                  <p className="mb-4 mt-16 text-[18px] font-black leading-7">
                    {workspaceSettings.some(
                      (setting) => setting.id === 'enablePasswordLogin' && setting.active
                    )
                      ? 'Or choose from other authentication methods enabled for your organization:'
                      : 'Choose Authentication Method'}
                  </p>

                  {/* Display Other Authentication Methods */}
                  {workspaceSettings.map(
                    (setting) =>
                      setting.id !== 'enablePasswordLogin' && (
                        <div
                          key={setting.id}
                          onClick={setting.active ? () => handleAction(setting.id) : undefined}
                          className={`mb-4 h-12 rounded-lg border border-gray-400/50 bg-gray-100 ${
                            setting.active
                              ? 'cursor-pointer'
                              : 'cursor-default bg-gray-400/50 grayscale'
                          }`}
                        >
                          <div className="flex h-full items-center justify-start px-6">
                            <div>{setting.icon}</div>
                            <div className="w-full text-center">
                              <div className="text-gray-600">Sign in with {setting.label}</div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </>
              ) : (
                slackCode && (
                  <div className="mb-4 mt-16 text-[18px] font-black leading-7">
                    Please wait while the redirect is processed...
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginWorkspaceSettings;
