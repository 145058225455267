import { useState } from 'react';
import { BsArrowRepeat, BsClock } from 'react-icons/bs';
import { resendInvitation } from './network';
import { useToast } from 'hooks/use-toast';

export default function GridView({ list, rowClick }) {
  const { toast } = useToast();
  // eslint-disable-next-line no-unused-vars
  const [preventNavigation, setPreventNavigation] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [resend, setResend] = useState(false);
  // eslint-disable-next-line no-unused-vars
  let invitationClick = false;
  return (
    <div>
      {list.length > 0 ? (
        <ul className="mt-3 grid grid-cols-2 gap-4 xl:grid-cols-4">
          {list.map((item, i) => (
            <li
              onClick={() => rowClick(item)}
              key={i}
              className="col-span-1 flex h-20 rounded-md shadow-sm"
            >
              <div className="flex w-12 flex-shrink-0 items-center justify-center rounded-l-md border-b border-r border-t border-gray-200 bg-blue-100 text-sm font-medium uppercase text-blue-400">
                USER
              </div>
              <div className="command-card">
                <div className="flex-1 px-4 py-4">
                  <h2 className="text-md dark:text-blue-400">{item.fullName}</h2>
                  <p className="text-xs line-clamp-1 text-gray-500 dark:text-gray-200">
                    {item.email}
                  </p>
                  <p className="text-md line-clamp-1 text-secondary dark:text-gray-200">
                    {item.status === 'active' ? (
                      item.status
                    ) : (
                      <div className="flex flex-col gap-0.5">
                        <div className="flex items-center gap-1 text-secondary">
                          <BsClock /> Invite Pending
                        </div>
                        <div
                          className="my-1 flex w-fit items-center gap-0.5 rounded-lg border border-blue-600 px-1 text-secondary hover:bg-secondary hover:text-white"
                          onClick={(e) => {
                            e.preventDefault();
                            setPreventNavigation(true);
                            setResend(true);
                            invitationClick = true;
                            resendInvitation({ email: item.email }).then(({ result }) => {
                              toast({
                                title: 'Successfully',
                                description: 'Re-invitation has been sent to user'
                              });
                            });
                          }}
                        >
                          <BsArrowRepeat />
                          Resend
                        </div>
                      </div>
                    )}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="my-48 flex justify-center text-2xl text-gray-400">No user found</div>
      )}
    </div>
  );
}
