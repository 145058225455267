import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useToast } from 'hooks/use-toast';
import { addNewUserViewStates } from 'constants';
import {
  globalViewStates,
  postMethods,
  userRolesSelectOptions,
  superAdminUserRoleOption
} from 'constants/index';
import RequiredField from 'components/toolbox/RequiredField';
import {
  addNewUserRole,
  addOrUpdateUser,
  fetchGroups,
  fetchPolicies,
  fetchUserData,
  fetchUserDataDetail
} from './io';
import { sendDeleteUserRequest } from './network';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/modal/Modal';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import MultiSelectDataTable from 'components/multiSelectDataTable';

const NewUser = () => {
  const { toast } = useToast();
  const emailRegex = /^\S+@\S+\.\S+$/;

  const initialFormModel = {
    email: '',
    role: '',
    groups: [],
    policies: []
  };

  const [viewState, setViewState] = useState(globalViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [roles, setRoles] = useState(userRolesSelectOptions);
  const [showModal, setShowModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [formErrorModel, setFormErrorModel] = useState({
    email: false,
    role: false
  });

  // eslint-disable-next-line no-unused-vars
  const [users, setUsers] = useState([]);
  const userState = useSelector((state) => state.userState);

  // Get groups
  useEffect(() => {
    fetchGroups().then(({ success, payload }) => {
      void success;
      const { groups } = payload;
      setGroups(groups.map((group) => ({ value: group.name, label: group.name })));
    });
  }, []);

  useEffect(() => {
    fetchUserData().then(
      ({ success, payload }) => {
        void success;
        const { users } = payload;
        setUsers(users);

        const superAdminUsers = users.filter((user) => user.role === 'superadmin');
        const adminUsers = users.filter((user) => user.role === 'admin');
        const operatorUsers = users.filter((user) => user.role === 'operator');

        let filteredOptionRoles = [...userRolesSelectOptions];

        if (userState.stripeInfo?.features?.numberAdminUser !== -1) {
          if (
            userState.stripeInfo?.features?.numberAdminUser === adminUsers.length ||
            userState.stripeInfo?.features?.numberAdminUser === superAdminUsers.length
          ) {
            filteredOptionRoles = userRolesSelectOptions.filter((option) => {
              return option.label !== 'admin';
            });
          }
        }

        if (userState.stripeInfo?.features?.numberOperatorUser !== -1) {
          if (userState.stripeInfo?.features?.numberOperatorUser === operatorUsers.length) {
            filteredOptionRoles = filteredOptionRoles.filter((option) => {
              return option.label !== 'operator';
            });
          }
        }

        setRoles(filteredOptionRoles);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, [userState.stripeInfo]);

  // Get policies
  useEffect(() => {
    fetchPolicies().then(({ success, payload }) => {
      void success;
      const { policies } = payload;
      setPolicies(policies.map((policy) => ({ value: policy.name, label: policy.name })));
    });
  }, []);

  const splitEmail = (emails) => {
    const splitEmails = emails.split(',');
    return splitEmails;
  };

  const params = useParams(); // get param
  const userMail = params.userMail ? params.userMail : null;

  useEffect(() => {
    if (userMail !== null) {
      fetchUserDataDetail(userMail).then(
        ({ success, payload }) => {
          void success;

          const { user } = payload;

          const temporaryForm = {
            email: user.email,
            role: user.role,
            groups: user.groups,
            policies: user.policy
          };
          setSelectedGroups(
            user.groups
              ? user.groups.map((group) => {
                  return { value: group, label: group };
                })
              : []
          );
          setSelectedPolicies(
            user.policy
              ? user.policy.map((p) => {
                  return { value: p, label: p };
                })
              : []
          );
          setFormModel(temporaryForm);
          setViewState(addNewUserViewStates.DONE);
        },
        (err) => {
          console.log('error', err);
        }
      );
    }
  }, [userMail]);

  const onFormSubmit = (type, e) => {
    e.preventDefault();

    const obj = {
      email: type === postMethods.UPDATE ? false : formModel.email.trim() === ''
    };

    setFormErrorModel(obj);

    if (Object.values(obj).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill the required areas!'
      });
      return;
    }
    const selectedGroupsName = selectedGroups.map((group, index) => {
      return group.label;
    });
    const selectedPoliciesName = selectedPolicies.map((policy, index) => {
      return policy.label;
    });
    setFormModel({
      ...formModel,
      groups: selectedGroupsName,
      policies: selectedPoliciesName
    });

    if (type === postMethods.SAVE) {
      onFormEdit();
    } else if (type === postMethods.UPDATE) {
      onFormUpdate();
    }
  };

  const onFormEdit = async (e) => {
    e && e.preventDefault();

    const formErrors = {
      email: formModel.email.trim() === '',
      role: formModel.role.trim() === ''
    };

    setFormErrorModel(formErrors);

    if (Object.values(formErrors).includes(true)) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please fill in the required areas!'
      });
    } else if (!emailRegex.test(formModel.email)) {
      // Add email validation check
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please enter a valid email address!'
      });
      return;
    } else {
      const deleteLastBlank = formModel.email.trim();
      const splitData = splitEmail(deleteLastBlank).filter((email) => email !== '');

      splitData.forEach((item) => {
        const newFormModel = {
          email: item.trim(),
          role: formModel.role,
          groups: selectedGroups,
          policies: selectedPolicies
        };

        onFormSave(item.trim(), newFormModel);
      });
    }
  };

  const onFormSave = async (email, newFormModel) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successAdd } = await addNewUserRole({
        ...newFormModel,
        groups: selectedGroups.map((group) => {
          return group.value;
        }),
        policies: selectedPolicies.map((policy) => {
          return policy.value;
        })
      });

      if (!successAdd) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: `There was a problem adding a new user role for email ${email}. Please try again later.`
        });
        setViewState(globalViewStates.IDLE);
      } else {
        toast({
          title: 'Successfully',
          description: `${email} was invited successfully.`
        });
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
      }
    } catch (error) {
      console.error(error);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: `There was an error adding a new user role for email ${email}. Please try again later.`
      });
    }
  };

  const onFormUpdate = async () => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateUser(
        {
          ...formModel,
          groups: selectedGroups.map((group) => {
            return group.value;
          }),
          policies: selectedPolicies.map((policy) => {
            return policy.value;
          })
        },
        userMail
      );
      if (!successUpdate) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem updating your user. Please try again later.'
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: 'User updated.'
        });
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem updating your user. Please try again later.'
      });
    }
  };

  const clearForm = () => {
    setFormModel(initialFormModel);
  };

  const onChangeInput = (value, field) => {
    setFormModel({
      ...formModel,
      [field]: value
    });
  };

  //delete user
  const onDeleteClick = async (evt) => {
    evt.preventDefault();
    setShowModal(!showModal);
  };

  const confirmDelete = async () => {
    setShowModal(!showModal);
    deletionConfirmed();
  };

  const deletionConfirmed = (evt) => {
    if (!userMail) {
      return;
    }
    setViewState(globalViewStates.EXECUTING);
    sendDeleteUserRequest(userMail).then(
      () => {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: 'Successfully',
          description: `User ${userMail} was deleted.`
        });
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.DONE);
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem. Please try again!'
        });
      }
    );
  };
  const disabled = viewState === globalViewStates.POSTING;

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/settings/users" />;
  }

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">
              {userMail ? 'UPDATE USER' : 'INVITE TO OPSBEACON'}
            </h2>

            <div className="flex flex-col gap-6">
              <div>
                <label htmlFor="email" className="label-main">
                  Email *
                </label>

                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder=""
                  required
                  disabled={disabled || userMail}
                  className="input-main !h-auto"
                  value={userMail ? userMail : formModel.email}
                  onChange={(e) => {
                    onChangeInput(e.target.value, 'email');
                  }}
                />

                {formErrorModel.email && <RequiredField />}

                <label className="label-second">
                  You can add multiple emails by separating them with commas. (email1, email2)
                </label>
              </div>

              <div>
                <label htmlFor="role-select" className="label-main">
                  User Role *
                </label>

                <Select
                  isDisabled={formModel.role === 'superadmin'}
                  id="role-select"
                  required
                  placeholder="Select a user role"
                  value={
                    formModel.role === 'superadmin'
                      ? superAdminUserRoleOption
                      : roles.find((role) => role.value === formModel.role)
                  }
                  onChange={(e) => {
                    onChangeInput(e.value, 'role');
                  }}
                  options={roles}
                  classNames={{
                    control: (state) => {
                      const disabledStyle = state.isDisabled && 'multi-select-input-disabled';
                      const focusedStyle = state.isFocused
                        ? 'multi-select-input-focus'
                        : 'multi-select-input';

                      return `${focusedStyle} ${disabledStyle}`;
                    },
                    menuList: () => 'multi-select-dropdown',
                    option: () => 'multi-select-option',
                    input: () => 'multi-select-input-text',
                    singleValue: () => 'dark:!text-gray-200'
                  }}
                />

                {formErrorModel.role && <RequiredField />}
              </div>
              {/*Groups */}
              <MultiSelectDataTable
                title={'Groups'}
                tableData={groups}
                selectedTableData={selectedGroups}
                setSelectedTableDataCallback={setSelectedGroups}
              />
              {/*Policies */}
              <MultiSelectDataTable
                title={'Policies'}
                tableData={policies}
                selectedTableData={selectedPolicies}
                setSelectedTableDataCallback={setSelectedPolicies}
              />

              <div className="flex justify-center">
                {userMail ? (
                  <button type="button" className="btn-danger" onClick={onDeleteClick}>
                    Delete User
                  </button>
                ) : (
                  <button type="button" className="btn-danger" onClick={clearForm}>
                    Clear
                  </button>
                )}
                {userMail ? (
                  <button
                    type="button"
                    className="btn-success"
                    onClick={(e) => onFormSubmit('UPDATE', e)}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-success"
                    onClick={(e) => onFormSubmit('SAVE', e)}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={'md'} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this user?</p>
        </ModalBody>

        <ModalFooter>
          <button className="text-red-600 dark:text-red-500" onClick={() => confirmDelete()}>
            Delete User
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NewUser;
