import React, { useState, useEffect } from "react";
import { useInvocationTableFetch } from "hooks/useInvocationTableFetch";
import { DataTable } from "components/data-table";

export const InvocationTables = ({ kind, header, dateBoundaries }) => {
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const { data, error, setPage, setContainsText, setStartDate, setEndDate } =
    useInvocationTableFetch({
      initialInvocationKind: kind,
      initialStartDate: dateBoundaries.startDate,
      initialEndDate: dateBoundaries.endDate,
      initialPage: 1,
      initialDirection: "desc",
      initialLimit: 5,
      initialContainsText: "",
    });

  useEffect(() => {
    setTableData(data?.invocationData);
    if (data !== null) {
      setTotalPages(Math.ceil(data?.dataCount / 5));
    }
  }, [data]);

  useEffect(() => {
    setStartDate(dateBoundaries.startDate);
    setEndDate(dateBoundaries.endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateBoundaries]);

  const columns = [
    {
      header: `${header} name`,
      accessorKey: "name",
      cell: (info) => info.getValue(),
    },
    {
      header: "Invocation Count",
      accessorKey: "count",
      cell: (info) => info.getValue(),
    },
  ];
  return error ? (
    <div className="relative overflow-x-auto rounded-3xl bg-white p-6 shadow-lg sm:rounded-lg">
      Something went wrong...
    </div>
  ) : (
    <div className="pb-1.5">
      <div className="flex-column flex flex-nowrap items-center justify-between gap-2 space-y-4 pb-4 sm:flex-row sm:space-y-0">
        <label htmlFor="table-search" className="sr-only">
          Search
        </label>
        <div className="relative mt-10">
          <div className="rtl:inset-r-0 pointer-events-none absolute inset-y-0 left-0 flex items-center ps-3 rtl:right-0">
            <svg
              className="h-4 w-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            onChange={(e) => {
              setContainsText(e.target.value.trim());
            }}
            type="text"
            id="table-search"
            className=" block w-64 rounded-lg border border-gray-300 bg-gray-50 p-2 ps-10 text-sm text-gray-900 shadow-inner outline-none"
            placeholder="Search for items"
          />
        </div>
      </div>
      <DataTable columns={columns} data={tableData || []} />
      <div className="mt-3 flex items-end justify-between">
        <div className="flex-1 text-sm text-[#71717a]">
          Total {data?.dataCount} {header}
        </div>
        <nav>
          <ul className="flex h-8 items-center -space-x-px text-sm">
            <li>
              <button
                onClick={() => {
                  if (data?.currentPage > 1) {
                    setPage(data?.currentPage - 1);
                  }
                }}
                className="inline-flex items-center justify-center gap-2 whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs"
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="h-2 w-2 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 1 1 5l4 4"
                  />
                </svg>
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (index) => {
                return (
                  <li key={index}>
                    <button
                      onClick={(e) => {
                        setPage(index);
                      }}
                      className={`inline-flex items-center justify-center gap-2 whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs ${
                        index === data?.currentPage
                          ? "bg-secondaries_blue_600/100 text-gray-300 "
                          : "bg-white text-gray-500"
                      }`}
                    >
                      {index}
                    </button>
                  </li>
                );
              }
            )}
            <li>
              <button
                onClick={() => {
                  if (totalPages > data?.currentPage) {
                    setPage(data?.currentPage + 1);
                  }
                }}
                className="inline-flex items-center justify-center gap-2 whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs"
              >
                <span className="sr-only">Next</span>
                <svg
                  className="h-2 w-2 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
