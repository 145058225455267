import React, { useState, useEffect, useLayoutEffect } from "react";
import Loading from "components/loading/Loading";
import { BiX, BiPlus } from "react-icons/bi";
import { features, globalViewStates, postMethods } from "constants/index";
import { commandExists, addOrUpdateCommand } from "./io";
import { fetchCommandDataDetail, deleteCommand } from "../commandList/io";
import { fetchData } from "features/project/ProjectList/io";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "components/modal/Modal";
import ApprovalPolicyOverride from "components/ApprovalPolicyOverride";
import ExecutionPolicyOverride from "components/ExecutionPolicyOverride";
import ApprovalWorkflow from "components/ApprovalWorkflow";
import CommandArgument from "components/CommandArgument";
import { nanoid } from "nanoid";
import {
  createCommandArgumentObject,
  isInvalidArguments,
  validateArguments,
} from "./helpers/argumentHelper";
import { fetchCommandsData } from "./io";
import { Flags } from "contexts/FlagProvider";
import { fetchPolicies } from "features/user/io";
import sendFetchTriggersRequest from "network/sendFetchTriggersRequest";
import { useSelector } from "react-redux";
import { userRoles } from "constants/index";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, MinusIcon } from "@heroicons/react/20/solid";
import { useToast } from "hooks/use-toast";
import { Navigate, useParams } from "react-router-dom";
import ReactCodeMirror from "@uiw/react-codemirror";
import { githubDark } from "@uiw/codemirror-theme-github";
import { json } from "@codemirror/lang-json";

const initialFormModel = {
  name: "",
  kind: "rest",
  description: "",
  // connection: '',
  private: false,
  restCommandInfo: {
    arguments: [
      // {
      //   name: '',
      //   required: true
      // }
    ],
    method: "GET",
    url: "",
    queryParams: [
      // {
      //   key: '',
      //   value: ''
      // }
    ],
    headers: [
      // {
      //   key: '',
      //   value: ''
      // }
    ],
    body: "",
    urlEncodedBody: [],
    contentType: "application/json",
  },
  projectNames: [],
  approvalGroup: "",
  unleashCommand: {},
  leashCommand: {},
};

const CommandNewRest = () => {
  const [viewState, setViewState] = useState(globalViewStates.IDLE);
  const [formModel, setFormModel] = useState(initialFormModel);
  const [showModal, setShowModal] = useState(false);
  const [argumentObjects, setArgumentObjects] = useState([]);
  const [submit, setIsSubmit] = useState(false);
  const [postMethodType, setPostMethodType] = useState();
  const [formErrorModel, setFormErrorModel] = useState({
    name: false,
    uniqueName: false,
    method: false,
    url: false,
    contentType: false,
    arguments: false,
    queryParams: false,
    headers: false,
    command: false,
  });

  // eslint-disable-next-line no-unused-vars
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const params = useParams(); // get param
  const commandName = params.commandName ? params.commandName : null;
  const [commandList, setCommandList] = useState([]);

  const [policies, setPolicies] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [selectedTriggers, setSelectedTriggers] = useState([]);
  const userState = useSelector((state) => state.userState);
  const { toast } = useToast();
  useLayoutEffect(() => {
    if (commandName) {
      fetchCommandDataDetail(commandName).then(
        ({ success, payload }) => {
          void success;

          const { command } = payload;

          const temporaryForm = {
            // name: command.name,
            kind: "rest",
            description: command.description,
            // connection: command.connection,
            private: command.private,
            restCommandInfo: {
              arguments: createCommandArgumentObject(
                command.restCommandInfo.arguments
              ),
              method: command.restCommandInfo.method,
              url: command.restCommandInfo.url,
              queryParams: command.restCommandInfo.queryParams.map(
                (queryParam, index) => {
                  return {
                    key: queryParam.key,
                    value: queryParam.value,
                  };
                }
              ),
              headers: command.restCommandInfo.headers.map((header, index) => {
                return {
                  key: header.key,
                  value: header.value,
                };
              }),
              body: command.restCommandInfo.body,
              urlEncodedBody: command.restCommandInfo.urlEncodedBody.map(
                (object, index) => {
                  return {
                    key: object.key,
                    value: object.value,
                  };
                }
              ),
              contentType: command.restCommandInfo.contentType,
            },
            projectNames: command.projectNames,
            approvalGroup: command.approvalGroup,
            unleashCommand: command.unleashCommand,
            leashCommand: command.leashCommand,
          };

          setSelectedProjects(
            command.projectNames
              ? command.projectNames.map((project) => {
                  return { value: project, label: project };
                })
              : []
          );
          setFormModel(temporaryForm);
          setArgumentObjects(temporaryForm.restCommandInfo.arguments);

          setViewState(globalViewStates.DONE);
        },
        (err) => {
          console.log("error", err);
        }
      );
    }
  }, [commandName]);

  //Updating inputs when contentType changes
  useEffect(() => {
    if (formModel.restCommandInfo.contentType === "application/json") {
      formModel.restCommandInfo.urlEncodedBody = [];
    } else {
      formModel.restCommandInfo.body = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModel.restCommandInfo.contentType]);

  // Get triggers and policies and projects
  useEffect(() => {
    fetchData().then(({ success, payload }) => {
      void success;

      const { projects } = payload;
      setProjects(projects);
    });
    fetchPolicies().then(({ success, payload }) => {
      void success;

      const { policies } = payload;

      setPolicies(policies);
    });
    sendFetchTriggersRequest().then(({ success, payload }) => {
      void success;

      const { triggers } = payload;

      setTriggers(triggers);
    });
  }, []);

  useEffect(() => {
    if (policies) {
      const selectedPolicy = policies.filter(
        (policy) => policy.commands && policy.commands.includes(commandName)
      );
      setSelectedPolicies(selectedPolicy);
    }
  }, [policies, commandName]);

  useEffect(() => {
    if (triggers) {
      const selectedTrigger = triggers.filter(
        (trigger) => trigger.commands && trigger.commands.includes(commandName)
      );
      setSelectedTriggers(selectedTrigger);
    }
  }, [triggers, commandName]);

  useEffect(() => {
    if (submit) {
      setIsSubmit(false);

      const paramControl = formModel.restCommandInfo.queryParams.map(
        (param, index) => {
          return param.key.trim() === "" ? true : false;
        }
      );

      const headerControl = formModel.restCommandInfo.headers.map(
        (header, index) => {
          return header.key.trim() === "" ? true : false;
        }
      );

      const obj = {
        name:
          postMethodType === postMethods.UPDATE
            ? false
            : formModel.name.trim() === "",
        uniqueName:
          postMethodType === postMethods.UPDATE
            ? false
            : commandList.find(
                (command) =>
                  command.name.toLowerCase() === formModel.name.toLowerCase()
              )
            ? true
            : false,
        method: formModel.restCommandInfo.method.trim() === "",
        url:
          postMethodType === postMethods.UPDATE
            ? false
            : formModel.restCommandInfo.url.trim() === "",
        contentType: formModel.restCommandInfo.contentType.trim() === "",
        queryParams: paramControl.includes(true),
        headers: headerControl.includes(true),
      };

      setFormErrorModel(obj);
      const validatedArgumentObjects = validateArguments(argumentObjects);
      setArgumentObjects([...validatedArgumentObjects]);

      if (
        Object.values(obj).includes(true) ||
        isInvalidArguments(validatedArgumentObjects)
      ) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "Please fill the required areas!",
        });
        return;
      } else {
        if (postMethodType === postMethods.SAVE) {
          onFormSave();
        } else if (postMethodType === postMethods.UPDATE) {
          onFormUpdate();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModel]);

  useEffect(() => {
    fetchCommandsData().then(
      ({ success, payload }) => {
        if (!success) {
          return;
        }
        const { commands } = payload;
        setCommandList(commands);
      },
      (err) => {
        console.log("error", err);
      }
    );
  }, []);

  useEffect(() => {
    const checkIfNameExists = () => {
      if (!commandName) {
        if (
          commandList.find(
            (command) =>
              command.name.toLowerCase() === formModel.name.toLowerCase()
          )
        ) {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: true,
          });
        } else {
          setFormErrorModel({
            ...formErrorModel,
            uniqueName: false,
          });
        }
      }
    };
    checkIfNameExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModel.name, commandList]);

  const onFormSubmit = (type, e) => {
    e.preventDefault();
    setPostMethodType(type);
    setIsSubmit(true);

    const restArguments = argumentObjects.map((argument) => ({
      name: argument.name,
      default:
        typeof argument.default == "boolean"
          ? argument.default.toString()
          : argument.default,
      shortName: argument.shortName,
      type: argument.type,
      required: argument.required,
    }));

    const url = formModel.restCommandInfo.url;
    const urlParts = url.split("?");
    const baseUrl = urlParts[0];

    setFormModel({
      ...formModel,
      restCommandInfo: {
        ...formModel.restCommandInfo,
        arguments: [...restArguments],
        url: baseUrl,
      },
    });
  };

  /********************   REST API   *********************/

  //Change Input
  const onChangeInput = (value, field, e) => {
    if (field === "method" || field === "contentType" || field === "body") {
      setFormModel({
        ...formModel,
        restCommandInfo: {
          ...formModel.restCommandInfo,
          [field]: value,
        },
      });
    } else if (field === "name" && value === "") {
      const trimmedValue = value.trim();
      setFormModel({
        ...formModel,
        [field]: trimmedValue,
      });
    } else if (field === "private") {
      const { checked } = e.target;
      setFormModel({
        ...formModel,
        private: checked,
      });
    } else {
      setFormModel({
        ...formModel,
        [field]: value,
      });
    }
  };

  //Change BaseUrl
  const onChangeBaseUrl = (value, field) => {
    let newUrl = parseUrl(value);
    const newQueryParameters = newUrl.qs.map((qs) => {
      if (qs[1] !== "") {
        return {
          key: qs[0],
          value: qs[1],
        };
      } else {
        return {
          key: "",
          value: "",
        };
      }
    });

    if (newQueryParameters.length) {
      setFormModel({
        ...formModel,
        restCommandInfo: {
          ...formModel.restCommandInfo,
          queryParams: newQueryParameters,
          url: value,
        },
      });
    } else {
      setFormModel({
        ...formModel,
        restCommandInfo: {
          ...formModel.restCommandInfo,
          url: value,
        },
      });
    }
  };

  const parseUrl = (url) => {
    var sp, hash, qs;

    sp = url.split("#");
    url = sp[0];
    hash = (sp.length > 1 ? "#" : "") + sp.slice(1).join("#");

    sp = url.split("?");
    url = sp[0];
    qs = sp.slice(1).join("?");

    qs = !qs.length ? [] : qs.split("&");
    qs = qs.map(function (param) {
      param = param.split("=");
      param = [param[0], param.slice(1).join("=")];
      return param.map(function (val, i) {
        var t;
        try {
          val = decodeURIComponent(val.replace(/\+/g, " "));
        } catch (e) {}

        try {
          t = JSON.parse(val);
          if (typeof t === "object") {
            val = JSON.stringify(t, null, 2);
          }
        } catch (e2) {}

        return val;
      });
    });

    return {
      url: url,
      qs: qs,
      hash: hash,
    };
  };

  const onFormUpdate = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateCommand(
        {
          ...formModel,
        },
        commandName
      );
      if (!successUpdate) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem. Try again later.",
        });
        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: "Successfully",
          description: "Command successfully updated",
        });
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description:
          "There was a problem updating your command. Please try again later.",
      });
    }
  };

  // clear form
  const clearForm = () => {
    setFormModel(initialFormModel);
  };

  /********************   Query Parameters   *********************/

  const onChangeQueryParameters = (value, field, index) => {
    setFormModel((prevState) => {
      if (field === "key") {
        prevState.restCommandInfo.queryParams[index].key = value;
      } else if (field === "value") {
        prevState.restCommandInfo.queryParams[index].value = value;
      }
      return {
        ...prevState,
      };
    });
  };

  // Add Query Params
  const addQueryParameter = (e) => {
    e.preventDefault();

    const newArray = [
      ...formModel.restCommandInfo.queryParams,
      { key: "", value: "" },
    ];
    setFormModel({
      ...formModel,
      restCommandInfo: {
        ...formModel.restCommandInfo,
        queryParams: newArray,
      },
    });
  };

  // Delete Query Params
  const deleteQueryParameter = (item, e) => {
    e.preventDefault();

    const newArray = [
      ...formModel.restCommandInfo.queryParams.filter(
        (param) => param.key !== item.key
      ),
    ];

    let ret = `${item.key}=${item.value}&`;
    let ret2 = `${item.key}=${item.value}`;

    let ret3 = formModel.restCommandInfo.url.replace(ret, "");
    const ret4 = ret3.replace(ret2, "");
    let lastCharacter = ret4.charAt(ret4.length - 1);
    let newUrl = lastCharacter === "&" ? ret4.slice(0, -1) : ret4;

    setFormModel({
      ...formModel,
      restCommandInfo: {
        ...formModel.restCommandInfo,
        url: newUrl,
        queryParams: newArray,
      },
    });
  };

  /********************  urlencoded   *********************/

  const onChangeUrlEncoded = (value, field, index) => {
    setFormModel((prevState) => {
      if (field === "key") {
        prevState.restCommandInfo.urlEncodedBody[index].key = value;
      } else if (field === "value") {
        prevState.restCommandInfo.urlEncodedBody[index].value = value;
      }
      return {
        ...prevState,
      };
    });
  };

  // Add urlencoded
  const addUrlEncoded = (e) => {
    e.preventDefault();

    const newArray = [
      ...formModel.restCommandInfo.urlEncodedBody,
      { key: "", value: "" },
    ];
    setFormModel({
      ...formModel,
      restCommandInfo: {
        ...formModel.restCommandInfo,
        urlEncodedBody: newArray,
      },
    });
  };

  // Delete urlencoded
  const deleteUrlEncoded = (item, e) => {
    e.preventDefault();

    const newArray = [
      ...formModel.restCommandInfo.urlEncodedBody.filter(
        (param) => param.key !== item.key
      ),
    ];

    setFormModel({
      ...formModel,
      restCommandInfo: {
        ...formModel.restCommandInfo,
        urlEncodedBody: newArray,
      },
    });
  };

  /********************   HEADERS   *********************/

  // Change Header
  const onChangeHeaders = (value, field, index) => {
    setFormModel((prevState) => {
      if (field === "key") {
        prevState.restCommandInfo.headers[index].key = value;
      } else if (field === "value") {
        prevState.restCommandInfo.headers[index].value = value;
      }

      return {
        ...prevState,
      };
    });
  };

  // Add Header
  const addHeader = (e) => {
    e.preventDefault();

    const newArray = [
      ...formModel.restCommandInfo.headers,
      { key: "", value: "" },
    ];
    setFormModel({
      ...formModel,
      restCommandInfo: {
        ...formModel.restCommandInfo,
        headers: newArray,
      },
    });
  };

  // Delete Header
  const deleteHeader = (item, e) => {
    e.preventDefault();

    const newArray = [
      ...formModel.restCommandInfo.headers.filter(
        (header) => header.key !== item.key
      ),
    ];

    setFormModel({
      ...formModel,
      restCommandInfo: {
        ...formModel.restCommandInfo,
        headers: newArray,
      },
    });
  };

  /********************   Save   *********************/

  const onFormSave = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);
      const { success, payload } = await commandExists(formModel.name);

      if (!success) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description:
            "There is already a record with the same name. Please choose a different name.",
        });

        setViewState(globalViewStates.IDLE);
        return;
      }

      const { exists } = payload;

      if (exists) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description:
            "There is already a record with the same name. Please choose a different name.",
        });
        setViewState(globalViewStates.IDLE);
        return;
      }

      const { success: successAdd } = await addOrUpdateCommand({
        ...formModel,
      });

      if (!successAdd) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description:
            "There was a problem saving your command. Please try again later.",
        });

        setViewState(globalViewStates.IDLE);
        return;
      } else {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: "Successfully",
          description: "New command added",
        });
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description:
          "There was a problem saving your command. Please try again later.",
      });
    }
  };

  const onProjeUpdate = async (evt) => {
    try {
      setViewState(globalViewStates.POSTING);

      const { success: successUpdate } = await addOrUpdateCommand(
        formModel,
        commandName
      );

      if (!successUpdate) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description:
            "There was a problem updating your command. Please try again later.",
        });
        setViewState(globalViewStates.IDLE);
        return;
      }
    } catch (ex) {
      console.log(ex);
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description:
          "There was a problem updating your command. Please try again later.",
      });
    }
  };

  //delete command
  const onDeleteClick = async (evt) => {
    evt.preventDefault();
    if (
      selectedTriggers.length !== 0 ||
      selectedPolicies.length !== 0 ||
      selectedProjects.length !== 0
    ) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: `This command is being used in ${
          selectedTriggers.length !== 0 || selectedProjects.length !== 0
            ? `${selectedTriggers
                .map((trigger) => `'${trigger.name}'`)
                .join(", ")} ${
                selectedProjects &&
                selectedProjects
                  .map((project) => `'${project.value}'`)
                  .join(", ")
              } triggers`
            : ""
        }
        ${
          selectedPolicies.length !== 0
            ? `${selectedPolicies
                .map((policy) => `'${policy.name}'`)
                .join(", ")} policies`
            : ""
        } 
        `,
      });
    } else {
      setShowModal(!showModal);
      formModel.projectNames = [];
    }
  };

  const confirmDelete = async () => {
    setShowModal(!showModal);
    await onProjeUpdate();
    deletionConfirmed();
  };

  const deletionConfirmed = (evt) => {
    if (!commandName) {
      return;
    }
    setViewState(globalViewStates.EXECUTING);
    deleteCommand(commandName).then(
      () => {
        setViewState(globalViewStates.REDIRECT_TO_PARENT);
        toast({
          title: "Successfully",
          description: `${commandName} was successfully deleted`,
        });
      },
      (err) => {
        console.log(err);
        setViewState(globalViewStates.DONE);
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem. Please try again later.",
        });
      }
    );
  };

  if (viewState === globalViewStates.REDIRECT_TO_PARENT) {
    return <Navigate to="/commands" />;
  }

  if (viewState === globalViewStates.LOADING) {
    return <Loading />;
  }
  const disabled = viewState === globalViewStates.POSTING;
  const btnExtraClassName = disabled ? "opacity-50 cursor-not-allowed" : "";

  // Add Argument
  const addArgument = (e) => {
    e.preventDefault();
    setArgumentObjects([
      ...argumentObjects,
      {
        id: nanoid(),
        name: "",
        shortName: "",
        required: false,
        type: "",
        default: "",
        invalidName: false,
        invalidDefault: false,
        invalidType: false,
        invalidMessage: "",
        duplicatedName: true,
      },
    ]);
  };

  // Delete Argument
  const removeArgument = (removedArgumentId) => {
    const filteredArguments = argumentObjects.filter(
      (argument) => argument.id !== removedArgumentId
    );
    setArgumentObjects(filteredArguments);
  };

  const handleChangedArgument = (updatedArgument) => {
    const updatedArgumentObjectIndex = argumentObjects.findIndex(
      (argument) => argument.id === updatedArgument.id
    );

    const copyArgumentObjects = argumentObjects;
    copyArgumentObjects.splice(updatedArgumentObjectIndex, 1, updatedArgument);
    setArgumentObjects([...copyArgumentObjects]);
  };

  const handleChangeApprovalPolicyGroup = (selectedGroup) => {
    setFormModel({
      ...formModel,
      unleashCommand: {
        ...formModel.unleashCommand,
        group: selectedGroup,
      },
    });
  };
  const handleChangeApprovalPolicyFlags = (selectedFlags) => {
    setFormModel({
      ...formModel,
      unleashCommand: {
        ...formModel.unleashCommand,
        list: [...selectedFlags.map((command) => command.value)],
      },
    });
  };
  const handleChangeExecutionPolicyGroup = (selectedGroup) => {
    setFormModel({
      ...formModel,
      leashCommand: {
        ...formModel.leashCommand,
        group: selectedGroup,
      },
    });
  };
  const handleChangeExecutionPolicyFlags = (selectedFlags) => {
    setFormModel({
      ...formModel,
      leashCommand: {
        ...formModel.leashCommand,
        list: [...selectedFlags.map((command) => command.value)],
      },
    });
  };

  return (
    <>
      <div className="form-container">
        <div className="hero-section">
          <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
            <h2 className="mb-4 mt-0 text-center text-base font-bold">
              {commandName ? "UPDATE REST COMMAND" : "NEW REST COMMAND"}
            </h2>
            <div className="mb-4 rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <BsFillInfoCircleFill className="h-5 w-5 text-blue-500" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm leading-5 text-blue-700">
                    For more information please checkout the
                    <a
                      href=" https://docs.ob2.ai/opsbeacon/args-in-cmd-workflow"
                      target="_blank"
                    >
                      <u> How to use arguments in commands and workflows</u>
                    </a>{" "}
                    page.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6">
              {/* Command Name  */}
              <div>
                <label htmlFor="name" className="label-main">
                  Command Name *
                </label>

                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  disabled={commandName}
                  readOnly={commandName}
                  maxLength="250"
                  placeholder=""
                  className={`${btnExtraClassName} ${
                    commandName ? "input-main-disabled" : "input-main"
                  } ${
                    formErrorModel.uniqueName
                      ? "form-error border-2 border-red-500"
                      : ""
                  }`}
                  value={commandName ? commandName : formModel.name}
                  onChange={(e) => {
                    onChangeInput(e.target.value, "name");
                  }}
                />

                <span className="label-second">
                  Choose a <strong>single word</strong> with{" "}
                  <strong>no spaces</strong>.
                </span>

                {formErrorModel.name && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}

                {formErrorModel.uniqueName && (
                  <span className="label-second my-1 block !text-red-500">
                    {`${formModel.name.toLocaleLowerCase()} is  already taken`}
                  </span>
                )}
              </div>

              {/*  Description  */}
              <div>
                <label htmlFor="description" className="label-main">
                  Description (optional)
                </label>

                <textarea
                  rows={2}
                  name="description"
                  id="description"
                  className="input-main"
                  value={formModel.description}
                  maxLength={125}
                  onChange={(e) => {
                    onChangeInput(e.target.value, "description");
                  }}
                />
              </div>

              {/* Methods */}
              <div>
                <label htmlFor="Method" className="label-main">
                  Method *
                </label>

                <div className="flex flex-wrap sm:flex-nowrap">
                  <div className="mr-0 w-full sm:mr-3 sm:w-2/12">
                    <select
                      id="method"
                      name="method"
                      className="input-main "
                      value={formModel.restCommandInfo.method}
                      onChange={(e) => {
                        onChangeInput(e.target.value, "method");
                      }}
                    >
                      <option key="GET" value="GET">
                        GET
                      </option>
                      <option key="POST" value="POST">
                        POST
                      </option>
                      <option key="PUT" value="PUT">
                        PUT
                      </option>
                      <option key="PATCH" value="PATCH">
                        PATCH
                      </option>
                      <option key="DELETE" value="DELETE">
                        DELETE
                      </option>
                    </select>
                  </div>

                  <div className="flex w-full sm:w-10/12">
                    <div className="w-4-12 mb-1 mt-2 inline-flex items-center rounded-l-md border border-r-0 bg-gray-100 px-3 text-xs text-gray-400 dark:border-gray-700 dark:bg-gray-800 sm:w-3/12 ">
                      <span>BASE_URL </span>
                    </div>
                    <div className="w-10/12">
                      <input
                        id="url"
                        name="url"
                        type="text"
                        autoComplete="url"
                        className="input-main !rounded-l-none"
                        value={formModel.restCommandInfo.url}
                        onChange={(e) => {
                          onChangeBaseUrl(e.target.value, "url");
                        }}
                      />
                    </div>
                  </div>
                </div>

                {(formErrorModel.method || formErrorModel.url) && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}
              </div>

              {/* Content Type */}
              <div>
                <label htmlFor="restCommandInfo" className="label-main">
                  Content Type *
                </label>

                <select
                  id="restCommandInfo"
                  name="restCommandInfo"
                  className="input-main"
                  onChange={(e) => {
                    onChangeInput(e.target.value, "contentType");
                  }}
                  value={formModel.restCommandInfo.contentType}
                >
                  <option key="application/json" value="application/json">
                    application/json
                  </option>
                  <option
                    key="x-www-form-urlencoded"
                    value="application/x-www-form-urlencoded"
                  >
                    application/x-www-form-urlencoded
                  </option>
                </select>
                {formErrorModel.contentType && (
                  <span className="label-second my-1 block !text-red-500">
                    This area is required!
                  </span>
                )}
              </div>

              {/* Arguments */}
              <div className="sm:col-span-6">
                <label
                  htmlFor="command"
                  className="label-main mb-3 block border-b pb-1 dark:border-gray-700"
                >
                  Arguments
                </label>
                {argumentObjects?.length > 0 && (
                  <>
                    <div className="command-arguments !pb-0">
                      <div className="w-full">
                        <label className="label-main">Name:</label>
                      </div>
                      <div className="w-full">
                        <label className="label-main">Type:</label>
                      </div>
                      <div className="w-full">
                        <label className="label-main">Default Value:</label>
                      </div>
                      <div className="w-full">
                        <label className="label-main">Required:</label>
                      </div>
                    </div>
                    {argumentObjects?.map((argument) => (
                      <CommandArgument
                        key={argument.id}
                        argumentObject={argument}
                        onRemove={removeArgument}
                        onChange={handleChangedArgument}
                      />
                    ))}
                  </>
                )}
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mt-1 flex items-center rounded-md border p-1.5 px-2 text-blue-500 hover:bg-blue-300 hover:text-blue-900 dark:border-gray-700"
                  onClick={addArgument}
                >
                  <BiPlus className="mr-1 !text-base" /> Add New
                </button>
              </div>

              {/* Query Parameters */}
              <div>
                <label
                  htmlFor="queryParams"
                  className={`
              label-main 
              ${
                formModel.restCommandInfo.queryParams.length === 0
                  ? "mb-1 block border-b pb-1 dark:border-gray-700"
                  : ""
              }
              `}
                >
                  Query Parameters
                </label>

                <div className="relative">
                  {formModel.restCommandInfo.queryParams &&
                    formModel.restCommandInfo.queryParams.map(
                      (queryParam, index) => {
                        return (
                          <div
                            className="inset-y-0  left-0 top-[2px] flex"
                            key={index}
                          >
                            <input
                              name="key"
                              type="text"
                              className="input-main !rounded-r-none"
                              placeholder="key"
                              value={queryParam.key}
                              onChange={(e) => {
                                onChangeQueryParameters(
                                  e.target.value,
                                  "key",
                                  index
                                );
                              }}
                            />

                            <input
                              name="value"
                              type="text"
                              className="input-main !rounded-l-none !rounded-r-none"
                              placeholder="value"
                              value={queryParam.value}
                              onChange={(e) => {
                                onChangeQueryParameters(
                                  e.target.value,
                                  "value",
                                  index
                                );
                              }}
                            />
                            <button
                              type="text"
                              className="btn-delete !rounded-l-none"
                              onClick={deleteQueryParameter.bind(
                                this,
                                queryParam
                              )}
                            >
                              <BiX />
                            </button>
                          </div>
                        );
                      }
                    )}

                  <div className="items-top flex justify-between text-xs ">
                    {formErrorModel.queryParams ? (
                      <span className="label-second my-1 block !text-red-500">
                        This area is required!
                      </span>
                    ) : (
                      <span className="label-second block"> </span>
                    )}

                    <button
                      type="button"
                      className="mt-1 flex items-center rounded-md border p-1.5 px-2 text-blue-500 hover:bg-blue-300 hover:text-blue-900 dark:border-gray-700"
                      onClick={addQueryParameter}
                    >
                      <BiPlus className="mr-1 !text-base" /> Add New
                    </button>
                  </div>
                </div>
              </div>

              {/*  Headers */}
              <div>
                <label
                  htmlFor="http"
                  className={`
              label-main 
              ${
                formModel.restCommandInfo.headers.length === 0
                  ? "mb-1 block border-b pb-1 dark:border-gray-700"
                  : ""
              }
              `}
                >
                  Headers
                </label>

                <div className="relative">
                  {formModel.restCommandInfo.headers &&
                    formModel.restCommandInfo.headers.map((item, index) => {
                      return (
                        <div
                          className="inset-y-0 left-0 top-[2px] flex"
                          key={index}
                        >
                          <input
                            name="key"
                            type="text"
                            className="input-main !rounded-r-none"
                            placeholder="key"
                            value={item.key}
                            onChange={(e) => {
                              onChangeHeaders(e.target.value, "key", index);
                            }}
                          />

                          <input
                            name="value"
                            type="text"
                            className="input-main !rounded-l-none !rounded-r-none"
                            placeholder="value"
                            value={item.value}
                            onChange={(e) => {
                              onChangeHeaders(e.target.value, "value", index);
                            }}
                          />

                          <button
                            type="text"
                            className="btn-delete !rounded-l-none"
                            onClick={deleteHeader.bind(this, item)}
                          >
                            <BiX />
                          </button>
                        </div>
                      );
                    })}

                  <div className="items-top flex justify-between text-xs ">
                    {formErrorModel.headers ? (
                      <span className="label-second my-1 block !text-red-500">
                        This area is required!
                      </span>
                    ) : (
                      <span className="label-second block"> </span>
                    )}

                    <button
                      type="text"
                      className="mt-1 flex items-center rounded-md border p-1.5 px-2 text-blue-500 hover:bg-blue-300 hover:text-blue-900 dark:border-gray-700"
                      onClick={addHeader}
                    >
                      <BiPlus className="mr-1 !text-base" /> Add New
                    </button>
                  </div>
                </div>
              </div>

              {formModel.restCommandInfo.method &&
              formModel.restCommandInfo.method !== "GET" ? (
                <div>
                  <div>
                    <label htmlFor="type" className="label-main border-b">
                      Body
                    </label>
                  </div>

                  {formModel.restCommandInfo.contentType ===
                  "application/json" ? (
                    <>
                      <div>
                        <ReactCodeMirror
                          height={commandName ? 'auto' : '50vh'}
                          value={formModel.restCommandInfo.body}
                          extensions={[json()]}
                          onChange={(value, e) => {
                            onChangeInput(value, 'body', e);
                          }}
                          theme={githubDark}
                          options={{
                            lineNumbers: true,
                            mode: "json",
                          }}
                          placeholder="Command line can be modified as needed. So, you can use either hardcoded or dynamic parameters."
                        />
                      </div>
                    </>
                  ) : (
                    <div className="relative">
                      {formModel.restCommandInfo.urlEncodedBody &&
                        formModel.restCommandInfo.urlEncodedBody.map(
                          (object, index) => {
                            return (
                              <div
                                className="inset-y-0  left-0 top-[2px] flex"
                                key={index}
                              >
                                <input
                                  name="key"
                                  type="text"
                                  className="input-main !rounded-r-none"
                                  placeholder="key"
                                  value={object.key}
                                  onChange={(e) => {
                                    onChangeUrlEncoded(
                                      e.target.value,
                                      "key",
                                      index
                                    );
                                  }}
                                />

                                <input
                                  name="value"
                                  type="text"
                                  className="input-main !rounded-l-none !rounded-r-none"
                                  placeholder="value"
                                  value={object.value}
                                  onChange={(e) => {
                                    onChangeUrlEncoded(
                                      e.target.value,
                                      "value",
                                      index
                                    );
                                  }}
                                />
                                <button
                                  type="text"
                                  className="btn-delete !rounded-l-none"
                                  onClick={deleteUrlEncoded.bind(this, object)}
                                >
                                  <BiX />
                                </button>
                              </div>
                            );
                          }
                        )}

                      <div className="items-top flex justify-end pt-2 text-xs">
                        {/* {formErrorModel.queryParams ? (
                          <span className="label-second my-1 block !text-red-500">
                            This area is required!
                          </span>
                        ) : (
                          <span className="label-second block"> </span>
                        )} */}

                        <button
                          type="button"
                          className="mt-1 flex items-center rounded-md border p-1.5 px-2 text-blue-500 hover:bg-blue-300 hover:text-blue-900 dark:border-gray-700"
                          onClick={addUrlEncoded}
                        >
                          <BiPlus className="mr-1 !text-base" /> Add New
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              <Disclosure as="div" className="border-b border-gray-200 py-6">
                {({ open }) => (
                  <>
                    <h3 className="-my-3 flow-root">
                      <Disclosure.Button className="flex w-full items-center justify-between py-3 text-sm">
                        <h2 className="text-xl font-extrabold">
                          Advanced Settings
                        </h2>
                        <span className="ml-6 flex items-center">
                          {open ? (
                            <MinusIcon className="h-5 w-5" aria-hidden="true" />
                          ) : (
                            <ChevronDownIcon
                              className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel className="pt-6">
                      <div className="flex flex-col gap-y-4 py-4">
                        <div className="rounded-md border p-4 dark:border-gray-700">
                          <div className="flex items-center">
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="private"
                                name="private"
                                checked={formModel.private}
                                onChange={(e) => {
                                  onChangeInput(!formModel.private, "private");
                                }}
                              />
                              <span className="slider round"></span>
                            </label>

                            <label
                              htmlFor="private"
                              className="label-main ml-2 mt-1 cursor-pointer "
                            >
                              Enable Direct Message Output
                            </label>
                          </div>

                          <label className="label-second mt-2 block">
                            Enable this setting to send the output of this
                            command directly to the person who triggered it.
                          </label>
                        </div>

                        {/* Approval Workflow */}
                        <Flags authorizedFlags={[features.APPROVAL_POLICY]}>
                          <ApprovalWorkflow
                            defaultGroup={{
                              value: formModel.approvalGroup,
                              label: formModel.approvalGroup,
                            }}
                            onChange={(approvalGroup) => {
                              setFormModel({
                                ...formModel,
                                approvalGroup: approvalGroup,
                              });
                            }}
                          />
                        </Flags>
                      </div>
                      <div className="space-y-4">
                        {/* Approval Policy Override */}
                        <ApprovalPolicyOverride
                          defaultGroup={
                            formModel.unleashCommand
                              ? formModel.unleashCommand.group
                              : ""
                          } // for difference between old command object type
                          defaultFlags={formModel.unleashCommand.list} // for difference between old command object type
                          onChangeApproverGroup={
                            handleChangeApprovalPolicyGroup
                          }
                          onChangeFlags={handleChangeApprovalPolicyFlags}
                        />

                        {/* Execution Policy Override */}
                        <ExecutionPolicyOverride
                          defaultGroup={
                            formModel.leashCommand
                              ? formModel.leashCommand.group
                              : ""
                          } // for difference between old command object type
                          defaultFlags={formModel.leashCommand.list} // for difference between 'old command object' type
                          onChangeApproverGroup={
                            handleChangeExecutionPolicyGroup
                          }
                          onChangeFlags={handleChangeExecutionPolicyFlags}
                        />
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              {userState.userRole.role !== userRoles.READ_ONLY &&
                commandName && (
                  <>
                    <h2 className="text-xl font-extrabold">Danger Zone</h2>

                    <div className="mb-8 rounded-md border border-red-500 p-4 text-center">
                      <p className="label-second">
                        By clicking the button below you delete this command.
                        This action is <strong>irreversible</strong>.
                      </p>
                      <p className="label-second mb-4">
                        You can still re-use this command's features in other
                        commands.
                      </p>
                      <button
                        disabled={disabled}
                        type="button"
                        onClick={onDeleteClick}
                        className={`${btnExtraClassName} text-bolder focus:shadow-outline-red inline-flex justify-center rounded-md  border border-gray-300 bg-red-100 px-6 py-2 font-bold leading-5 text-red-600 transition duration-150 ease-out hover:border-red-700 hover:bg-red-600 hover:text-white focus:border-red-700 focus:bg-red-600 focus:text-white focus:outline-none active:bg-red-700`}
                      >
                        I understand the consequences. Delete this command
                      </button>
                    </div>
                  </>
                )}

              {userState.userRole.role !== userRoles.READ_ONLY && (
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="btn-danger"
                    onClick={clearForm}
                  >
                    Clear
                  </button>

                  {commandName ? (
                    <button
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit("UPDATE", e)}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn-success"
                      onClick={(e) => onFormSubmit("SAVE", e)}
                    >
                      Save
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        size={"md"} // sm md lg xl full
        closeButton={true}
        setShow={setShowModal}
      >
        <ModalHeader>
          <h2>Confirm deletion!</h2>
        </ModalHeader>

        <ModalBody>
          <p>Are you sure you want to delete this command?</p>
        </ModalBody>

        <ModalFooter>
          <button
            className="text-red-600 dark:text-red-500"
            onClick={() => confirmDelete()}
          >
            Delete Item
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CommandNewRest;
