import React, { useEffect, useState } from 'react';
import { fetchUserData } from './io';
import { userListViewStates } from 'constants';
import ListTabs from 'components/listTabs/ListTabs';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/loading/Loading';
import LayoutView from 'components/LayoutView/LayoutView';
import ListView from './ListView';
import GridView from './GridView';
import { userInfo, userNavigationInfos } from 'assets/allData';

export default function UserContent() {
  const [viewState, setViewState] = useState(userListViewStates.LOADING);
  const [userList, setUserList] = useState([]);
  const [tableData, setTableData] = useState([]);

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  const [layoutView, setLayoutView] = useState('list');

  const handleSearch = (searchedCommand) => {
    setSearchTerm(searchedCommand);
  };

  useEffect(() => {
    fetchUserData().then(
      ({ success, payload }) => {
        void success;
        const { users } = payload;
        setUserList(users);
        setTableData(users);
        setViewState(userListViewStates.DONE);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }, []);

  useEffect(() => {
    if (searchTerm !== '') {
      const filteredDatas = tableData.filter(
        (listItem) =>
          listItem.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          listItem.fullName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setTableData(filteredDatas);
    } else {
      setTableData(userList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, userList]);

  const handleLayout = (selectedView) => {
    setLayoutView(selectedView);
  };

  if (viewState === userListViewStates.LOADING) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <ListTabs
        handleSearch={handleSearch}
        handleAddClick={() => navigate(`${userInfo.createNewPath}`)}
      />
      <React.Fragment>
        <LayoutView layoutView={layoutView} changeLayout={handleLayout} />
        {layoutView === 'list' ? (
          <ListView
            list={tableData}
            rowClick={(item) => {
              navigate(`${userNavigationInfos.createNewPath}/${item.email}`);
            }}
          />
        ) : (
          <GridView
            list={tableData}
            rowClick={(item) => {
              navigate(`${userNavigationInfos.createNewPath}/${item.email}`);
            }}
          />
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
