import React, { useEffect, useState } from 'react';
import IconGoogle from 'components/icons/iconGoogle';
import IconIntegrationSlack from 'components/icons/IconIntegrationSlack';
import { logSlackIn } from '../network';
import { useNavigate } from 'react-router-dom';
import { loginViewStates } from 'constants';
import { useToast } from 'hooks/use-toast';

const clientId = window.ob.config.slackClientId;
const redirectUri = `${window.location.origin}/slack-auth`;
const slackLoginControl = window.ob.config.enableSlackLogin;
const googleLoginControl = window.ob.config.enableGoogleLogin;

function LoginGoogleandSlack({ onGoogleSubmit }) {
  const [slackCode, setSlackCode] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [viewState, setViewState] = useState(loginViewStates.DEFAULT);
  // eslint-disable-next-line no-unused-vars
  let [optIn, setOptIn] = useState(true);
  const { toast } = useToast();

  const navigate = useNavigate();
  const onSlackSubmit = () => {
    const authorizeUrl = `https://slack.com/openid/connect/authorize?response_type=code&scope=openid%20profile%20email&client_id=${clientId}&redirect_uri=${redirectUri}`;
    window.location.href = authorizeUrl;
  };

  useEffect(() => {
    const getAccessToken = async () => {
      const code = new URLSearchParams(window.location.search).get('code');
      setSlackCode(code);
    };
    getAccessToken();
  }, []);

  useEffect(() => {
    if (slackCode) {
      let accessToken = slackCode;
      logSlackIn(accessToken, optIn)
        .then(({ success }) => {
          if (success) {
            navigate('/dashboard');
            return;
          }
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description:'Slack login error, please try again.'
          });
          setViewState(loginViewStates.DEFAULT);
        })
        .catch(() => {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description:'Slack login error, please try again.'
          });
          setViewState(loginViewStates.DEFAULT);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slackCode]);

  return (
    <>
      {googleLoginControl === 'true' && (
        <div
          onClick={onGoogleSubmit}
          className="flex w-full items-center justify-center gap-3 rounded-md bg-white p-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent cursor-pointer"
        >
          <IconGoogle />
          <span className="text-sm font-semibold leading-6 text-gray-900">Google</span>
        </div>
      )}
      {slackLoginControl === 'true' && (
        <div
          onClick={onSlackSubmit}
          className="flex w-full items-center justify-center gap-3 rounded-md bg-white p-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent cursor-pointer"
        >
          <IconIntegrationSlack customHeight={24} customWidth={24}  customClassName="m-0"/>
          <span className="text-sm font-semibold leading-6 text-gray-900">Slack</span>
        </div>
      )}
    </>
  );
}

export default LoginGoogleandSlack;
